<div class="row panel--home">
  <div class="panel--big panel--left">
    <h1 class="txt txt--big txt--white" [innerHTML]="'retraites_populaires.title' | translate"></h1>
    <h1 id="subtitle" class="txt txt--big txt--white" [innerHTML]="'retraites_populaires.subtitle' | translate"></h1>
  </div>
  <div class="panel--intro--small">
    <div class="panel--intro panel--intro--small--content">
      <div class="txt--white txt-intro panel--center">
        <p>{{'retraites_populaires.label' | translate}}</p>
        <a class="btn btn--white btn--center"
        href="https://retraitespopulaires.webex.com/mw3300/mywebex/default.do?nomenu=true&siteurl=retraitespopulaires&service=6&rnd=0.4836079013485286&main_url=https:%2F%2Fretraitespopulaires.webex.com%2Fec3300%2Feventcenter%2Fevent%2FeventAction.do%3FtheAction%3Ddetail%26%26%26EMK%3D4832534b000000056fa23009b0f3191b380a319fb7e3494212e78087c6e7016021bb1160b1698b9e%26siteurl%3Dretraitespopulaires%26confViewID%3D224614902542531755%26encryptTicket%3DSDJTSwAAAAXFNlPlXV-_xsA_6gxGWkL--3ejdaEB5IagFULEYMX46w2%26"
        target="_blank">{{'retraites_populaires.subscription' | translate}}</a>
      </div>
    </div>
  </div>
</div>
