import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {LeadDTO} from './lead/lead.dto';
import {NewLeadEventModel} from './websocket/new-lead-event.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private readonly subject = new BehaviorSubject<LeadDTO>(new LeadDTO());
  private readonly subjectNewLead = new BehaviorSubject<NewLeadEventModel>(NewLeadEventModel.getInstance());
  private readonly information = new Subject<boolean>();
  private readonly isOpenModalCalendly = new Subject<boolean>();
  private readonly openCallModalCalendly = new Subject<string>();

  private readonly title = new Subject<string>();

  public setHeaderContent(leadDTO: LeadDTO): void {
    this.subject.next(leadDTO);
  }

  public setHeaderNewLead(leadDTO: NewLeadEventModel): void {
    this.subjectNewLead.next(leadDTO);
  }

  public clearHeaderContent(): void {
    this.subject.next(new LeadDTO());
    this.subjectNewLead.next(NewLeadEventModel.getInstance());
  }

  public setOpenModalCalendly(isOpen: boolean) {
    this.isOpenModalCalendly.next(isOpen);
  }

  public getHeaderContent(): Observable<LeadDTO> {
    return this.subject.asObservable();
  }

  public getHeaderNewLead(): Observable<NewLeadEventModel> {
    return this.subjectNewLead.asObservable();
  }

  public setShowHeader(b: boolean): void {
    this.information.next(b);
  }

  public clearShowHeader(): void {
    this.information.next();
  }

  public getShowHeader(): Observable<boolean> {
    return this.information.asObservable();
  }

  public setTitle(title: string): void {
    this.title.next(title);
  }

  public getTitle(): Observable<string> {
    return this.title.asObservable();
  }

  public getOpenModalCalendly(): Observable<boolean> {
    return this.isOpenModalCalendly.asObservable();
  }

  public getOpenCallModalCalendly(): Observable<string> {
    return this.openCallModalCalendly.asObservable();
  }

  public setOpenCallModalCalendly(callEventType: string) {
    return this.openCallModalCalendly.next(callEventType);
  }

}
