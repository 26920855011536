<!-- Information Calendly Modal -->
<div appClickOutside class="modal" id="modal-info-calendly">
  <div class="modal__container">
    <button (click)="onClickCloseModalButton()" class="button-modal-close"></button>
    <h2 class="modal__container__title">
      {{'modal-information-calendly.title' | translate}}
    </h2>

    <div class="modal__container__intro" [innerHTML]="(this.salesBlocked ? 'modal-information-calendly.content_sales_blocked' : 'modal-information-calendly.content') | translate">
    </div>

    <div *ngIf="!this.salesBlocked">
      <img *disabledForClient="'pac'" [src]="getPVImage()" class="mobile-image">
      <img *disabledForClient="'pv'" [src]="getPACImage()" class="mobile-image">
    </div>

    <button *ngIf="!this.salesBlocked" class="btn btn--primary btn-left position" (click)="openCalendlyModal()">
      {{'modal-information-calendly.button' | translate}}
    </button>
    <button *ngIf="this.salesBlocked" class="btn btn--primary btn-left position" (click)="updateFSContact()">
      {{'modal-information-calendly.button_sales_blocked' | translate}}
    </button>
  </div>
</div>
