import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public actualYear: number;
  public ngOnInit(): void {
    this.actualYear = new Date().getFullYear();
  }

}
