import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ModalContactModel} from './modal-contact.model';
import {AppConfig} from "../../dynamic-config/AppConfig";
import {AppConfigService} from "../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class ModalContactService {
  public readonly showModal = new BehaviorSubject<boolean>(false);
  public environment : AppConfig;

  protected constructor(private readonly http: HttpClient,
                        private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
  }

  public registration(partnerModalContact: ModalContactModel): Observable<HttpResponse<object>> {
    const body: any = {
      dealUuid: partnerModalContact.dealUuid,
      contactId: partnerModalContact.contactId,
      civility: partnerModalContact.civility,
      firstName: partnerModalContact.firstName,
      lastName: partnerModalContact.lastName,
      email: partnerModalContact.email,
      phone: partnerModalContact.phone,
      meetingType: partnerModalContact.meetingType,
      productType: this.environment.client.toUpperCase()
    };
    return this.http.post(this.environment.API_ROOT + '/partner/update', body, { observe: 'response'});
  }
}
