import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import * as HttpStatus from 'http-status-codes';
import {NotificationService} from '../services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private readonly authenticationService: AuthenticationService,
              private readonly notificationService: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError(err => {

          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case HttpStatus.FORBIDDEN:
              case HttpStatus.UNAUTHORIZED:
                this.authenticationService.logout();
                break;
              case HttpStatus.NOT_FOUND:
                // do nothing
                break;
              default:
                this.notificationService.showErrorMessage('common.errors.unknown');
            }
          }

          return throwError(err);
        }
      ));
  }
}
