import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';
import {AppConfig} from '../../../dynamic-config/AppConfig';

@Injectable({
  providedIn: 'root'
})
export class ModalInfoCalendlyService {
  public readonly showModal = new BehaviorSubject<boolean>(false);
  public environment: AppConfig;

  constructor(private readonly http: HttpClient,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
  }

  service(): string {
    return 'information-calendly-modal';
  }

  public updateContactWitWaitingList(uuid: string): Observable<HttpResponse<object>> {
    const date = new Date();
    const body: {waitingListDate: Date} = {
      waitingListDate: date
    };
    return this.http.post(this.environment.API_ROOT + `/${this.service()}/${uuid}`, body, { observe: 'response'});
  }
}
