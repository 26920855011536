import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {UserActionService} from '../../services/user/user-action.service';
import {Subscription} from 'rxjs';
import {BrowserFormat} from '../../services/browser-format.enum';
import {Configs} from '../../config.constant';
import {HeaderService} from '../../services/header.service';
import {UserTrackingService} from '../../services/user/user-tracking.service';
import {CalendlyService} from '../../services/calendly.service';
import {NewLeadEventModel} from '../../services/websocket/new-lead-event.model';
import {LeadDTO} from '../../services/lead/lead.dto';
import {AppConfig} from '../../../dynamic-config/AppConfig';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';
import {ModalInfoCalendlyComponent} from '../modalInfoCalendly/modal-info-calendly.component';

@Component({
  selector: 'app-advice-modal',
  templateUrl: './advice-modal.component.html',
  styleUrls: ['./advice-modal.component.scss']
})
export class AdviceModalComponent implements OnInit, OnDestroy {

  public browserFormat!: BrowserFormat;
  public userActionSubscription: Subscription | undefined;
  public headerServiceSubscription: Subscription | undefined;
  public newLead: NewLeadEventModel | undefined;
  public lead: LeadDTO | undefined;
  private environment: AppConfig;
  public customCalendlyUrl;

  constructor(public readonly bsModalRef: BsModalRef,
              private readonly userActionService: UserActionService,
              private readonly headerService: HeaderService,
              private readonly userTrackingService: UserTrackingService,
              private readonly calendlyService: CalendlyService,
              private readonly appConfigService: AppConfigService,
              private readonly modalService: BsModalService) {
    this.environment = this.appConfigService.getConfig();
    this.customCalendlyUrl =
      `${this.environment.CALENDLY_BASE_URL}/${this.environment.CALENDLY_TEAM}/appel-pour-votre-projet-romande-energie?${Configs.CALENDLY.STYLES}`;

    this.userActionSubscription = this.userActionService.getBrowserFormat().subscribe(browserFormat => {
      this.browserFormat = browserFormat;
    });

  }

  ngOnInit(): void {
    this.headerService.getHeaderContent()?.subscribe(lead => {
      if (lead && lead.uuid && lead.uuid !== '') {
        this.lead = lead;
        this.changeTheCalendlyUrlTarget();
      }
    });
    this.headerService.getHeaderNewLead()?.subscribe(newLead => {
      if (newLead && newLead.uuid && newLead.uuid !== '') {
        this.newLead = newLead;
        this.changeTheCalendlyUrlTarget();
      }
    });
  }

  public changeTheCalendlyUrlTarget(): void {
    this.customCalendlyUrl =
      `${this.environment.CALENDLY_BASE_URL}/${this.environment.CALENDLY_TEAM}/appel-pour-votre-projet?${Configs.CALENDLY.STYLES}`;
  }

  public onClickCloseModalButton(): void {
    this.bsModalRef.hide();
  }

  public openCalendlyModalInformation()  {
    this.bsModalRef.hide();
    const initialState: ModalOptions = {
      animated: true
    };
    this.modalService.show(ModalInfoCalendlyComponent, initialState);
  }

  public openCalendlyModal(): boolean {
    this.onClickCloseModalButton();
    this.trackClickOnGetSimulation();
    const lead = this.newLead ? this.newLead : this.lead;
    this.calendlyService.callCalendly(this.customCalendlyUrl, lead);
    return false;
  }

  public openLink(event: MouseEvent, url: string): void {
    if (this.browserFormat && this.browserFormat === BrowserFormat.MOBILE) {
      window.open('tel:' + url, '_blank');
    }
    event.preventDefault();
  }

  public trackClickOnGetSimulation(): void {
    this.userTrackingService.trackEvent('CTA', 'adviceModalOpened');
  }

  ngOnDestroy(): void {
    if (this.userActionSubscription) {
      this.userActionSubscription.unsubscribe();
    }
    if (this.headerServiceSubscription) {
      this.headerServiceSubscription.unsubscribe();
    }
  }

}
