import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2} from '@angular/core';
import {EnergissimaModalModel} from './energissima-modal.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Configs} from '../../../config.constant';
import {CivilityTypes} from '../../../../checkout/form/civility-types.enum';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {EnergissimaModalService} from './energissima-modal.service';
import {HeatingTypesEnum} from './heating-types.enum';
@Component({
  selector: 'app-modal',
  templateUrl: './energissima-modal.component.html',
  styleUrls: ['./energissima-modal.component.scss']
})
export class EnergissimaModalComponent implements OnInit {
  private modalRef?: HTMLElement;
  public beenSubmit = false;
  public beenSubmitValid = false;
  public participationDone = false;
  private readonly MODAL_ACTIVE_CLASS = 'active';
  @Output()
  public submitForm = new EventEmitter<EnergissimaModalModel>();
  public readonly civilityTypes = [CivilityTypes.MRS, CivilityTypes.MR];
  public readonly habitationTypes = ['Maison individuelle', 'Maison jumelle', 'Maison de village', 'Ferme',
    'Appartement en PPE', 'Appartement en co-propriété'];
  public readonly heatingTypes = [HeatingTypesEnum.ELECTRIC, HeatingTypesEnum.GAS, HeatingTypesEnum.OIL, HeatingTypesEnum.PELLET, HeatingTypesEnum.WOOD, HeatingTypesEnum.OTHER];
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] =
    [CountryISO.France, CountryISO.Italy, CountryISO.Germany, CountryISO.Belgium, CountryISO.UnitedKingdom, CountryISO.Switzerland];
  public energissimaForm!: FormGroup;

  constructor(private readonly elRef: ElementRef,
              private readonly modalService: EnergissimaModalService,
              private readonly renderer: Renderer2) {}

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;
    if (!targetElement) {
      return;
    }
    const clickedOutside = this.modalRef?.isEqualNode(targetElement);
    if (clickedOutside) {
      if (targetElement.classList && targetElement.classList.contains(this.MODAL_ACTIVE_CLASS)) {
        this.modalService.showModal.next(false);
      }
    }
  }

  public get ticketNumber() {
    return this.energissimaForm.get('ticketNumber');
  }

  public get owner() {
    return this.energissimaForm.get('owner');
  }

  public get civility() {
    return this.energissimaForm.get('civility');
  }

  public get firstName() {
    return this.energissimaForm.get('firstName');
  }

  public get lastName() {
    return this.energissimaForm.get('lastName');
  }

  public get email() {
    return this.energissimaForm.get('email');
  }

  public get prefix() {
    return this.energissimaForm.get('prefix');
  }

  public get phone() {
    return this.energissimaForm.get('phone');
  }

  public get address() {
    return this.energissimaForm.get('address');
  }

  public get npa() {
    return this.energissimaForm.get('npa');
  }

  public get city() {
    return this.energissimaForm.get('city');
  }

  public get habitationType() {
    return this.energissimaForm.get('habitationType');
  }

  public get heatingType() {
    return this.energissimaForm.get('heatingType');
  }

  public get houseYear() {
    return this.energissimaForm.get('houseYear');
  }

  public getKeyNameHeatingTypes(i: number) {
    return Object.keys(HeatingTypesEnum)[i];
  }

  public ngOnInit(): void {
    this.energissimaForm = new FormGroup({
      civility: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      npa: new FormControl('', [Validators.required]),
      zipcode: new FormControl('',
        [Validators.pattern(Configs.VALIDATION.POSTAL_CODE_PATTERN),
          Validators.minLength(Configs.VALIDATION.POSTAL_CODE_MIN_LENGHT),
          Validators.maxLength(Configs.VALIDATION.POSTAL_CODE_MAX_LENGHT)]),
      city: new FormControl('', [Validators.required]),
      phone: new FormControl(''),
      owner: new FormControl(null, [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(Configs.VALIDATION.EMAIL_PATTERN)]),
      heatingType: new FormControl(null, [Validators.required]),
      habitationType: new FormControl(null, [Validators.required]),
      ticketNumber: new FormControl(null, [Validators.required]),
      houseYear: new FormControl(null, [Validators.required, Validators.max(2022)])
    });
    this.modalRef = this.elRef.nativeElement.querySelector('#modal_energissima') as HTMLElement;
    this.showBodyScrollbar();

    this.modalService.showModal.subscribe((newVal) => {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    });
  }

  public onSubmit(): void {
    if (this.email) {
      this.email.setValue(this.email.value.toLowerCase().replace(/ /g, ''));
    }
    if (this.energissimaForm.valid && this.owner &&
      this.civility && this.firstName && this.lastName && this.email &&
      this.address && this.npa && this.city && this.ticketNumber && this.heatingType && this.habitationType &&
      this.houseYear && this.houseYear.value <= 2022) {
      const checkout = new EnergissimaModalModel();
      checkout.owner = this.owner.value;
      checkout.civility = this.civility.value;
      checkout.firstName = this.firstName.value;
      checkout.lastName = this.lastName.value;
      checkout.email = this.email.value;
      if (this.phone?.value) {
        checkout.phone = this.phone.value.e164Number;
      }
      checkout.address = this.address.value;
      checkout.zipCode = this.npa.value;
      checkout.city = this.city.value;
      checkout.ticketNumber = Number(this.ticketNumber.value);
      checkout.heatingType = this.heatingType.value;
      checkout.habitationType = this.habitationType.value;
      checkout.houseYear = this.houseYear.value;

      this.modalService.registration(checkout, '2022_ENERGISSIMA').subscribe(data => {
        if (data === 'participation_ok') {
          this.participationDone = true;
          this.beenSubmitValid = true;
        }
      });
      this.beenSubmitValid = false;

    } else {
      this.beenSubmit = true;
    }
  }

  public isOwnerValid(): boolean {
    return this.beenSubmit && !!this.owner && !this.owner.valid;
  }

  public isCivilityValid(): boolean {
    return this.beenSubmit && !!this.civility && !this.civility.valid;
  }

  public isFirstNameValid(): boolean {
    return this.beenSubmit && !!this.firstName && !this.firstName.valid;
  }

  public isLastNameValid(): boolean {
    return this.beenSubmit && !!this.lastName && !this.lastName.valid;
  }

  public isEmailValid(): boolean {
    return this.beenSubmit && !!this.email && !this.email.valid;
  }

  public isPhoneValid(): boolean {
    return this.beenSubmit && !!this.phone && !this.phone.valid;
  }

  public isAddressValid(): boolean {
    return this.beenSubmit && !!this.address && !this.address.valid;
  }

  public isNPAValid(): boolean {
    return this.beenSubmit && !!this.npa && !this.npa.valid;
  }

  public isCityValid(): boolean {
    return this.beenSubmit && !!this.city && !this.city.valid;
  }

  public isTicketNumberValid(): boolean {
    return this.beenSubmit && !!this.ticketNumber && !this.ticketNumber.valid;
  }

  public isHabitationTypeValid(): boolean {
    return this.beenSubmit && !!this.habitationType && !this.habitationType.valid;
  }

  public isHeatingTypeValid(): boolean {
    return this.beenSubmit && !!this.heatingType && !this.heatingType.valid;
  }

  public isHouseYearValid(): boolean {
    return this.beenSubmit && !!this.houseYear && !this.houseYear.valid;
  }

  public isHouseYearNumberInvalid(): boolean {
    return (this.houseYear && this.houseYear.value > 2022) ? true : false;
  }

  private resetForm() {
    this.energissimaForm.patchValue({
      civility: CivilityTypes.MR
    });
  }

  private hideModal() {
    this.renderer.removeClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
    this.resetForm();
    this.showBodyScrollbar();
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  public onClickCloseModalButton(): void {
    this.participationDone = false;
    this.modalService.showModal.next(false);
    this.energissimaForm.reset();
  }

  public showModal(): void {
      this.energissimaForm.reset();
      this.renderer.addClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
      this.hideBodyScrollbar();
  }

  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }
}

