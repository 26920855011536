import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {Configs} from '../../config.constant';
import {UserTrackingService} from '../../services/user/user-tracking.service';
import {AppConfig} from '../../../dynamic-config/AppConfig';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';
import {CalendlyService} from '../../services/calendly.service';
import {NewLeadEventModel} from '../../services/websocket/new-lead-event.model';
import {LeadDTO} from '../../services/lead/lead.dto';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {HeaderService} from '../../services/header.service';
import {ModalInfoCalendlyService} from './modal-info-calendly.service';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-info-modal-calendly',
  templateUrl: './modal-info-calendly.component.html',
  styleUrls: ['./modal-info-calendly.component.scss']
})
export class ModalInfoCalendlyComponent implements OnInit {
  private modalRef: HTMLElement | undefined;
  private readonly MODAL_ACTIVE_CLASS = 'active';
  private environment: AppConfig;
  public customCalendlyUrl;
  public newLead: NewLeadEventModel | undefined;
  public lead: LeadDTO | undefined;
  readonly MOBILE_WIDTH = 426;
  public salesBlocked = false;

  constructor(public readonly bsModalRef: BsModalRef,
              private readonly elRef: ElementRef,
              private readonly renderer: Renderer2,
              private readonly userTrackingService: UserTrackingService,
              private readonly appConfigService: AppConfigService,
              private readonly calendlyService: CalendlyService,
              private readonly headerService: HeaderService,
              private readonly modalInfoCalendlyService: ModalInfoCalendlyService,
              private readonly notificationService: NotificationService) {
    this.environment = this.appConfigService.getConfig();
    this.customCalendlyUrl =
      `${this.environment.CALENDLY_BASE_URL}/${this.environment.CALENDLY_TEAM}/appel-pour-votre-projet-romande-energie?${Configs.CALENDLY.STYLES}`;
    this.salesBlocked = this.environment.PV_SALES_BLOCKED;
  }

  public ngOnInit(): void {
    this.modalRef = this.elRef.nativeElement.querySelector('#modal-info-calendly') as HTMLElement;
    this.showBodyScrollbar();
    this.headerService.getHeaderContent()?.subscribe(lead => {
      if (lead && lead.uuid && lead.uuid !== '') {
        this.lead = lead;
        this.changeTheCalendlyUrlTarget(lead);
      }
    });
    this.headerService.getHeaderNewLead()?.subscribe(newLead => {
      if (newLead && newLead.uuid && newLead.uuid !== '') {
        this.newLead = newLead;
        this.changeTheCalendlyUrlTarget(newLead);
      }
    });
  }

  public changeTheCalendlyUrlTarget(lead: NewLeadEventModel | LeadDTO): void {
    this.customCalendlyUrl = this.calendlyService.buildCalendlyUrl(lead);
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;
    if (!targetElement) {
      return;
    }
    const clickedOutside = this.modalRef?.isEqualNode(targetElement);
    if (clickedOutside) {
      this.bsModalRef.hide();
    }
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  public onClickCloseModalButton(): void {
    this.bsModalRef.hide();
  }

  public showModal(): void {
    this.renderer.addClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
    this.hideBodyScrollbar();
  }

  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  public openCalendlyModal(): boolean {
    this.bsModalRef.hide();
    this.trackClickOnGetSimulation();
    const lead = this.newLead ? this.newLead : this.lead;
    this.calendlyService.callCalendly(this.customCalendlyUrl, lead);
    return false;
  }

  public updateFSContact(): boolean {
    if (this.newLead !== undefined && this.newLead.customer !== undefined) {
      this.modalInfoCalendlyService.updateContactWitWaitingList(this.newLead.customer.uuid).subscribe(response => {
        if (response.status === 200) {
          this.bsModalRef.hide();
          this.notificationService.showSuccessMessage('common.waiting_list.success', false);
        } else {
          this.notificationService.showErrorMessage('common.errors.unknown');
        }
      });
    }
    return false;
  }


  public trackClickOnGetSimulation(): void {
    this.userTrackingService.trackEvent('CTA', 'adviceCalendlyModalInformationOpened');
  }

  public getPVImage(): string {
    if (window.innerWidth < this.MOBILE_WIDTH) {
      return './assets/images/modal-calendly/modal_calendly_pv_mobile.png';
    }
    return './assets/images/modal-calendly/modal_calendly_pv.png';
  }

  public getPACImage(): string {
    if (window.innerWidth < this.MOBILE_WIDTH) {
      return './assets/images/modal-calendly/modal_calendly_pac_mobile.png';
    }
    return './assets/images/modal-calendly/modal_calendly_pac.png';
  }
}
