<div class="modal" id="modal-create-appointment">
  <div (clickOutside)="handleClickOutside($event)" appClickOutside class="modal__container"
       id="modal-calendly-container">
    <button (click)="onClickCloseModal()" class="button-modal-close button-modal-close-calendly"></button>
    <div [class.active]="isBlockEnabled('OVERVIEW')" class="modal__container__block" id="block1">
      <div class="row">
        <div class="col-12">
          <h2 class="modal__container__title">{{'contact-button.title' | translate}}</h2>
        </div>
      </div>
      <!-- TODO: COMMENT THIS BLOCK IF COVID MODAL COMES BACK-->
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="img img--modal" [class.img--modal_small]="newLead && newLead?.systemTotalSize < configs.SYSTEM_TOTAL_SIZE.SUITABLE">
          </div>
        </div>
        <div class="col-sm-6 col-12 d-flex flex-column">
          <ng-template [ngIf]="lead">
            <p class="justified">
              {{'contact-button.subtitle' | translate}} <span class="txt--black">{{buildCustomerAddress()}}</span>
            </p>
            <div></div>
            <button (click)="openCalendlyModal()" class="btn btn--primary js-btn">
              {{'contact-button.button' | translate}}
            </button>
            <p class="justified">{{'contact-button.text' | translate}}</p>
            <button (click)="openCalendlyModal()" class="btn btn--secondary transparent btn_long_text">
              {{'common.call.me.modal' | translate}}
            </button>
          </ng-template>
          <ng-template [ngIf]="newLead && newLead?.systemTotalSize >= configs.SYSTEM_TOTAL_SIZE.SUITABLE">
              <p class="justified">
                {{'contact-button.subtitle' | translate}} <span class="txt--black">{{buildCustomerAddress()}}</span>
              </p>
              <div></div>
              <button (click)="openCalendlyModal()" class="btn btn--primary js-btn">
                {{'contact-button.button' | translate}}
              </button>
              <p class="justified">{{'contact-button.text' | translate}}</p>
              <button (click)="openCalendlyModal()" class="btn btn--secondary transparent btn_long_text">
                {{'common.call.me.modal' | translate}}
              </button>
          </ng-template>
          <ng-template [ngIf]="newLead && newLead?.systemTotalSize < configs.SYSTEM_TOTAL_SIZE.SUITABLE">
              <p class="justified">{{'contact-button.text' | translate}}</p>
              <div></div>
              <button (click)="openCalendlyModal()" class="btn btn--primary js-btn">
                {{'common.call.me.modal' | translate}}
              </button>
          </ng-template>
        </div>
      </div>
      <!-- TODO: UNCOMMENT THIS BLOCK IF COVID MODAL NEEDS TO BE RESTORED-->
      <!--
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="img img--modal">
          </div>
        </div>
        <div class="col-sm-6 col-12 d-flex flex-column justify-content-between">
          <p class="justified">
            {{'contact-button.subtitle-covid' | translate}}
          </p>
          <div></div>
          <button (click)="openCalendlyModal()" class="btn btn--primary js-btn">
            {{'common.call.me.modal' | translate}}
          </button>
          <p class="justified">{{'contact-button.text-covid' | translate}}</p>
          <button (click)="openCalendlyModal()" class="btn btn--secondary transparent btn_long_text">
            {{'contact-button.button' | translate}}
          </button>
        </div>
      </div>
    </div> -->
    </div>
  </div>
</div>
