import { LeadValidate } from './../../services/lead/lead.validate';
import { CalendlyService } from './../../services/calendly.service';
import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {HeaderService} from '../../services/header.service';
import {LeadDTO} from '../../services/lead/lead.dto';
import {Configs} from '../../config.constant';
import {UserActionService} from '../../services/user/user-action.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CheckoutService} from '../../services/checkout/checkout.service';
import {NotificationService} from '../../services/notification.service';
import {Title} from './header.enum';
import {Router} from '@angular/router';
import {NewLeadEventModel} from '../../services/websocket/new-lead-event.model';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {AdviceModalComponent} from '../advice-modal/advice-modal.component';
import {UserTrackingService} from '../../services/user/user-tracking.service';
import {AppConfig} from '../../../dynamic-config/AppConfig';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';
import {ModalInfoCalendlyComponent} from '../modalInfoCalendly/modal-info-calendly.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private static readonly MODAL_ACTIVE_CLASS = 'active';

  public lead!: LeadDTO;
  public newLead!: NewLeadEventModel;
  public titlePage!: string;
  public titleEnum = Title;
  public open!: boolean;
  public emailForm!: FormGroup;
  public information!: boolean;
  public simulatorUrl;
  public isSuccessful = false;
  public configs = Configs;
  public customerPostalCode!: number;
  public salesBlocked = false;

  private environment: AppConfig;

  private modalRef?: HTMLElement;

  public isDeleteRefPACAbo: boolean;
  public isPacPopupUndisplayed = false;

  bsModalRef?: BsModalRef;
  constructor(private readonly headerService: HeaderService,
              private readonly elRef: ElementRef,
              private readonly renderer: Renderer2,
              private readonly userActionService: UserActionService,
              private readonly notificationService: NotificationService,
              private readonly checkoutService: CheckoutService,
              private readonly router: Router,
              private readonly calendlyService: CalendlyService,
              private readonly leadValidate: LeadValidate,
              private readonly modalService: BsModalService,
              private readonly userTrackingService: UserTrackingService,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
    this.simulatorUrl = this.environment.SIMULATOR_URL;
    this.salesBlocked = this.environment.PV_SALES_BLOCKED;
    this.isDeleteRefPACAbo = this.environment.DELETE_ABO_REF_PAC;
    this.isPacPopupUndisplayed = this.environment.PAC_POPUP_IS_DELETE;
  }

  public ngOnInit(): void {
    this.getTitle();

    this.userActionService.disableCallMeMessage();

    this.userActionService.getCallMeMessage().subscribe(isModalEnabled => {
      this.isSuccessful = isModalEnabled;
    });

    this.modalRef = this.elRef.nativeElement.querySelector('#modal-email') as HTMLElement;
    this.showBodyScrollbar();

    this.headerService.getHeaderContent().subscribe(lead => {
      if (lead && lead.uuid && lead.uuid !== '') {
        this.lead = lead;
      } else {
        this.lead = new LeadDTO();
      }
    });

    this.headerService.getHeaderNewLead().subscribe(newLead => {
      if (newLead && newLead.uuid && newLead.uuid !== '') {
        this.newLead = newLead;
      } else {
        this.newLead = NewLeadEventModel.getInstance();
      }
      if (this.newLead) {
        this.customerPostalCode = Number(this.newLead.customer.postalCode);
      }
    });

    this.headerService.getShowHeader().subscribe(information => {
      this.information = information;
    });

    this.headerService.getOpenModalCalendly().subscribe(() => {
      this.openModalCalendly();
    });

    this.headerService.getOpenCallModalCalendly().subscribe(() => {
      this.openCalendlyModal();
    });

    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(Configs.VALIDATION.EMAIL_PATTERN)])
    });
  }

  public isNotOnSimulationPage(): boolean {
    return this.router.url.indexOf('/simulation') === -1;
  }

  public showModal(event): void {
    event.preventDefault();
    this.renderer.addClass(this.modalRef, HeaderComponent.MODAL_ACTIVE_CLASS);
    this.hideBodyScrollbar();
  }


  public handleClickOutside(event): void {
    if (event.target.classList && event.target.classList.contains(HeaderComponent.MODAL_ACTIVE_CLASS)) {
      this.onClickCloseModalButton();
      this.showBodyScrollbar();
    }
  }

  public getActualCost(): number {
    return this.lead.currentHeating.cost + this.lead.currentHeating.maintenanceCost;
  }

  public toggleHeader(): void {
    this.open = !this.open;
  }

  public openModalCalendly(): void {
    this.userActionService.enableModal();
    this.hideBodyScrollbar();
  }

  public onSubmit(): void {
    if (this.emailForm && this.emailForm.valid) {
      const email = this.emailForm.get('email');
      if (email) {
        this.checkoutService.resendOffer(email.value).subscribe(
          () => {
            this.onClickCloseModalButton();
            this.notificationService.showSuccessMessage('header.already.offered.success');
          },
          () => {
            this.notificationService.showErrorMessage('header.already.offered.error');
          });
      }
    }
  }

  public isAppointmentButtonVisible(): boolean {
    return this.lead.customer.owner && this.isEligible() && this.isFromRegion() && this.isNotOnOffer()
      && this.leadValidate.checkCalculatedHeatingPower(this.lead);
  }

  public isCallMeButtonVisibleForPV(): boolean {
    return this.newLead.callEventType !== null;
  }

  public isCallMeButtonVisible(): boolean {
    return this.lead.callEventType !== null && this.lead.customer.owner && !this.isEligible() && this.isFromRegion() && this.isNotOnOffer();
  }

  public onClickCloseModalButton(): void {
    this.renderer.removeClass(this.modalRef, HeaderComponent.MODAL_ACTIVE_CLASS);
    this.showBodyScrollbar();
  }

  public isEligible(): boolean {
    return this.leadValidate.isEligible(this.lead);
  }

  public isFromRegion(): boolean {
    return this.leadValidate.isFromRegion(this.lead);
  }
  public isFromFribourgArea(): boolean {
    return this.leadValidate.isFromFribourgArea(this.customerPostalCode);
  }

  private isNotOnOffer(): boolean {
    return this.leadValidate.isNotOnOffer(this.lead);
  }
  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  public checkCalculatedHeatingPower(): boolean {
    return this.leadValidate.checkCalculatedHeatingPower(this.lead);
  }

  private getTitle(): void {
    this.headerService.getTitle().subscribe(title => {
      this.titlePage = title;
    });
  }

  public openCecbCalendlyModal(): boolean {
    const urlCalendly = Configs.CALENDLY.URL + Configs.CALENDLY.STYLES; // one-time use & not the same calendly team
    this.calendlyService.callCalendly(urlCalendly, this.lead);
    return false;
  }

  public openUnsuitableCalendlyModal(): boolean {
    const urlCalendly = this.calendlyService.buildCalendlyUrlUnsuitable();
    this.calendlyService.callCalendly(urlCalendly, this.lead);
    return false;
  }

  public openCalendlyModal(): boolean {
    if (this.lead || this.newLead) {
      const initialState: ModalOptions = {
        animated: true
      };
      if (this.isPacPopupUndisplayed) {
        const urlCalendly = this.calendlyService.buildCalendlyUrl(Object.keys(this.lead).length === 0 ? this.newLead : this.lead);
        this.calendlyService.callCalendly(urlCalendly, Object.keys(this.lead).length === 0 ? this.newLead : this.lead);
      } else {
        this.modalService.show(ModalInfoCalendlyComponent, initialState);
      }    }
    return false;
  }

  public isValidAddress(lead: any): boolean {
    if (lead.customer) {
      return lead.customer.address && lead.customer.locality && lead.customer.postalCode;
    }
    return false;
  }

  public showAdviceModal(): void {
    const initialState: ModalOptions = {
      animated: true
    };
    this.bsModalRef = this.modalService.show(AdviceModalComponent, initialState);
  }

  public trackClickOnGetSimulation(): void {
    this.userTrackingService.trackEvent('CTA', 'simulationButtonOnHeaderClicked');
  }
}
