import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from "../../dynamic-config/AppConfig";
import {AppConfigService} from "../../dynamic-config/AppConfigService";

export abstract class BackendService<T> {

  public environment: AppConfig;

  private readonly baseUrl;

  protected constructor(private readonly http: HttpClient,
                        private readonly appConfigService: AppConfigService) {
    this.environment=this.appConfigService.getConfig();
    this.baseUrl = this.environment.API_ROOT;
  }

  abstract service(): string;

  protected get(url: string = ``): Observable<T> {
    return this.http.get<any>(this.buildUrl(url));
  }

  protected post(url: string, body: any): Observable<T> {
    return this.http.post<T>(this.buildUrl(url), body);
  }

  protected put(url: string, body: any): Observable<T> {
    return this.http.put<T>(this.buildUrl(url), body);
  }

  protected patch(url: string, body: any): Observable<T> {
    return this.http.patch<T>(this.buildUrl(url), body);
  }

  protected delete(url: string): Observable<void> {
    return this.http.delete<void>(this.buildUrl(url));
  }

  private buildUrl(url: string): string {
    return `${this.baseUrl}/${this.service()}${url}`;
  }

}
