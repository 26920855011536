import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MyDocument} from '../components/my-documents/my-document.dto';
import {HttpClient} from '@angular/common/http';
import {BackendService} from './backend.service';
import {map} from 'rxjs/operators';
import {DocumentType, QuoteType, DocumentDisplayName} from '../components/my-documents/my-document.enum';
import {AppConfigService} from "../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class MyDocumentService extends BackendService<MyDocument[]> {

  constructor(http: HttpClient,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  public service(): string {
    return 'lead';
  }

  public getListMyDocument(uuid: string): Observable<MyDocument[]> {
    return this.get(`/${uuid}/documents`).pipe(
      map((listMyDocument: MyDocument[]) => {
        return listMyDocument.map((document: MyDocument) => {
          const splitNameValue = document.name.split('.');
          document.documentFormat = splitNameValue[splitNameValue.length - 1].toLocaleUpperCase();
          if (document.documentType === DocumentType.Quote && document.quoteType === QuoteType.Geothermie) {
            document.displayName = DocumentDisplayName.DevisGoothermie;
          } else if (document.documentType === DocumentType.Quote) {
            document.displayName = DocumentDisplayName.DevisAirEau;
          } else if (document.documentType === DocumentType.Offer && document.quoteType === QuoteType.Geothermie) {
            document.displayName = DocumentDisplayName.OffreGeothermie;
          } else if (document.documentType === DocumentType.Offer && document.quoteType === QuoteType.AirWater) {
            document.displayName = DocumentDisplayName.OffreAirEau;
          } else {
            document.displayName = document.name;
          }
          return document;
        });
      })
    );
  }
}
