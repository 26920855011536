import {Component, OnInit, Input} from '@angular/core';
import {EnergissimaModalService} from '../modal/energissima-modal.service';
@Component({
  selector: 'app-energissima',
  templateUrl: './energissima.component.html',
  styleUrls: ['./energissima.component.scss']
})
export class EnergissimaComponent implements OnInit {
  @Input()
  activateModal!: ((bool: boolean) => void);
  constructor(private readonly modalService: EnergissimaModalService) { }

  public ngOnInit() {
  }

  public showModal(eventModal: MouseEvent): void {
    this.modalService.showModal.next(true);
    eventModal.stopPropagation();
  }

}

