import {Injectable} from '@angular/core';
import {Configs} from '../../config.constant';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {AppConfig} from "../../../dynamic-config/AppConfig";
import {AppConfigService} from "../../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class UserTrackingService {
  private environment: AppConfig;

  constructor(private readonly tagManager: Angulartics2GoogleTagManager,
              private readonly appConfigService: AppConfigService) {
    this.environment=this.appConfigService.getConfig();
  }

  public getMarketingCampaign(): string | null {
    return localStorage.getItem(Configs.LOCAL_STORAGE.MARKETING_CAMPAIGN);
  }

  public setMarketingCampaign(marketingCampaign: string | null): void {
    if (marketingCampaign) {
      localStorage.setItem(Configs.LOCAL_STORAGE.MARKETING_CAMPAIGN, marketingCampaign);
    } else {
      localStorage.removeItem(Configs.LOCAL_STORAGE.MARKETING_CAMPAIGN);
    }
  }

  public getDiscountCode(): string | null {
    return localStorage.getItem(Configs.LOCAL_STORAGE.DISCOUNT_CODE);
  }

  public setDiscountCode(discountCode: string | null): void {
    if (discountCode) {
      localStorage.setItem(Configs.LOCAL_STORAGE.DISCOUNT_CODE, discountCode);
    }
  }

  public removeDiscountCode(): void {
    if (this.getDiscountCode()) {
      localStorage.removeItem(Configs.LOCAL_STORAGE.DISCOUNT_CODE);
    }
  }

  public registerAnalytics(): void {
    if (this.environment.production) {
      this.tagManager.startTracking();
    }
  }

  public trackEvent(eventCategory: string,
                    eventAction: string,
                    eventLabel: string | null = null,
                    eventValue: number | null = null): void {
    if (this.environment.production) {
      this.tagManager.eventTrack(eventAction, {
        category: eventCategory,
        label: eventLabel,
        value: eventValue
      });
    }
  }

}
