import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { Quotation } from 'src/app/shared/services/customer/customer.dto';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html'
})
export class QuotationComponent implements OnInit {
  public quotations$!: Observable<Quotation[]>;

  constructor(private readonly customerService: CustomerService) {
  }

  public ngOnInit(): void {
    this.quotations$ = this.customerService.getListQuotation();
  }

}
