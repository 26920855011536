import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BackendService} from '../backend.service';
import {LeadDTO} from './lead.dto';
import {AppConfigService} from "../../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class LeadService extends BackendService<LeadDTO> {

  constructor(http: HttpClient,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  service(): string {
    return 'lead';
  }

  public findByUuid(uuid: string): Observable<LeadDTO> {
    return this.get(`/${uuid}`);
  }

  public createCallReminder(uuid: string): Observable<any> {
    return this.post(`/${uuid}/call`, {});
  }

  public notifyAppointmentPending(uuid: string): Observable<any> {
    return this.post(`/${uuid}/appointment_pending`, {});
  }

  public unsubscribe(uuid: string): Observable<any> {
    return this.post(`/${uuid}/unsubscribe`, {});
  }

  public time(uuid: string): Observable<any> {
    return this.post(`/${uuid}/time`, {});
  }
}
