import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BackendService} from '../../services/backend.service';
import {HttpClient} from '@angular/common/http';
import {DownloadPdfModel} from './download-pdf.model';
import {Configs} from '../../config.constant';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';

@Injectable({
  providedIn: 'root',
})
export class DownloadPdfService extends BackendService<any> {
  public readonly showModal = new BehaviorSubject<boolean>(false);

  constructor(http: HttpClient,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  service(): string {
    return 'download-pdf';
  }

  public registration(downloadPdf: DownloadPdfModel, marketingCampaign: string | null): Observable<void>  {
    const body: any = {
      firstName: downloadPdf.firstName,
      lastName: downloadPdf.lastName,
      email: downloadPdf.email,
      phone: downloadPdf.phone,
      city: downloadPdf.city,
      zipCode: downloadPdf.zipCode,
      owner: downloadPdf.owner,
      referer: downloadPdf.referer,
      civility: downloadPdf.civility,
      marketingCampaign
    };

    if (this.environment.client === Configs.ENVIRONMENT.PAC) {
      body.consentPac = downloadPdf.consentPac;
    } else {
      body.consentPv = downloadPdf.consentPv;
      body.installedSolar = downloadPdf.installedSolar;
    }

    return this.post('', body);
  }
}
