import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {CivilityTypes} from '../../../checkout/form/civility-types.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Configs} from '../../config.constant';
import {DownloadPdfService} from './download-pdf.service';
import {ActivatedRoute} from '@angular/router';
import {DownloadPdfModel} from './download-pdf.model';
import {UserTrackingService} from '../../services/user/user-tracking.service';
import {LocalStorageService} from '../../services/store/local-storage.service';
import {AppConfig} from "../../../dynamic-config/AppConfig";
import {AppConfigService} from "../../../dynamic-config/AppConfigService";

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html'
})
export class DownloadPdfComponent implements OnInit {

  private readonly MODAL_ACTIVE_CLASS = 'active';
  private readonly NAME_FILE_LIVRE_BLANC = 'livre-blanc';
  private readonly OPEN_LIVRE_BLANC = 'livre-blanc';

  public readonly civilityTypes = [CivilityTypes.MR, CivilityTypes.MRS];
  public environment: AppConfig;
  public configs = Configs;

  public livreBlancForm!: FormGroup;
  private modalRef?: HTMLElement;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] =
    [CountryISO.France, CountryISO.Italy, CountryISO.Germany, CountryISO.Belgium, CountryISO.UnitedKingdom, CountryISO.Switzerland];

  constructor(private readonly elRef: ElementRef,
              private readonly downloadPdfService: DownloadPdfService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly renderer: Renderer2,
              private readonly userTrackingService: UserTrackingService,
              private readonly localStorageService: LocalStorageService,
              private readonly appConfigService: AppConfigService
  ) {
    this.environment=this.appConfigService.getConfig();
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;
    if (!targetElement) {
      return;
    }
    const clickedOutside = this.modalRef?.isEqualNode(targetElement);
    if (clickedOutside) {
      if (targetElement.classList && targetElement.classList.contains(this.MODAL_ACTIVE_CLASS)) {
        this.downloadPdfService.showModal.next(false);
      }
    }
  }

  ngOnInit(): void {
    this.livreBlancForm = new FormGroup({
      civility: new FormControl(CivilityTypes.MR, [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      zipcode: new FormControl('',
        [Validators.pattern(Configs.VALIDATION.POSTAL_CODE_PATTERN),
          Validators.minLength(Configs.VALIDATION.POSTAL_CODE_MIN_LENGHT),
          Validators.maxLength(Configs.VALIDATION.POSTAL_CODE_MAX_LENGHT)]),
      city: new FormControl('', [Validators.required]),
      phone: new FormControl(''),
      owner: new FormControl(null, [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(Configs.VALIDATION.EMAIL_PATTERN)]),
    });
    if (this.environment.client === Configs.ENVIRONMENT.PAC) {
      this.livreBlancForm.addControl('agree',  new FormControl('', [Validators.requiredTrue]));
    } else {
      this.livreBlancForm.addControl('agreePv',  new FormControl('', [Validators.requiredTrue]));
      this.livreBlancForm.addControl('installedSolar', new FormControl(null, [Validators.required]));
    }
    this.modalRef = this.elRef.nativeElement.querySelector('#modal-livre-blanc') as HTMLElement;
    this.showBodyScrollbar();

    this.downloadPdfService.showModal.subscribe((newVal) => {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    });

    if (this.activatedRoute.snapshot.fragment
        && this.activatedRoute.snapshot.fragment.indexOf(this.OPEN_LIVRE_BLANC) !== -1) {
      this.showModal();
    }
  }

  private resetForm() {
    this.livreBlancForm.patchValue({
      civility: CivilityTypes.MR
    });
  }

  private hideModal() {
    this.renderer.removeClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
    this.resetForm();
    this.showBodyScrollbar();
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  public onClickCloseModalButton(): void {
    this.downloadPdfService.showModal.next(false);
  }

  public onSubmit(): void {
    const downloadPdf = new DownloadPdfModel();
    const referer = window.location.href;
    const marketingCampaign = this.userTrackingService.getMarketingCampaign();

    downloadPdf.firstName = this.firstName ? this.firstName.value : null;
    downloadPdf.lastName = this.lastName ? this.lastName.value : null;
    downloadPdf.email = this.email ? this.email.value : null;
    downloadPdf.phone = this.phone ? (this.phone.value ? this.phone.value.internationalNumber : '') : null;
    downloadPdf.city = this.city ? this.city.value : null;
    downloadPdf.zipCode = this.zipCode ? this.zipCode.value : null;
    downloadPdf.consentPac = !!this.agree;
    downloadPdf.consentPv = !!this.agreePv;
    downloadPdf.owner = this.owner ? this.owner.value : null;
    downloadPdf.referer = referer;
    downloadPdf.civility = this.civility ? this.civility.value : null;
    downloadPdf.installedSolar = this.installedSolar ? this.installedSolar.value : null;
    this.downloadPdfService.registration(downloadPdf, marketingCampaign).subscribe();

    this.onClickCloseModalButton();
    let urlFileDownload: string;
    if (this.environment.client === Configs.ENVIRONMENT.PAC) {
      urlFileDownload = './assets/doc/LivreBlanc-PAC-web.pdf';
      this.downloadFileFromURI(urlFileDownload, this.NAME_FILE_LIVRE_BLANC + '-pompe-chaleur');
    } else {
      urlFileDownload = './assets/doc/LivreBlanc-PV_PROD.pdf';
      this.downloadFileFromURI(urlFileDownload, this.NAME_FILE_LIVRE_BLANC + '-solaire');
    }
    this.localStorageService.setDownloadPdfModel(this.livreBlancForm.value);
  }

  public get civility() {
    return this.livreBlancForm.get('civility');
  }

  public get lastName() {
    return this.livreBlancForm.get('lastName');
  }

  public get firstName() {
    return this.livreBlancForm.get('firstName');
  }

  public get email() {
    return this.livreBlancForm.get('email');
  }

  public get city() {
    return this.livreBlancForm.get('city');
  }

  public get zipCode() {
    return this.livreBlancForm.get('zipcode');
  }

  public get contactPac() {
    return this.livreBlancForm.get('contactPac');
  }

  public get contactPv() {
    return this.livreBlancForm.get('contactPv');
  }

  public get agree() {
    return this.livreBlancForm.get('agree');
  }

  public get agreePv() {
    return this.livreBlancForm.get('agreePv');
  }

  public get owner() {
    return this.livreBlancForm.get('owner');
  }

  public get installedSolar() {
    return this.livreBlancForm.get('installedSolar');
  }

  private downloadFileFromURI(uri: string, name: string): void {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public get phone() {
    return this.livreBlancForm.get('phone');
  }

  public isLastNameValid(): boolean {
    return !!this.lastName?.touched && !!this.lastName && !this.lastName.valid;
  }

  public isFirstNameValid(): boolean {
    return !!this.firstName?.touched && !!this.firstName && !this.firstName.valid;
  }

  public isCityValid(): boolean {
    return !!this.city?.touched && !!this.city && !this.city.valid;
  }

  public isEmailValid(): boolean {
    return !!this.email?.touched && !!this.email && !this.email.valid;
  }

  public showModal(): void {
    this.livreBlancForm.reset();
    this.renderer.addClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
    this.hideBodyScrollbar();
  }

  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

}
