import { HeatDistributionSystem } from './heat-distribution-system.enum';
import { DealStatus } from './deal-status.enum';
import { HeatingName } from './heating-name.enum';
import { Configs } from './../../config.constant';
import { LeadDTO } from './lead.dto';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class LeadValidate {

  public isFromFribourgArea(customerPostalCode: number): boolean {
    return customerPostalCode >= 1600 && customerPostalCode < 1800;
  }

  public isEligible(lead: LeadDTO): boolean {
    return lead.currentHeating.consumptionWm2 < Configs.CONSUMMATION.LIMIT;
  }

  public checkCalculatedHeatingPower(lead: LeadDTO): boolean {
    return lead.currentHeating.calculatedHeatingPowerKw < Configs.HEATING_POWER.LIMIT;
  }

  public isEligibleToAbo(lead: LeadDTO): boolean {
    return lead.currentHeating.calculatedHeatingPowerKw <= Configs.HEATING_POWER.LIMIT_WITH_ABO
      && (lead.currentHeating.name === HeatingName.GAS || lead.currentHeating.name === HeatingName.OIL)
      && lead.heatDistributionSystem !== 'combined';
  }

  public isFromRegion(lead: LeadDTO): boolean {
    return lead.callEventType !== null && lead.appointmentEventType !== null;
  }

  public isNotOnOffer(lead: LeadDTO): boolean {
    return lead.dealStatus === null || lead.dealStatus === DealStatus.QUOTE_RECEIVED;
  }

  public getOfferInAbo(lead: LeadDTO): number {
    return lead.heatDistributionSystem === HeatDistributionSystem.FLOOR_HEATING ? Configs.OFFER_LEVEL.LOW : Configs.OFFER_LEVEL.HIGHT;
  }
}
