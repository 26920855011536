import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
​
@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutSideDirective {
​
  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  constructor(private readonly elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
