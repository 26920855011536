import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AppConfig} from '../../dynamic-config/AppConfig';
import {AppConfigService} from '../../dynamic-config/AppConfigService';
import {Observable} from 'rxjs';

@Injectable()
export class NextStepGuard implements CanActivate {
  private environment: AppConfig;
  constructor(private readonly router: Router,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
  }

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (this.environment.client !== 'borne') {
      return true;
    }

    // not logged in so redirect to home page
    return this.router.createUrlTree(['/']);
  }
}

