<div class="tab row" id="upper-tab">
  <div class="panel--tab" [ngClass]="isOfferPage ? 'panel--tab--offer' : ''">
    <div class="upper-tab" *ngIf="lead.airWaterSimulationResult.cost && !disabledAirEau"
      [ngClass]="[isAirEauSelected ? 'isTabSelected' : '', !isOfferPage ? 'upper-tab--checkout isTabSelected--checkout' : '']"
      (click)="selectAirEauSimulation()">
      <div class="tab-title">{{'upper-tabs.air-eau' | translate}}</div>
      <img (mouseenter)="onMouseEnterAirEau(true)" (mouseleave)="onMouseLeaveAirEau(false)"
        [src]="'/assets/images/' + (isAirEauSelected ? 'ico-info.svg' : 'ico-info-grey.svg')">
      <div class="pac-description tab-description-width" *ngIf="onHoverAirEau"
      [ngClass]="isOfferPage ? 'pac-description--offer' : 'pac-description--checkout'">
        {{'upper-tabs.hover.air-eau' | translate}}</div>
      <div class="pac-description pac-description--checkout tab-description-width tab-description-abo" *ngIf="onHoverAbo">{{'upper-tabs.hover.abo' | translate}}</div>
      <div class="tab-desc" *ngIf="!isOfferPage">{{'upper-tabs.simple' | translate}}</div>
      <div class="tab-desc" *ngIf="isOfferPage">CHF {{finalCostAirEau | offerCurrencyFormat}}</div>
    </div>
    <div *ngIf="!isOfferPage" class="panel-radio" [ngClass]="isAirEauSelected ? 'isTabSelected' : ''">
      <div id="invAirEau" class="radio" (click)="selectButton(radioButtonType.INVESTISSEMENT_AIR)" [ngClass]="[!isAirEauSelected ? 'radio--tabIsNotSelected' : '',
      isSelected === radioButtonType.INVESTISSEMENT_AIR && isAirEauSelected ? 'radio--isSelected' : '',
      isSelected !== radioButtonType.INVESTISSEMENT_AIR && isAirEauSelected ? 'radio--isNotSelected' : '']">
        <input id="radio-1" type="radio" value={{radioButtonType.INVESTISSEMENT_AIR}}
          [checked]="radioButtonType.INVESTISSEMENT_AIR===isSelected">
        <div class="radio-label">
          <label for="radio-1" class="no--display--mobile">Investissement</label>
          <label class="illustration--small tab--icon no--display--web">
            <img *ngIf="radioButtonType.INVESTISSEMENT_AIR===isSelected" alt=""
              src="./assets/images/ico-air-eau-red.svg">
            <img *ngIf="radioButtonType.INVESTISSEMENT_AIR!==isSelected" alt="" src="./assets/images/ico-air-eau.svg">
          </label>
          <label class="tab--title no--display--web">{{'upper-tabs.air-eau' | translate}}<br>
            <p>Investissement</p>
          </label>
          <label for="radio-1" class="tab-info">CHF
            {{getTotalSimulationCost(lead?.airWaterSimulationResult) | currencyFormat}}</label>
        </div>
      </div>
      <div *ngIf="isEligibleToAbo && !this.isDeleteRefPACAbo" class="radio" id="aboAirEau" (click)="selectButton(radioButtonType.ABONNEMENT_AIR)" [ngClass]="[!isAirEauSelected ? 'radio--tabIsNotSelected' : '',
      isSelected === radioButtonType.ABONNEMENT_AIR && isAirEauSelected ? 'radio--isSelected' : '',
      isSelected !== radioButtonType.ABONNEMENT_AIR && isAirEauSelected ? 'radio--isNotSelected' : '']">
        <input id="radio-2" type="radio" value={{radioButtonType.ABONNEMENT_AIR}}
          [checked]="radioButtonType.ABONNEMENT_AIR===isSelected">
        <div class="radio-label">
          <label for="radio-2" class="no--display--mobile">Abonnement</label>
          <label class="illustration--small tab--icon no--display--web">
            <img *ngIf="radioButtonType.ABONNEMENT_AIR===isSelected" alt="" src="./assets/images/ico-air-eau-red.svg">
            <img *ngIf="radioButtonType.ABONNEMENT_AIR!==isSelected" alt="" src="./assets/images/ico-air-eau.svg">
          </label>
          <label class="tab--title no--display--web">{{'upper-tabs.air-eau' | translate}}<br>
            <p>Abonnement</p>
          </label>
          <label for="radio-2" class="tab-info">CHF {{aboAmount | currencyFormat}}/{{'common.month' | translate}}</label>
        </div>
      </div>
      <div *ngIf="!isEligibleToAbo && !this.isDeleteRefPACAbo" class="radio radio--isDisabled" [ngClass]="[!isAirEauSelected ? 'radio--tabIsNotSelectedDisabled' : '',
      isSelected !== radioButtonType.ABONNEMENT_AIR && isAirEauSelected ? 'radio--isNotSelected' : '']">
        <input id="radio-4" type="radio" value={{radioButtonType.ABONNEMENT_AIR}} [disabled]="true">
        <div class="radio-label">
          <label for="radio-4" class="no--display--mobile">Abonnement <span>
              <img (mouseenter)="onMouseEnterAbo(true)" (mouseleave)="onMouseLeaveAbo(false)" class="iconAbo"
                [src]="'/assets/images/' + (isAirEauSelected ? 'ico-info.svg' : 'ico-info-grey.svg')">
            </span></label>
          <label class="illustration--small tab--icon no--display--web">
            <img alt="" src="./assets/images/ico-air-eau.svg">
          </label>
          <label class="tab--title no--display--web">{{'upper-tabs.air-eau' | translate}}
            <span>
              <img (mouseenter)="onMouseEnterAbo(true)" (mouseleave)="onMouseLeaveAbo(false)" class="iconAbo"
                [src]="'./assets/images/' + (isAirEauSelected ? 'ico-info.svg' : 'ico-info.svg')">
            </span><br>
            <p>Abonnement</p>
          </label>
          <label for="radio-4" class="tab-info">{{ "upper-tabs.ineligible" | translate }}
          </label>
        </div>
      </div>
      <div class="radio no--display--web" id="invGeo"  (click)="selectButton(radioButtonType.INVESTISSEMENT_GEO)" [ngClass]="[isAirEauSelected ? 'radio--tabIsNotSelected' : '',
      isSelected === radioButtonType.INVESTISSEMENT_GEO && !isAirEauSelected ? 'radio--isSelected' : '',
      isSelected !== radioButtonType.INVESTISSEMENT_GEO && !isAirEauSelected ? 'radio--isNotSelected' : '']">
        <input id="radio-3" type="radio" value={{radioButtonType.INVESTISSEMENT_GEO}}
          [checked]="radioButtonType.INVESTISSEMENT_GEO===isSelected">
        <div class="radio-label">
          <label class="illustration--small tab--icon tab--icon--geo">
            <img *ngIf="radioButtonType.INVESTISSEMENT_GEO===isSelected" alt="" src="./assets/images/ico-geo-red.svg">
            <img *ngIf="radioButtonType.INVESTISSEMENT_GEO!==isSelected" alt="" src="./assets/images/ico-geo.svg">
          </label>
          <label class="tab--title">{{'upper-tabs.geothermie' | translate}}<br>
            <p>Investissement</p>
          </label>
          <label for="radio-3" class="tab-info">CHF
            {{getTotalSimulationCost(lead?.geoSimulationResult) | currencyFormat}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="panel--tab" [ngClass]="!isOfferPage ? 'no--display--mobile' : 'panel--tab--offer'">
    <div class="upper-tab" *ngIf="lead.geoSimulationResult.cost && !disabledGeo"
      [ngClass]="[!isAirEauSelected ? 'isTabSelected' : '', !isOfferPage ? 'upper-tab--checkout isTabSelected--checkout' : '']"
      (click)="selectGeoSimulation()">
      <div class="tab-title">{{'upper-tabs.geothermie' | translate}}</div>
      <img (mouseenter)="onMouseEnterGeo(true)" (mouseleave)="onMouseLeaveGeo(false)"
        [src]="'/assets/images/' + (!isAirEauSelected ? 'ico-info.svg' : 'ico-info-grey.svg')">
      <div class="pac-description tab-description-width"
      [ngClass]="isOfferPage ? 'pac-description--offer' : 'pac-description--checkout'" *ngIf="onHoverGeo">
        {{'upper-tabs.hover.geothermie' | translate}}</div>
      <div class="tab-desc" *ngIf="!isOfferPage">{{'upper-tabs.performante' | translate}}</div>
      <div class="tab-desc" *ngIf="isOfferPage">CHF {{finalCostGeo | offerCurrencyFormat}}</div>
    </div>
    <div *ngIf="!isOfferPage" class="panel-radio"
         [ngClass]="{'isTabSelected': !isAirEauSelected, 'panel-radio--duplicate-height':!this.isDeleteRefPACAbo}">
      <div class="radio" (click)="selectButton(radioButtonType.INVESTISSEMENT_GEO)" [ngClass]="[isAirEauSelected ? 'radio--tabIsNotSelected' : '',
      isSelected === radioButtonType.INVESTISSEMENT_GEO && !isAirEauSelected ? 'radio--isSelected' : '',
      isSelected !== radioButtonType.INVESTISSEMENT_GEO && !isAirEauSelected ? 'radio--isNotSelected' : '']">
        <input id="radio-3" name="radio" type="radio" value={{radioButtonType.INVESTISSEMENT_GEO}}
          [checked]="radioButtonType.INVESTISSEMENT_GEO===isSelected">
        <div class="radio-label">
          <label for="radio-3">Investissement</label>
          <label for="radio-3" class="tab-info">CHF
            {{getTotalSimulationCost(lead?.geoSimulationResult) | currencyFormat}}</label>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="isOfferPage ? 'red-row' : 'upper-tabs-row'"></div>
