import {Pipe, PipeTransform} from '@angular/core';
import {AppConfig} from "../../dynamic-config/AppConfig";
import {AppConfigService} from "../../dynamic-config/AppConfigService";

@Pipe({
  name: 'addClientSuffix'
})
export class AddClientSuffixPipe implements PipeTransform {
  private environment: AppConfig;

  constructor(private readonly appConfigService: AppConfigService) {
    this.environment=this.appConfigService.getConfig();
  }

  transform(value: string): string {
    return value + '.' + this.environment.client;
  }
}
