import { Discount } from './../../services/discount/discount.dto';
import { DiscountService } from './../../services/discount/discount.service';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {Configs} from '../../config.constant';
import {LeadDTO} from '../../services/lead/lead.dto';
import {TabsStepsEnum} from './tabs-steps.enum';
import {SimulationResultDTO} from '../../services/lead/simulation-result.dto';
import {ActiveSimulationService} from '../../services/active-simulation.service';
import {RadioButton} from '../upper-tabs/radio-button.enum';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterViewInit {
  @Output()
  public changeValue = new EventEmitter<Discount>();

  @Input()
  public lead!: LeadDTO;
  @Input()
  public contactButton!: boolean;
  public radioButtonType = RadioButton;
  public tab!: TabsStepsEnum;
  @Input()
  public aboAmount!: number;
  @Input()
  public isButtonSelected = this.radioButtonType.INVESTISSEMENT_AIR;

  public readonly amortizationYears = Configs.AMORTISATION.LIMIT;
  public readonly estimated = Configs.AMORTISATION.ESTIMATED_KWH;
  public discountCode!: string;
  public showCodeMessage = false;
  public isCodeValid!: boolean;
  public isCodeValidOnInit!: boolean;
  public onHoverPromoCode = false;
  public discount!: Discount;

  private heightGraph = 0;
  private widthGraph = 0;
  private graph!: HTMLElement;

  public activeSimulation!: SimulationResultDTO;

  constructor(private readonly elRef: ElementRef,
              private readonly activeSimulationService: ActiveSimulationService,
              private readonly discountService: DiscountService) {
  }


  public ngOnInit(): void {
    this.tab = TabsStepsEnum.INVESTMENT;

    this.activeSimulation = this.activeSimulationService.getActiveSimulationValue();
    this.activeSimulationService.getActiveSimulation().subscribe((simulation) => this.activeSimulation = simulation);
    if (this.lead.discount) {
        this.discountCode = this.lead.discount.code;
        this.discount = this.lead.discount;
        this.isCodeValid = this.lead.discount.valid;
        if (this.isCodeValid) {
          this.isCodeValidOnInit = true;
        }
    }
  }

  public ngAfterViewInit(): void {
    this.renderGraph();
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    this.renderGraph();
  }

  public onChangeTab(tab: string): void {
    this.tab = TabsStepsEnum[tab];
    if (this.isTabSelected('INVESTMENT')) {
      this.renderGraph();
    }
  }

  public isTabSelected(tab: string): boolean {
    return this.tab === TabsStepsEnum[tab];
  }

  public getCurrentAnnualCost(): number {
    if (this.isButtonSelected === this.radioButtonType.ABONNEMENT_AIR) {
      return this.lead.currentHeating.cost + this.getMaintenanceInvestmentCost();
    } else {
      return this.lead.currentHeating.cost;
    }
  }
  public getAboAnnualCost(): number {
    return this.aboAmount * 12;
  }

  public getNewAnnualCost(): number {
    if (this.isButtonSelected === this.radioButtonType.ABONNEMENT_AIR) {
      return this.getAboAnnualCost() + this.activeSimulation.heating.cost;
    } else {
      return this.activeSimulation.heating.cost;
    }
  }

  public getTotalSimulationCost(): number {
    if (this.isButtonSelected === this.radioButtonType.ABONNEMENT_AIR) {
      return this.aboAmount * 12;
    } else if (this.discount.amount != null && this.discount.valid) {
      return this.activeSimulation.cost - this.activeSimulation.subvention - this.discount.amount;
    } else {
      return this.activeSimulation.cost - this.activeSimulation.subvention;
    }
  }

  public getPercentageAnnualSave(): number {

    if (this.getCurrentAnnualCost() <= 0) {
      return 0;
    }

    const percentage = (this.getNewAnnualCost() / this.getCurrentAnnualCost()) * 100;
    if (percentage <= 100) {
      return 100 - percentage;
    } else {
      return percentage;
    }
  }

  public getCo2AnnualSave(): number {
    return this.lead.currentHeating.co2Output - this.activeSimulation.heating.co2Output;
  }

  public getPercentageAnnualCo2(): number {

    if (this.lead.currentHeating.co2Output <= 0) {
      return 0;
    }

    const percentage = (this.activeSimulation.heating.co2Output / this.lead.currentHeating.co2Output) * 100;
    if (percentage <= 100) {
      return 100 - percentage;
    } else {
      return percentage;
    }
  }

  public getCurrentCostPercentageOfTotalCost(): number {
    return (this.lead.currentHeating.cost / (this.getCurrentAnnualCost())) * 100;
  }

  public getNewCostPercentageOfTotalCost(): number {
    return (this.getNewAnnualCost() / this.getCurrentAnnualCost()) * 100;
  }

  public getNewAboPercentageOfTotalCost(): number {
    return (this.getAboAnnualCost() / this.getCurrentAnnualCost()) * 100;
  }

  public getCurrentBarPercentage(): number {
    return Math.min(100 * (this.getCurrentCostPercentageOfTotalCost() / this.getNewCostPercentageOfTotalCost()), 100);
  }
  public getNewPercentageDifference(): number {
    return (this.getDifferenceAnnualCost() / this.getCurrentAnnualCost()) * 100;
    // return 10.11;
  }

  public getDifferenceAnnualCost(): number {
    return this.getCurrentAnnualCost() - this.getNewAnnualCost();
  }

  public getKilometersSave(): number {
    return this.getCo2AnnualSave() / Configs.EMISSIONS.KILOMETERS;
  }

  public getEcologicalFootprintSave(): number {
    return this.activeSimulation.heating.co2Output / Configs.EMISSIONS.ENVIRONMENT;
  }

  public getTreesSave(): number {
    return this.getCo2AnnualSave() / Configs.EMISSIONS.TREES;
  }

  public getYearsPercentage(): number {
    return (100 * this.getAmortisationPeriod()) / Configs.AMORTISATION.LIMIT;
  }

  public getAmortisationPeriod(): number {
    return Math.round(this.getTotalSimulationCost() / this.getDifferenceAnnualCost());
  }

  public getMoneySavedAfter20Years(): number {
    return (this.getDifferenceAnnualCost() * 20) - this.getTotalSimulationCost();
  }

  public getCurrentMaintenancePercentageOfTotalCost(): number {
    return (this.lead.currentHeating.maintenanceCost / (this.getCurrentAnnualCost())) * 100;
  }

  public getCurrentInvestmentPercentageOfTotalCost(): number {
    return (this.lead.investment / 15) / (this.getCurrentAnnualCost()) * 100;
  }

  public getMaintenanceInvestmentCost(): number {
    return this.lead.currentHeating.maintenanceCost + (this.lead.investment / 15);
  }

  public ifToSmallMoveRight(): string {
    return this.getCurrentMaintenancePercentageOfTotalCost() < 10 ? '0' : '';
  }

  public determineSign(n: number): string {
    return n < 100 ? '-' : '+';
  }

  public renderGraph(): void {
    setTimeout(() => {
      this.graph = this.elRef.nativeElement.querySelector('.positive') as HTMLElement;
      if (this.graph != null) {
        this.heightGraph = this.graph.getBoundingClientRect().height;
        this.widthGraph = this.graph.getBoundingClientRect().width;
      }
    }, Configs.TIMEOUT.RENDER_GRAPH);
  }

  public getGraphPointsPositive(): string {
    const height = this.heightGraph / 2;

    let positiveHeight = height - (height / this.getRatio());

    if (positiveHeight < 0) {
      positiveHeight = 0;
    }

    // tslint:disable-next-line:max-line-length
    return `${this.widthGraph * (this.getAmortisationPeriod() / 20)},${height} ${this.widthGraph},${height} ${this.widthGraph},${positiveHeight}`;
  }

  public getGraphPointsNegative(): string {
    const height = this.heightGraph / 2;

    let negativeHeight = height + (height * this.getRatio());

    if (negativeHeight > 100) {
      negativeHeight = 100;
    }

    return `0,${height} ${this.widthGraph * (this.getAmortisationPeriod() / 20)},${height} 0,${negativeHeight}`;
  }

  public checkCodeValid(): void {
    let uuid;
    if (this.lead?.buildingUuid) {
      uuid = this.lead?.buildingUuid;
    } else {
      uuid = this.lead.uuid;
    }
    this.discountService.checkCode(uuid, this.discountCode).subscribe((res: Discount) => {
      this.isCodeValid = res?.valid;
      this.showCodeMessage = true;
      this.discount = res;
      this.changeValue.emit(this.discount);
    });
  }

  public onMouseEnterPromoCode(bool: boolean) {
    this.onHoverPromoCode = bool;
  }

  public onMouseLeavePromoCode(bool: boolean) {
    this.onHoverPromoCode = bool;
  }

  public isShowChart(): boolean {
    return this.getAmortisationPeriod() >= 0 && this.getAmortisationPeriod() <= 20;
  }

  private getRatio(): number {
    let ratio = this.getTotalSimulationCost() / this.getMoneySavedAfter20Years();
    if (ratio < 0) {
      ratio = 1;
    }
    return ratio;
  }
}
