import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {AppConfig} from '../../dynamic-config/AppConfig';
import {AppConfigService} from '../../dynamic-config/AppConfigService';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private environment: AppConfig;

  constructor(private readonly authService: AuthenticationService,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const token = this.authService.getToken();
    const isApiUrl = request.url.startsWith(this.environment.API_ROOT);
    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
}
