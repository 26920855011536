import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PartnerConditionsGuard} from './shared/guards/partnerConditions.guard';
import {PartnerRouteGuard} from './shared/guards/partnerRoute.guard';
import {NextStepGuard} from './shared/guards/nextStep.guard';
import {PartnerDrillingConditionsGuard} from './shared/guards/partnerDrillingConditions.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'simulation',
    loadChildren: () => import('src/app/simulation/simulation.module').then(m => m.SimulationModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('src/app/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'offer',
    loadChildren: () => import('src/app/offer/offer.module').then(m => m.OfferModule)
  },
  {
    path: 'avantages',
    loadChildren: () => import('src/app/advantages/advantages.module').then(m => m.AdvantagesModule)
  },
  {
    path: 'mailing',
    loadChildren: () => import('src/app/campaign/campaign.module').then(m => m.CampaignModule)
  },
  {
    path: 'livreblanc',
    loadChildren: () => import('src/app/livre-blanc/livre-blanc.module').then(m => m.LivreBlancModule)
  },
  /**
   * {
   * path: 'abonnement',
   * loadChildren: () => import('src/app/abonnement/abonnement.module').then(m => m.AbonnementModule)
   * },
   */
  {
    path: 'partenaires/conditions',
    loadChildren: () => import('src/app/conditions/conditions-partner.module').then(m => m.ConditionsPartnerModule),
    canActivate: [PartnerConditionsGuard]
  },
  {
    path: 'prochaines-etapes',
    loadChildren: () => import('src/app/next-step/next-step.module').then(m => m.NextStepModule),
    canActivate: [NextStepGuard]
  },
  {
    path: ':partner',
    loadChildren: () => import('src/app/partner/home-partner/home-partner.module').then(m => m.HomePartnerModule),
    canActivate: [PartnerRouteGuard]
  },
  {
    path: ':partner/simulation',
    loadChildren: () => import('src/app/partner/simulation-partner/simulation.module').then(m => m.SimulationModule)
  },
  {
    path: ':partner/checkout',
    loadChildren: () => import('src/app/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: ':partner/offer',
    loadChildren: () => import('src/app/offer/offer.module').then(m => m.OfferModule)
  },
  {
    path: 'partenaires/conditions-forage',
    loadChildren: () => import('src/app/drilling/drilling-conditions.module').then(m => m.DrillingConditionsModule),
    canActivate: [PartnerDrillingConditionsGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
