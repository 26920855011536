<div class="img--bg img--bg--shadow" [ngClass]="isTabSelected('INVESTMENT') ? 'img--bg--' + isButtonSelected : ''">
  <div class="tab tab--mobile-padding row-tab" [ngClass]="isTabSelected('INVESTMENT') ? 'mobile-bg' : ''" id="tab">
    <div class="col-4 mobile-tab">
      <a (click)="onChangeTab('INVESTMENT')" [class.active]="isTabSelected('INVESTMENT')" class="tab__item" id="toTab1">
        <ng-template [ngIf]="isButtonSelected !== radioButtonType.ABONNEMENT_AIR">
          <div>{{'tabs.investment.title' | translate}}</div>
          <div>{{getTotalSimulationCost() | currencyFormat}}</div>
          <div *ngIf="activeSimulation.subvention > 0">
            {{'tabs.investment.cost.subventionOf' | translate}} CHF {{activeSimulation.subvention | currencyFormat}} {{'tabs.investment.cost.subventionOfEnded' | translate}}
          </div>
          <div *ngIf="activeSimulation.subvention == null || activeSimulation.subvention == 0">
            {{'tabs.investment.cost.noSubvention' | translate}}
          </div>
        </ng-template>
        <ng-template [ngIf]="isButtonSelected === radioButtonType.ABONNEMENT_AIR">
          <div>{{'tabs.investment.title-abo' | translate}}</div>
          <div>{{aboAmount | currencyFormat}}</div>
          <div>
            {{'tabs.investment.subtitleC' | translate}}
          </div>
        </ng-template>
      </a>
    </div>
    <div *ngIf="this.getCurrentAnnualCost() > 0" class="col-4 mobile-tab">
      <a (click)="onChangeTab('COSTS')" [class.active]="isTabSelected('COSTS')"
        [ngClass]="getPercentageAnnualSave() > 100 ? 'higher' : 'lower'" class="tab__item" id="toTab2">
        <div>{{'tabs.cost.title' | translate}}</div>
        <div>{{(determineSign(getPercentageAnnualSave()))}}{{ getPercentageAnnualSave() | numberFormat}}%</div>
        <div *ngIf="getPercentageAnnualSave() < 100">
          CHF {{getDifferenceAnnualCost() | numberFormat}}{{'tabs.cost.subtitle' | translate}}
        </div>
        <div *ngIf="getPercentageAnnualSave() > 100">
          CHF {{0 | numberFormat}}{{'tabs.cost.subtitle' | translate}}
        </div>
      </a>
    </div>
    <div *ngIf="this.getCurrentAnnualCost() > 0 && this.getPercentageAnnualCo2() >= 0" class="col-4 mobile-tab">
      <a (click)="onChangeTab('EMISSIONS')" [class.active]="isTabSelected('EMISSIONS')"
        [ngClass]="getPercentageAnnualCo2() > 100 ? 'higher' : 'lower'" class="tab__item" id="toTab3">
        <div>{{'tabs.emissions.title' | translate}}</div>
        <div>{{(determineSign(getPercentageAnnualCo2()))}}{{getPercentageAnnualCo2() | numberFormat}}%</div>
        <div>{{getCo2AnnualSave() | numberFormat}} {{'tabs.emissions.subtitle' | translate}}</div>
      </a>
    </div>
  </div>
  <div *ngIf="isTabSelected('INVESTMENT')" class="layout-row layout-row--mobile-padding row-tab" id="tab1">
    <div class="col-md-12 col-12 center mb tab__item__mobile panel--inves">
      <div *ngIf="isButtonSelected !== radioButtonType.ABONNEMENT_AIR">
        {{getTotalSimulationCost() | currencyFormat}}
      </div>
      <div *ngIf="isButtonSelected === radioButtonType.ABONNEMENT_AIR">
        {{aboAmount | currencyFormat}}
      </div>
      <ng-template [ngIf]="isButtonSelected !== radioButtonType.ABONNEMENT_AIR">
        <div *ngIf="this.getAmortisationPeriod() < 0 || this.getAmortisationPeriod() > 100">
          {{'tabs.investment.impossible' | translate}}
        </div>
      </ng-template>
      <ng-template [ngIf]="isButtonSelected === radioButtonType.ABONNEMENT_AIR">
        <div>
          {{'tabs.investment.subtitleC' | translate}}
        </div>
      </ng-template>
    </div>
    <div class="col-md-4 col-8 center mb no--display--mobile">
      <div class="img">
        <img *ngIf="activeSimulation.heating.name.includes('air')" alt="" src="./assets/images/image_aireau.svg">
        <img *ngIf="activeSimulation.heating.name.includes('sol')" alt="" src="./assets/images/image_geo.svg">
      </div>
      <div class="txt txt--center">{{activeSimulation.heating.name}}</div>
    </div>
    <div class="col-md-8">
      <div class="table panel--inves--content">
        <div
          *ngIf="(isButtonSelected === radioButtonType.INVESTISSEMENT_AIR || isButtonSelected === radioButtonType.INVESTISSEMENT_GEO)
                && isShowChart()"
          class="panel">
          <div class="txt txt--primary">
            {{'tabs.investment.graph.title' | translate}} {{amortizationYears}} {{'common.years' | translate}}
          </div>
          <div class="graph graph--amortissement">
            <div class="graph__content">
              <div *ngIf="this.getAmortisationPeriod() < 20" [style.left]="getYearsPercentage() + '%'" class="box">
                {{this.getAmortisationPeriod()}} {{'common.years' | translate}}
              </div>
              <div *ngIf="getMoneySavedAfter20Years() > 0" class="positive_text">
                + CHF {{getMoneySavedAfter20Years() | currencyFormat}}
              </div>
              <div class="negative_text">
                - CHF {{getTotalSimulationCost() | currencyFormat}}
              </div>
              <div class="graph-wrapper">
                <svg *ngIf="getYearsPercentage() > 0" class="positive">
                  <polygon [attr.points]="getGraphPointsPositive()" class="positive_edge_polygon_positive"></polygon>
                  <polygon [attr.points]="getGraphPointsNegative()" class="positive_edge_polygon_negative"></polygon>
                </svg>
              </div>
            </div>
            <div class="graph__bg">
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
              <span class="grid"></span>
            </div>
          </div>
        </div>
        <div *ngIf="isButtonSelected !== radioButtonType.ABONNEMENT_AIR" class="panel">
          <div class="txt txt--primary">{{'tabs.investment.cost.title' | translate}}</div>
          <div class="panel--item table__item">
            <div class="text-amount">{{'tabs.investment.cost.product' | translate}}</div>
            <div>+ CHF {{activeSimulation.cost | currencyFormat}}</div>
          </div>
          <div *ngIf="activeSimulation.subvention" class="panel--item table__item">
            <div>{{'tabs.investment.cost.subvention' | translate}}</div>
            <div>- CHF {{activeSimulation.subvention | currencyFormat}}</div>
          </div>
          <div class="panel--item panel--item--code table__item">
            <div class="form--code">
              <label class="label-code">{{ 'form.promo' | translate }}<span>
                  <img (mouseenter)="onMouseEnterPromoCode(true)" (mouseleave)="onMouseLeavePromoCode(false)"
                    class="form--code--img" [src]="'/assets/images/ico-info-grey.svg'">
                </span></label>
              <div class="pac-description form--code--hover" *ngIf="onHoverPromoCode">{{ 'form.codeHover' | translate }}
              </div>
              <input class="form__item--code--small" [disabled]="isCodeValid"
                [ngClass]="{'form--code--input' : showCodeMessage && !isCodeValid}" [(ngModel)]="discountCode"
                name="code" required type="text">
              <button [disabled]="!discountCode || isCodeValid" [hidden]="isCodeValidOnInit"
                class="btn--code btn--primary" (click)="checkCodeValid()" [ngClass]="{'hiddenBtn' : isCodeValid}">
                {{ 'form.codeButton' | translate }}</button>
            </div>
            <div *ngIf="discount.amount && isCodeValid">- CHF {{discount.amount}}</div>
            <div class="no-code" *ngIf="!discount.amount || !isCodeValid">- CHF</div>
          </div>
          <div *ngIf="showCodeMessage" class="panel--noti panel--item--msg">
            <div *ngIf="isCodeValid" class="txt txt--valid msg">{{ 'form.codeValid' | translate }}</div>
            <div *ngIf="!isCodeValid" class="txt txt--error msg">{{ 'form.codeInvalid' | translate }}</div>
          </div>
          <div class="table__item table__item--total">
            <div>{{'tabs.investment.cost.total' | translate}}</div>
            <div>CHF {{getTotalSimulationCost() | currencyFormat}}</div>
          </div>
          <div class="txt txt--label txt--grey">
            <span>
              {{'tabs.investment.cost.disclaimer.prefix' | translate}}
              <a href="https://www.suisseenergie.ch/page/fr-ch/pompes-a-chaleur-systeme-module"
                target="_blank">{{'tabs.investment.cost.disclaimer.link' | translate}}</a>
              {{'tabs.investment.cost.disclaimer.suffix' | translate}}
            </span>
          </div>
        </div>
        <div *ngIf="isButtonSelected === radioButtonType.ABONNEMENT_AIR" class="panel">
          <div class="txt txt--primary">{{'tabs.abonnement.title' | translate}}</div>
          <div class="table__item">
            <div>{{'tabs.abonnement.install' | translate}}</div>
            <div>{{'tabs.abonnement.include' | translate}}</div>
          </div>
          <div class="table__item">
            <div>{{'tabs.abonnement.maintenance' | translate}}</div>
            <div>{{'tabs.abonnement.include' | translate}}</div>
          </div>
          <div class="table__item">
            <div>{{'tabs.abonnement.repair' | translate}}</div>
            <div>{{'tabs.abonnement.include' | translate}}</div>
          </div>
          <div class="table__item">
            <div>{{'tabs.abonnement.15years' | translate}}</div>
            <div>{{'tabs.abonnement.include' | translate}}</div>
          </div>
          <div class="table__item table__item--total">
            <div>{{'tabs.investment.cost.total' | translate}}</div>
            <div>{{aboAmount | currencyFormat}}/{{'common.month' | translate}}</div>
          </div>
          <div class="txt txt--label txt--grey">
            <span>
              {{'tabs.investment.cost.disclaimer.prefix' | translate}}
              <a href="https://www.suisseenergie.ch/page/fr-ch/pompes-a-chaleur-systeme-module"
                target="_blank">{{'tabs.investment.cost.disclaimer.link' | translate}}</a>
              {{'tabs.investment.cost.disclaimer.suffix' | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isTabSelected('COSTS')" class="layout-row row" id="tab2">
  <div class="col-md-12 col-12 center mb tab__item__mobile">
    <div>{{determineSign(getPercentageAnnualSave())}}{{ getPercentageAnnualSave() | numberFormat}}%</div>
    <div *ngIf="getPercentageAnnualSave() < 100">
      CHF {{getDifferenceAnnualCost() | numberFormat}}{{'tabs.cost.subtitle' | translate}}
    </div>
    <div *ngIf="getPercentageAnnualSave() > 100">
      CHF {{0 | numberFormat}}{{'tabs.cost.subtitle' | translate}}
    </div>
  </div>
  <ng-template [ngIf]="isButtonSelected !== radioButtonType.ABONNEMENT_AIR">
    <div class="panel col-12">
      <div class="panel__title"> {{'tabs.cost.actual' | translate}} <span class="txt txt--primary">
          CHF {{getCurrentAnnualCost() | numberFormat}}.-/{{'common.year' | translate}}</span>
      </div>
      <div class="panel__content">
        <div [style.max-width.%]="getCurrentCostPercentageOfTotalCost() | numberFormat"
          [style.width.%]="getCurrentBarPercentage() | numberFormat" class="graph--progress graph--progress--first">
          <div class="graph--progress__bar"></div>
          <div class="graph--progress__text">
            <div>{{'tabs.cost.consumption' | translate}}</div>
            <h3 class="graph--progress__text--price--first">
              CHF {{lead.currentHeating.cost | currencyFormat}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="panel col-12">
      <div class="panel__title"> {{'tabs.cost.new' | translate}} <span class="txt txt--primary">
          CHF {{getNewAnnualCost() | numberFormat}}.-/{{'common.year' | translate}}</span></div>
      <div class="panel__content">
        <div [style.max-width.%]="100 | numberFormat"
             [style.width.%]="100 - getNewPercentageDifference() | numberFormat"
             class="graph--progress graph--progress--first">
          <div class="graph--progress__bar"></div>
          <div class="graph--progress__text">
            <div>{{'tabs.cost.consumption' | translate}}</div>
            <h3 class="graph--progress__text--price--first">
              CHF {{ getNewAnnualCost() | currencyFormat}}</h3>
          </div>
        </div>
        <div *ngIf="getPercentageAnnualSave() < 100" [style.width.%]="getNewPercentageDifference() | flooringFormat"
             class="graph--progress graph--progress--last3">
          <div class="graph--progress__bar"></div>
          <div class="graph--progress__text graph--progress__phone3">
            <div>{{'tabs.cost.difference' | translate}}</div>
            <h2 class="graph--progress__text--price--last3">
              CHF {{getDifferenceAnnualCost() | currencyFormat}}</h2>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="isButtonSelected === radioButtonType.ABONNEMENT_AIR">
    <div class="panel col-12">
      <div class="panel__title"> {{'tabs.cost.actual-abo' | translate}} <span class="txt txt--primary ">
          CHF {{getCurrentAnnualCost() | numberFormat}}.-/{{'common.year' | translate}}</span>
      </div>
      <div class="panel__content">
        <div [style.max-width.%]="100 - ( (getNewAnnualCost()) - getCurrentCostPercentageOfTotalCost() ) | numberFormat"
          [style.width.%]="getCurrentCostPercentageOfTotalCost() | numberFormat"
          class="graph--progress graph--progress--first">
          <div class="graph--progress__bar"></div>
          <div class="graph--progress__text">
            <div>{{'tabs.cost.consumption' | translate}}</div>
            <h3 class="graph--progress__text--price--first">
              CHF {{lead.currentHeating.cost | currencyFormat}}</h3>
          </div>
        </div>
        <div [style.width.%]="getCurrentMaintenancePercentageOfTotalCost() | numberFormat"
          class="graph--progress graph--progress--middle">
          <div class="graph--progress__bar"></div>
          <div [style.right]="ifToSmallMoveRight()" class="graph--progress__text graph--progress__phone">
            <div>{{'tabs.cost.maintenance' | translate}}</div>
            <h3 class="graph--progress__text--price--last">
              CHF {{ this.lead.currentHeating.maintenanceCost | currencyFormat}}</h3>
          </div>
        </div>
        <div [style.width.%]="getCurrentInvestmentPercentageOfTotalCost() | flooringFormat"
          class="graph--progress graph--progress--last4">
          <div class="graph--progress__bar"></div>
          <div [style.right]="ifToSmallMoveRight()" class="graph--progress__text graph--progress__phone">
            <div>{{'tabs.cost.investment' | translate}}</div>
            <h3 class="graph--progress__text--price--amo">
              CHF {{ (this.lead.investment / 15) | currencyFormat}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="panel col-12">
      <div class="panel__title"> {{'tabs.cost.subscription' | translate}} <span class="txt txt--primary">
          CHF {{getNewAnnualCost() | numberFormat}}.-/{{'common.year' | translate}}</span></div>
      <div class="panel__content">
        <div [style.max-width.%]="100 - getNewAboPercentageOfTotalCost() - getNewPercentageDifference() | numberFormat"
          [style.width.%]="100 - getNewAboPercentageOfTotalCost() - getNewPercentageDifference() | numberFormat"
          class="graph--progress graph--progress--first">
          <div class="graph--progress__bar"></div>
          <div class="graph--progress__text">
            <div>{{'tabs.cost.consumption' | translate}}</div>
            <h3 class="graph--progress__text--price--first">
              CHF {{ this.activeSimulation.heating.cost | currencyFormat}}</h3>
          </div>
        </div>
        <div [style.width.%]="getNewAboPercentageOfTotalCost() | flooringFormat"
          class="graph--progress graph--progress--abo">
          <div class="graph--progress__bar"></div>
          <div [style.right]="ifToSmallMoveRight()" class="graph--progress__text graph--progress__phone">
            <div>{{'tabs.cost.abonnement' | translate}}</div>
            <h3 class="graph--progress__text--price--abo">
              CHF {{ getAboAnnualCost() | currencyFormat}}</h3>
          </div>
        </div>
        <div *ngIf="getPercentageAnnualSave() < 100" [style.width.%]="getNewPercentageDifference() | numberFormat"
          class="graph--progress graph--progress--last3">
          <div class="graph--progress__bar"></div>
          <div class="graph--progress__text graph--progress__phone3">
            <div>{{'tabs.cost.difference' | translate}}</div>
            <h2 class="graph--progress__text--price--last3">
              CHF {{getDifferenceAnnualCost() | currencyFormat}}</h2>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="isTabSelected('EMISSIONS')" class="layout-row row" id="tab3">
  <div class="col-md-12 col-12 center mb tab__item__mobile">
    <div>{{determineSign(getPercentageAnnualCo2())}}{{getPercentageAnnualCo2() | numberFormat}}%</div>
    <div>{{getCo2AnnualSave() | numberFormat}}{{'tabs.emissions.subtitle' | translate}}</div>
  </div>
  <div class="col-12 panel">
    <div class="panel__title panel__title__tabs center--title--phone">{{'tabs.emissions.tabTitle' | translate}}</div>
  </div>
  <div class="layout-row row row--with-3-columns full-width-row">
    <div class="col-md-4">
      <div class="panel panel--white panel--center full-width">
        <div class="illustration--tabs">
          <img alt="" class="icon--emissions--tabs" src="./assets/images/illu-car.svg">
        </div>
        <div class="panel__title panel__title__tabs txt txt--primary"> {{getKilometersSave() | numberFormat}} km</div>
        <div class="panel__content">{{'tabs.emissions.car' | translate}}</div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel panel--white panel--center full-width">
        <div class="illustration--tabs">
          <img alt="" class="icon--emissions--tabs" src="./assets/images/illu-ecolo.svg">
        </div>
        <div class="panel__title panel__title__tabs txt txt--primary">{{getEcologicalFootprintSave() | numberFormat}}%
        </div>
        <div class="panel__content">{{'tabs.emissions.environment' | translate}}</div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel panel--white panel--center full-width">
        <div class="illustration--tabs">
          <img alt="" class="icon--emissions--tabs" src="./assets/images/illu-trees.svg">
        </div>
        <div class="panel__title panel__title__tabs txt txt--primary"> {{getTreesSave() | numberFormat}}</div>
        <div class="panel__content">{{'tabs.emissions.trees' | translate}}</div>
      </div>
    </div>
  </div>
</div>
