import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-result-stepper',
  templateUrl: './stepper.component.html'
})
export class StepperComponent implements OnInit {

  constructor() {
  }

  public ngOnInit(): void {
  }

}
