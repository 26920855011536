import { LeadValidate } from '../../shared/services/lead/lead.validate';
import { CalendlyService } from '../../shared/services/calendly.service';
import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {HeaderService} from '../../shared/services/header.service';
import {LeadDTO} from '../../shared/services/lead/lead.dto';
import {Configs} from '../../shared/config.constant';
import {UserActionService} from '../../shared/services/user/user-action.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DealStatus} from '../../shared/services/lead/deal-status.enum';
import {CheckoutService} from '../../shared/services/checkout/checkout.service';
import {NotificationService} from '../../shared/services/notification.service';
import {Title} from './header-partner.enum';
import {Router} from '@angular/router';
import {NewLeadEventModel} from '../../shared/services/websocket/new-lead-event.model';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {AdviceModalComponent} from '../../shared/components/advice-modal/advice-modal.component';
import {UserTrackingService} from '../../shared/services/user/user-tracking.service';
import {States} from '../../enum/states.enum';

@Component({
  selector: 'app-header-partner',
  templateUrl: './header-partner.component.html',
  styleUrls: ['./header-partner.component.scss']
})
export class HeaderPartnerComponent implements OnInit {
  private static readonly MODAL_ACTIVE_CLASS = 'active';
  @Input()
  public partnerName!: string;
  public lead!: LeadDTO;
  public newLead!: NewLeadEventModel;
  public titlePage!: string;
  public titleEnum = Title;
  public open!: boolean;
  public emailForm!: FormGroup;
  public information!: boolean;
  public isSuccessful = false;
  public configs = Configs;
  public customerPostalCode!: number;
  private modalRef?: HTMLElement;

  bsModalRef?: BsModalRef;
  constructor(private readonly headerService: HeaderService,
              private readonly elRef: ElementRef,
              private readonly renderer: Renderer2,
              private readonly userActionService: UserActionService,
              private readonly notificationService: NotificationService,
              private readonly checkoutService: CheckoutService,
              private readonly router: Router,
              private readonly calendlyService: CalendlyService,
              private readonly leadValidate: LeadValidate,
              private readonly modalService: BsModalService,
              private readonly userTrackingService: UserTrackingService) {
  }

  public ngOnInit(): void {
    this.getTitle();

    this.userActionService.disableCallMeMessage();

    this.userActionService.getCallMeMessage().subscribe(isModalEnabled => {
      this.isSuccessful = isModalEnabled;
    });

    this.modalRef = this.elRef.nativeElement.querySelector('#modal-email') as HTMLElement;
    this.showBodyScrollbar();

    this.headerService.getHeaderContent().subscribe(lead => {
      if (lead && lead.uuid && lead.uuid !== '') {
        this.lead = lead;
      }
    });

    this.headerService.getHeaderNewLead().subscribe(newLead => {
      if (newLead && newLead.uuid && newLead.uuid !== '') {
        this.newLead = newLead;
      }
      if (this.newLead) {
        this.customerPostalCode = Number(this.newLead.customer.postalCode);
      }
    });

    this.headerService.getShowHeader().subscribe(information => {
      this.information = information;
    });

    this.headerService.getOpenModalCalendly().subscribe(() => {
      this.openModalCalendly();
    });

    this.headerService.getOpenCallModalCalendly().subscribe(() => {
      this.openCalendlyModal();
    });

    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(Configs.VALIDATION.EMAIL_PATTERN)])
    });

  }

  public isNotOnSimulationPage(): boolean {
    return this.router.url.indexOf('/simulation') === -1;
  }

  public showModal(event): void {
    event.preventDefault();
    this.renderer.addClass(this.modalRef, HeaderPartnerComponent.MODAL_ACTIVE_CLASS);
    this.hideBodyScrollbar();
  }


  public handleClickOutside(event): void {
    if (event.target.classList && event.target.classList.contains(HeaderPartnerComponent.MODAL_ACTIVE_CLASS)) {
      this.onClickCloseModalButton();
      this.showBodyScrollbar();
    }
  }

  public getActualCost(): number {
    return this.lead.currentHeating.cost + this.lead.currentHeating.maintenanceCost;
  }

  public toggleHeader(): void {
    this.open = !this.open;
  }

  public openModalCalendly(): void {
    this.userActionService.enableModal();
    this.hideBodyScrollbar();
  }

  public onSubmit(): void {
    if (this.emailForm && this.emailForm.valid) {
      const email = this.emailForm.get('email');
      if (email) {
        this.checkoutService.resendOffer(email.value).subscribe(
          () => {
            this.onClickCloseModalButton();
            this.notificationService.showSuccessMessage('header.already.offered.success');
          },
          () => {
            this.notificationService.showErrorMessage('header.already.offered.error');
          });
      }
    }
  }

  public isAppointmentButtonVisible(): boolean {
    return this.lead.customer.owner && this.isEligible() && this.isFromRegion() && this.isNotOnOffer()
      && this.leadValidate.checkCalculatedHeatingPower(this.lead);
  }

  public isCallMeButtonVisibleForPV(): boolean {
    return (this.isFromRegionPV() || (this.customerPostalCode >= 1600 && this.customerPostalCode < 1800) ) && this.isNotOnOfferForPV();
  }

  public isCallMeButtonVisible(): boolean {
    return this.lead.customer.owner && !this.isEligible() && this.isFromRegion() && this.isNotOnOffer();
  }

  public onClickCloseModalButton(): void {
    this.renderer.removeClass(this.modalRef, HeaderPartnerComponent.MODAL_ACTIVE_CLASS);
    this.showBodyScrollbar();
  }

  private isEligible(): boolean {
    return this.leadValidate.isEligible(this.lead);
  }


  private isFromRegion(): boolean {
    return this.leadValidate.isFromRegion(this.lead);
  }

  private isFromRegionPV(): boolean {
    return Object.values(States).some(val => val === this.newLead.customer.postalCode.substring(0, 2));
  }

  public isFromFribourgArea(): boolean {
    return this.leadValidate.isFromFribourgArea(this.customerPostalCode);
  }

  private isNotOnOffer(): boolean {
    return this.leadValidate.isNotOnOffer(this.lead);
  }

  private isNotOnOfferForPV(): boolean {
    return this.newLead.dealStatus === null || this.newLead.dealStatus === DealStatus.QUOTE_RECEIVED;
  }

  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  public checkCalculatedHeatingPower(): boolean {
    return this.leadValidate.checkCalculatedHeatingPower(this.lead);
  }

  private getTitle(): void {
    this.headerService.getTitle().subscribe(title => {
      this.titlePage = title;
    });
  }

  public openCecbCalendlyModal(): boolean {
    const urlCalendly = Configs.CALENDLY.URL + Configs.CALENDLY.STYLES; // one-time use & not the same calendly team
    this.calendlyService.callCalendly(urlCalendly, this.lead);
    return false;
  }

  public openUnsuitableCalendlyModal(): boolean {
    const urlCalendly = this.calendlyService.buildCalendlyUrlUnsuitable();
    this.calendlyService.callCalendly(urlCalendly, this.lead);
    return false;
  }

  public openCalendlyModal(): boolean {
    const lead = this.newLead ? this.newLead : this.lead;
    if (lead) {
      const urlCalendly = this.calendlyService.buildCalendlyUrl(lead);
      this.calendlyService.callCalendly(urlCalendly, lead);
    }
    return false;
  }

  public isValidAddress(lead: any): boolean {
    if (lead.customer) {
      return lead.customer.address && lead.customer.locality && lead.customer.postalCode;
    }
    return false;
  }

  public showAdviceModal(): void {
    const initialState: ModalOptions = {
      animated: true
    };
    this.bsModalRef = this.modalService.show(AdviceModalComponent, initialState);
  }

  public trackClickOnGetSimulation(): void {
    this.userTrackingService.trackEvent('CTA', 'simulationButtonOnHeaderClicked');
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
