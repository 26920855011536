export class CustomerDTO {
  id!: number;
  uuid!: string;
  owner!: boolean;
  civility!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  phone!: string;
  streetNumber!: string;
  address!: string;
  locality!: string;
  state!: string;
  postalCode!: string;
}
export class Quotation {
  quotationText!: string;
  authorSurname!: string;
  authorVillage!: string;
}
