import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {registerLocaleData, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import {UserTrackingService} from './shared/services/user/user-tracking.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {UserActionService} from './shared/services/user/user-action.service';
import {getBrowserFormat} from './shared/services/browser-format.enum';
import {AppConfig} from './dynamic-config/AppConfig';
import {AppConfigService} from './dynamic-config/AppConfigService';
import {Configs} from './shared/config.constant';
import {PartnersList} from './shared/guards/partner-enum';

export let browserLoad = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  public subscription: Subscription;
  private environment: AppConfig;
  public partnerName: string | null;
  public isPartnerHeader = false;
  public isBorne = false;
  public isPartnerConditionsHeader = false;
  public isNextStepHeader = false;

  constructor(private readonly translate: TranslateService,
              private readonly userTrackingService: UserTrackingService,
              public router: Router,
              private titleService: Title,
              private metaService: Meta,
              private gtmService: GoogleTagManagerService,
              private readonly appConfigService: AppConfigService,
              private readonly userActionService: UserActionService,
              private location: Location) {
    this.environment = this.appConfigService.getConfig();
    this.isBorne = ('borne' === this.environment.client);
    this.userTrackingService.registerAnalytics();
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserLoad = !router.navigated;
      }
    });
    const matches = this.location.path().match(/[^\/]+/g);
    this.partnerName = (matches !== null && matches[0]) ? matches[0].toLowerCase() : null;
    // check if partner name contains an utm_source parameter
    if (this.partnerName?.includes('?utm_source')) {
      const splitPartnerName = this.partnerName.split('?');
      if (splitPartnerName) {
        this.partnerName = splitPartnerName[0];
      }
    }
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(event) {
    this.userActionService.changeBrowserFormat(getBrowserFormat(event.window.innerWidth));
  }

  public ngOnInit(): void {
    registerLocaleData(localeEnGb, 'en-GB');
    registerLocaleData(localeFr, 'fr-FR');
    this.translate.setDefaultLang(this.environment.DEFAULT_LANGUAGE);
    // for the moment default language set to FR, use a locale selector and set the value here
    this.translate.use(this.environment.DEFAULT_LANGUAGE);
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
    if (this.environment.client === 'pac') {
      this.setTitle('Calculez le prix de votre pompe à chaleur - Romande Energie');
      this.metaService.addTags([
        {
          name: 'description', content: 'Obtenez votre devis complet gratuitement et réalisez des économies en ' +
            'toute simplicité grâce à votre pompe à chaleur !'
        },
        {name: 'keywords', content: 'Romande Energie, PAC, chauffage, energie, Pompe à chaleur, devis, offre'}
      ]);
    } else if (this.environment.client === 'pv') {
      // this.setTitle('Calculez le prix de votre installation solaire photovoltaïque - Romande Energie');
      this.metaService.addTags([
        {name: 'description', content: 'Obtenez votre devis complet gratuitement pour votre future installation solaire photovoltaïque !'},
        {name: 'keywords', content: 'Romande Energie, PV, panneaux solaires,  photovoltaïque, devis, offre'}
      ]);
    } else {
      this.setTitle('Borne - Romande Energie');
      // TO-DO: this.metaService.addTags(...)
    }
    this.userActionService.changeBrowserFormat(getBrowserFormat(window.innerWidth));
    this.isPartnerHeader = this.isPartnerRoute();
    this.isPartnerConditionsHeader = this.isPartnerConditions();

    // Set the localStorage date
    if (!localStorage.getItem(Configs.LOCAL_STORAGE.DATE_POPUP)) {
      localStorage.setItem(Configs.LOCAL_STORAGE.DATE_POPUP, new Date().getTime().toString());
    }

    if (!localStorage.getItem(Configs.LOCAL_STORAGE.DISPLAY_POPUP)) {
      localStorage.setItem(Configs.LOCAL_STORAGE.DISPLAY_POPUP, 'true');
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  private isPartnerRoute(): boolean {
    return this.partnerName !== '' &&
      this.partnerName != null &&
      !['simulation', 'checkout', 'offer', 'avantages', 'mailing', 'abonnement'].includes(this.partnerName) &&
      !this.partnerName.includes('utm_source') &&
      Object.values(PartnersList).some(val => val === this.partnerName);
  }

  private isPartnerConditions(): boolean {
    const matches = this.location.path().match(/[^\/]+/g);
    /**
     * This condition is for the routes /partenaires/conditions (PAC and PV access) /partenaires/conditions-forage (only PV access)
     * A guard exists too for control the access but here, the condition is for the header visible or not because page is an iframe
     * And for redirection if the route is not a good one
     */
    if (!!matches && matches.length > 1 && matches[0] === 'partenaires') {
      if (this.environment.client === 'pac' && matches[1] === 'conditions-forage') {
        return true;
      } else if ((this.environment.client === 'pac' || this.environment.client === 'pv') && matches[1] === 'conditions') {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

}
