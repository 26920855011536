import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BackendService} from '../backend.service';
import {CheckoutModel} from '../../../checkout/overview/checkout.model';
import {map, tap} from 'rxjs/operators';
import {AuthenticationService} from '../authentication.service';
import {AppConfigService} from "../../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class CheckoutService extends BackendService<any> {

  constructor(http: HttpClient, private readonly authenticationService: AuthenticationService,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  service(): string {
    return 'checkout';
  }

  public registration(uuid: string, checkout: CheckoutModel, marketingCampaign: string | null): Observable<string> {
    const body = {
      civility: checkout.civility,
      firstName: checkout.firstName,
      lastName: checkout.lastName,
      email: checkout.email,
      phone: checkout.phone,
      owner: checkout.owner,
      interest: checkout.installation,
      discountCode: checkout.discountCode,
      marketingCampaign,
      partnerName: checkout.partnerName,
      projectType: this.environment.client,
      installDate: checkout.installation
    };

    return this.post(`/${uuid}`, body)
      .pipe(map(response => response.token))
      .pipe(tap(token => this.authenticationService.saveToken(token)));
  }

  public resendOffer(email: string): Observable<void> {
    return this.post(`/email/${email}`, {});
  }
}
