import {Injectable} from '@angular/core';
import {DownloadPdfModel} from '../../components/download-pdf/download-pdf.model';
import {Configs} from '../../config.constant';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  public setDownloadPdfModel(value: DownloadPdfModel): void {
    localStorage.setItem(Configs.LOCAL_STORAGE.DOWNLOAD_PDF_MODEL, JSON.stringify(value));
  }

  public getDownloadPdfModel(): DownloadPdfModel {
    return JSON.parse(localStorage.getItem(Configs.LOCAL_STORAGE.DOWNLOAD_PDF_MODEL) || 'null');
  }
}
