<div class="phone--panel--web">
  <div class="panel phone--panel phone--panel"
  (mouseenter)="onMouseEnterPhone(true)"
  (mouseleave)="onMouseLeavePhone(false)"
  (click)="isSelected = !isSelected"
  [ngClass]="[onHoverPhone ? 'phone--panel--onHover' : '', isSelected ? 'phone--panel--isSelected' : '']">
    <div class="illustration phone--icon" [ngClass]="onHoverPhone || isSelected ? 'phone--icon--isSelected' : ''">
      <img alt="" src="./assets/images/ico-phone.svg">
    </div>
    <div class="phone--text">
      {{'common.contacts.title' | translate}}
    </div>
  </div>
  <div *ngIf="isSelected" class="panel phone--number--panel">
    <h3>{{'common.contacts.phoneNumber' | translate}}</h3>
    <p>{{'common.contacts.time' | translate}}</p>
  </div>
</div>
<div *ngIf="isQuickCall" class="panel phone--panel phone--panel--mobile">
  <div class="illustration phone--icon" (click)="showAdviceModal()">
    <div class="no--display--web" >
      <img alt="" src="./assets/images/ico-phone.svg">
    </div>
  </div>
</div>
