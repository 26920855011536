import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router';
import {PartnersList} from './partner-enum';
import {Observable} from 'rxjs';

@Injectable()
export class PartnerRouteGuard implements CanActivate {
  constructor(private readonly router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const partner = route.paramMap.get('partner');

    if (!!partner && Object.values(PartnersList).some(val => val === partner)) {
      return true;
    }

    // not logged in so redirect to home page
    return this.router.createUrlTree(['/']);
  }
}

