import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html'
})
export class LoaderComponent implements OnInit {
  public isLoading = false;
  @Input()
  public spinner!: boolean;
  @Input()
  public paddingTop = true;

  constructor(
    private readonly loaderService: LoaderService) {
  }

  public ngOnInit(): void {
    this.loaderService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
  }

}
