import { Discount } from './../discount/discount.dto';
import {HeatingDTO} from './heating.dto';
import {CustomerDTO} from '../customer/customer.dto';
import {SimulationResultDTO} from './simulation-result.dto';
import {DealStatus} from './deal-status.enum';
import {OfferDTO} from '../offer/offer.dto';
import {MyDocument} from '../../components/my-documents/my-document.dto';
import {OwnerDTO} from './Owner.dto';

export class LeadDTO {
  id!: number;
  uuid!: string;
  freshSalesId!: string;
  customer!: CustomerDTO;
  currentHeating!: HeatingDTO;
  airWaterSimulationResult!: SimulationResultDTO;
  geoSimulationResult!: SimulationResultDTO;
  offer!: OfferDTO;
  offerGeo!: OfferDTO;
  offerAir!: OfferDTO;
  appointmentEventType!: string;
  callEventType!: string;
  shortCallEventType!: string;
  callLink!: string;
  dealStatus!: DealStatus;
  createdAt!: Date;
  consummation!: number;
  estimatedProduction!: number;
  investment!: number;
  totalSize!: number;
  numberOfPanel!: number;
  buildingUuid!: string;
  simulationUuid!: string;
  heatDistributionSystem!: string;
  discount!: Discount;
}

export class LeadPVDTO {
  lead!: LeadDTO;
  documents!: MyDocument[];
  owner!: OwnerDTO;
}
