import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'flooringFormat'
})
export class FlooringFormatPipe implements PipeTransform {
  transform(value: number): string {
    const stringValue = value.toString();

    return stringValue.substr(0, stringValue.indexOf('.'));
  }
}
