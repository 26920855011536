<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="footer__title">{{"footer.title.label" | translate}}</div>
        <div class="d-flex">
          <a class="ico ico--fb"
             href="https://fr-fr.facebook.com/RomandeEnergie"
             rel="noopener noreferrer"
             target="_blank">
          </a>
          <a class="ico ico--tw"
             href="https://twitter.com/RomandeEnergie"
             rel="noopener noreferrer"
             target="_blank">
          </a>
          <a class="ico ico--in"
             href="https://www.linkedin.com/company/romande-energie"
             rel="noopener noreferrer"
             target="_blank">
          </a>
          <a class="ico ico--yb"
             href="https://www.youtube.com/user/RomandeEnergieSA"
             rel="noopener noreferrer"
             target="_blank">

          </a>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="footer__title">{{"footer.newsletter.title.label" | translate}}</div>
        <div class="btns">
          <a class="btn btn--primary btn--blue"
             href="https://www.romande-energie.ch/inscription-a-la-newsletter"
             rel="noopener noreferrer"
             target="_blank">{{"footer.newsletter.button.label" | translate}}</a>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="footer__title">{{"footer.contact.title.label" | translate}}</div>
        <div class="txt txt--label--footer">
          <p>{{"footer.contact.name.label" | translate}}</p>
          <p>{{"footer.contact.address.label" | translate}}</p>
          <p>{{"footer.contact.email.label" | translate}}</p>
          <p>{{"footer.contact.phone.label" | translate}}</p>
          <p>{{"footer.contact.emergency.electricity.label" | translate}}</p>
          <p>{{"footer.contact.emergency.products.label" | translate}}</p>
        </div>
        <div class="txt txt--label--footer">
          <p>{{this.actualYear}}{{"footer.copyright.label" | translate}}|
            <a href="https://www.romande-energie.ch/conditions-internet"
               rel="noopener noreferrer"
               target="_blank">{{"footer.copyright.link" | translate}}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
