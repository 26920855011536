<header [class.open]="open" class="header" style="padding: 7.5px 0px">
  <div class="header-partner__logo">
    <img class="header__image logo_partner" [alt]="partnerName" (click)="goToLink('https://www.bcv.ch/')"
         [src]="['../../../assets/images/partner/'+ partnerName + '/logo.png']">
  </div>

  <div class="header__info">
    <ng-template *disabledForClient="'pv'" [ngIf]="lead && lead.uuid && information">
      <div class="txt txt--label">{{'header.customer.title' | translate}}</div>
      <div (click)="toggleHeader()" class="js-header-btn">
        <div *ngIf="isValidAddress(lead)">
          {{lead.customer.address}}{{lead.customer.streetNumber ? ', ' + lead.customer.streetNumber : ''}}
        </div>
        <div *ngIf="isValidAddress(lead)">{{lead.customer.postalCode}} {{lead.customer.locality}}</div>
        <div class="header__info__mobile">
          <div>
            <div class="txt txt--label">{{'header.customer.consumption' | translate}}</div>
            <div class="txt txt--primary">{{lead.currentHeating.consumptionKwh | numberFormat}}
              {{'common.kwh' | translate}}/{{'common.year' | translate}}</div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.cost' | translate}}</div>
            <div class="txt txt--primary">CHF {{getActualCost() | currencyFormat}}/{{'common.year' | translate}}</div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.emissions' | translate}}</div>
            <div class="txt txt--primary">{{lead.currentHeating.co2Output | numberFormat}}
              {{'common.kg' | translate}}/{{'common.year' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.consumption' | translate}}</div>
        <div class="txt txt--primary">{{lead.currentHeating.consumptionKwh | numberFormat}}
          {{'common.kwh' | translate}}/{{'common.year' | translate}}</div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.cost' | translate}}</div>
        <div class="txt txt--primary">CHF {{getActualCost() | currencyFormat}}/{{'common.year' | translate}}</div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.emissions' | translate}}</div>
        <div class="txt txt--primary">{{lead.currentHeating.co2Output | numberFormat}}
          {{'common.kg' | translate}}/{{'common.year' | translate}}</div>
      </div>

      <div *ngIf="isCallMeButtonVisible() && checkCalculatedHeatingPower()"
           class="btns header__info__section btn-panel">
        <div (click)="openCecbCalendlyModal()" class="btn btn--primary btn-right">{{'common.schedule' | translate}}</div> <!-- TODO: Change to meet.me when visit are back -->
        <div class="btn--primary btn-right">
          <a href="tel:{{'common.contacts.phoneNumber' | translate}}">
            <img class="phone--icon" alt="" src="./assets/images/ico-phone.svg">
          </a>
        </div>
      </div>

      <div *ngIf="!checkCalculatedHeatingPower() && lead.customer.owner" class="btns header__info__section">
        <div (click)="openCalendlyModal()" class="btn btn--primary">{{'common.schedule' | translate}}
        </div>
      </div>
    </ng-template>

    <ng-template *disabledForClient="'pac'" [ngIf]="newLead && information">
      <div class="txt txt--label">
        <br>
      </div>
      <div (click)="toggleHeader()" class="js-header-btn">
        <div *ngIf="isValidAddress(newLead)">
          {{newLead.customer.address}}{{newLead.customer.streetNumber ? ', ' + newLead.customer.streetNumber : ''}}
        </div>
        <div *ngIf="isValidAddress(newLead)">{{newLead.customer.postalCode}} {{newLead.customer.locality}}</div>
        <div class="header__info__mobile">
          <div>
            <div class="txt txt--label">{{'header.customer.proposed-power-pv' | translate}}</div>
            <div class="txt txt--primary">{{(newLead.systemTotalSize || 0)}}
              {{'common.kwc' | translate}}</div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.estimated-production' | translate}}</div>
            <div class="txt txt--primary">{{(newLead.estimatedProduction || 0) | numberFormat}}
              {{'common.kwh' | translate}}/{{'common.year' | translate}}
            </div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.investment' | translate}}</div>
            <div class="txt txt--primary">CHF {{(newLead.investment || 0) | currencyFormat}}</div>
          </div>
        </div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.proposed-power-pv' | translate}}</div>
        <div class="txt txt--primary">{{(newLead.systemTotalSize || 0)}}
          {{'common.kwc' | translate}}</div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.estimated-production' | translate}}</div>
        <div class="txt txt--primary">{{(newLead.estimatedProduction || 0) | numberFormat}}
          {{'common.kwh' | translate}}/{{'common.year' | translate}}
        </div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.investment' | translate}}</div>
        <div class="txt txt--primary">CHF {{(newLead.investment || 0) | currencyFormat}}</div>
      </div>

    </ng-template>
  </div>

  <div>
    <img class="header__imag logo_re" alt="Romande energie" (click)="goToLink('https://www.romande-energie.ch/')" src="/assets/images/logo.png">
  </div>
</header>
