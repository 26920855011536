<div class="container">
  <div class="layout-row row">
    <div class="col-12">
      <h3 class="txt txt--red txt--med txt--center txt--title-section">
        {{'home.quotation.title' | translate}}
      </h3>
      <div class="masonry">
        <div *ngFor="let quotation of quotations$ | async" class="masonry__brick">
          <div class="citation">
            <div class="citation__illustration">
              <img alt="" src="./assets/images/quotation-icon.svg">
            </div>
            <div class="citation__content">
              <div class="row">
                <div class="col-12">
                  <div class="citation__content__text">
                    <p>
                      {{quotation.quotationText}}
                    </p>
                  </div>
                </div>
                <div class="col-12">
                  <div class="citation__content__surname">
                    {{quotation.authorSurname}}, {{quotation.authorVillage}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
