<div class="layout-row" id="documents">
  <h2>
    {{'my-document.title' | translate}}
  </h2>
  <div *ngIf="!myDocuments$" class="panel panel--appointment">
    <svg class="spinner--rotate" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" fill="none" r="20" stroke-width="3"></circle>
    </svg>
    <div class="panel__title">
      <div class="txt txt--med">{{'document.pending.title' | translate}}</div>
    </div>
  </div>

  <table *ngIf="myDocuments$" class="table--full-width table--padding-top-bottom table--border-spacing">
    <caption></caption>
    <tbody id="documentsTable">
      <tr [ngClass]="classDocuments">
        <td class="table__col--small table__col--center">
          <img class="table__icon" alt="" src="./assets/images/livreblanc.svg">
        </td>
        <td class="txt--black">
          <span *disabledForClient="'pv'" class="txt--black"> Livre blanc - Pompes à chaleur </span>
          <span *disabledForClient="'pac'" class="txt--black"> Livre blanc - Panneaux photovoltaïques </span>
          <br />
          <span class="txt--light-black table__col--mobile-show">
              {{lead?.createdAt | date : configs.DATE_FORMAT.DEFAULT}}, PDF, 2.3 MB
            </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
            <span class="txt--light-black">
              {{lead?.createdAt | date : configs.DATE_FORMAT.DEFAULT}}
            </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
            <span class="txt--light-black">
              PDF
            </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
            <span *disabledForClient="'pv'" class="txt--light-black">
              2.3 MB
            </span>
          <span *disabledForClient="'pac'" class="txt--light-black">
              5 MB
            </span>
        </td>
        <td class="table__col--medium table__col--action table__col--tablet-auto-width table__col--center">
            <span *disabledForClient="'pv'" id="dlLbPac" (click)="downloadFileFromURI('./assets/doc/LivreBlanc-PAC-web.pdf','Livre blanc - Pompes à chaleur')" class="txt txt--dark-red">
              {{'my-document.button-download.label' | translate}}
            </span>
          <span *disabledForClient="'pac'" id="dlLbPv" (click)="downloadFileFromURI('./assets/doc/LivreBlanc-PV_PROD.pdf','Livre blanc - Panneaux photovoltaïques')" class="txt txt--dark-red">
              {{'my-document.button-download.label' | translate}}
            </span>
        </td>
      </tr>
      <tr [ngClass]="classDocuments" *ngFor="let document of myDocuments$ | async">
        <td class="table__col--small table__col--center">
          <ng-container [ngSwitch]="document.documentType">
            <img *ngSwitchCase="documentType.Offer" class="table__icon" alt="" src="./assets/images/ico-offre.svg">
            <img *ngSwitchCase="documentType.Quote" class="table__icon" alt="" src="./assets/images/calculator.svg">
          </ng-container>
        </td>
        <td class="txt--black">
          {{document.displayName}}
          <br />
          <span class="txt--light-black table__col--mobile-show">
            {{document.createdAt | date : configs.DATE_FORMAT.DEFAULT}}, {{document.documentFormat}}, {{document.size}}
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            {{document.createdAt | date : configs.DATE_FORMAT.DEFAULT}}
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            {{document.documentFormat}}
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            {{document.size}}
          </span>
        </td>
        <td class="table__col--medium table__col--action table__col--tablet-auto-width table__col--center">
          <span id="dlFile" (click)="downloadFileFromURI(document.url,document.name)" class="txt txt--dark-red">
            {{'my-document.button-download.label' | translate}}
          </span>
        </td>
      </tr>
      <tr [ngClass]="classDocuments" *ngIf="isEligibleToAbo() && !this.isDeleteRefPACAbo">
        <td class="table__col--small table__col--center">
          <img class="table__icon" alt="" src="./assets/images/livreblanc.svg">
        </td>
        <td class="txt--black">
          <span class="txt--black"> Devis - Pompe à chaleur en abonnement </span>
          <br />
          <span class="txt--light-black table__col--mobile-show">
            {{lead?.createdAt | date : configs.DATE_FORMAT.DEFAULT}}, PDF, 0.6 MB
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            {{lead?.createdAt | date : configs.DATE_FORMAT.DEFAULT}}
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            PDF
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            0.6 MB
          </span>
        </td>
        <td class="table__col--medium table__col--action table__col--tablet-auto-width table__col--center">
          <span id="dl350" (click)="downloadFileFromURI('./assets/doc/devis_PAC_ABO_350.pdf','Devis - Pompe à chaleur en abonnement')" class="txt txt--dark-red">
            {{'my-document.button-download.label' | translate}}
          </span>
        </td>
      </tr>
      <tr *ngIf="this.isPartner" [ngClass]="classDocuments">
        <td class="table__col--small table__col--center">
          <img class="table__icon" alt="" src="./assets/images/livreblanc.svg">
        </td>
        <td class="txt--black">
          <span class="txt--black"> {{'checkout-partner.documents.label' | translate}} </span>
          <br />
          <span class="txt--light-black table__col--mobile-show">
            {{lead?.createdAt | date : configs.DATE_FORMAT.DEFAULT}}, PDF, 1.1 MB
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            {{lead?.createdAt | date : configs.DATE_FORMAT.DEFAULT}}
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            PDF
          </span>
        </td>
        <td class="table__col--medium table__col--center table__col--mobile-hide">
          <span class="txt--light-black">
            1.1 MB
          </span>
        </td>
        <td class="table__col--medium table__col--action table__col--tablet-auto-width table__col--center">
          <span (click)="downloadFileFromURI('./assets/doc/44-022_Brochure A4-Renovation-energetique_web.pdf','BCV - Rénovation energétique')" class="txt txt--dark-red">
            {{'my-document.button-download.label' | translate}}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
