import { LeadValidate } from './../../services/lead/lead.validate';
import { CalendlyService } from './../../services/calendly.service';
import {Component, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {CalendlyTypes} from './calendly-types.enum';
import {UserActionService} from '../../services/user/user-action.service';
import {LeadDTO} from '../../services/lead/lead.dto';
import {DealStatus} from '../../services/lead/deal-status.enum';
import {Configs} from '../../config.constant';
import {LeadService} from '../../services/lead/lead.service';
import {Router} from '@angular/router';
import {NewLeadEventModel} from '../../services/websocket/new-lead-event.model';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html'
})
export class ContactModalComponent implements OnInit {
  private static readonly MODAL_ACTIVE_CLASS = 'active';

  @Input()
  public lead!: LeadDTO;
  @Input()
  public newLead!: NewLeadEventModel;

  public block!: CalendlyTypes;
  public configs = Configs;
  private modalRef?: HTMLElement;

  constructor(private readonly elRef: ElementRef,
              private readonly renderer: Renderer2,
              private readonly router: Router,
              private readonly userActionService: UserActionService,
              private readonly leadService: LeadService,
              private readonly calendlyService: CalendlyService,
              private readonly leadValidate: LeadValidate) {
  }

  public ngOnInit(): void {
    this.block = CalendlyTypes.OVERVIEW;
    this.modalRef = this.elRef.nativeElement.querySelector('#modal-create-appointment') as HTMLElement;
    this.userActionService.getModalStatus().subscribe(isModalEnabled => {
      if (isModalEnabled) {
        this.showModal();
      } else {
        this.hideModal();
      }
    });
  }

  @HostListener('window:message', ['$event'])
  public bookedEvent(event: any): void {
    if (event.data.event && event.data.event.indexOf('calendly.event_scheduled') === 0) {
      let uuid;
      if (this.lead?.buildingUuid) {
        uuid = this.lead.buildingUuid;
      } else if (this.newLead?.buildingUuid) {
        uuid = this.newLead?.buildingUuid;
      } else {
        uuid = this.lead?.uuid;
      }
      this.leadService.notifyAppointmentPending(uuid).subscribe(() => {
        setTimeout(() => {
          this.router.navigate([`/offer/${uuid}`],  {preserveFragment: true});
        }, Configs.TIMEOUT.REDIRECT_MODAL);
      });
    }
  }

  public handleClickOutside(event): void {
    if (event.target.classList && event.target.classList.contains(ContactModalComponent.MODAL_ACTIVE_CLASS)) {
      this.userActionService.disableModal();
    }
  }

  public onClickCloseModal(): void {
    this.userActionService.disableModal();
  }

  public openCalendlyModal(): boolean {
    this.hideModal();
    const lead = this.newLead ? this.newLead : this.lead;
    const calendlyUrl = this.calendlyService.buildCalendlyUrl(lead);
    if (this.lead && this.lead.customer) {
      this.calendlyService.callCalendly(calendlyUrl, this.lead);
    } else if (this.newLead) {
      this.calendlyService.callCalendly(calendlyUrl, this.newLead);
    }

    return false;
  }

  public isBlockEnabled(slide: string): boolean {
    return this.block === CalendlyTypes[slide];
  }

  public buildCustomerAddress(): string {

    const address: string[] = [];

    if (this.lead) {
      if (this.lead.customer.address) {
        address.push(this.lead.customer.address);
      }

      if (this.lead.customer.streetNumber) {
        address.push(`${this.lead.customer.streetNumber},`);
      }

      if (this.lead.customer.postalCode) {
        address.push(this.lead.customer.postalCode);
      }

      if (this.lead.customer.locality) {
        address.push(this.lead.customer.locality);
      }
    } else if (this.newLead) {
      if (this.newLead.customer.address) {
        address.push(this.newLead.customer.address);
      }

      if (this.newLead.customer.streetNumber) {
        address.push(`${this.newLead.customer.streetNumber},`);
      }

      if (this.newLead.customer.postalCode) {
        address.push(this.newLead.customer.postalCode);
      }

      if (this.newLead.customer.locality) {
        address.push(this.newLead.customer.locality);
      }
    }

    return address.join(' ');
  }

  private showModal() {
    if (this.lead) {
       /*** If the modal is open on offer go directly to calendly block and call url ***/
      if (this.lead.dealStatus && this.lead.dealStatus !== DealStatus.QUOTE_RECEIVED) {
        this.userActionService.setCalendlyUrl(this.calendlyService.buildCalendlyUrl(this.lead));
        this.block = CalendlyTypes.CALENDLY;
      }
    } else if (this.newLead) {
      if (this.newLead.dealStatus && this.newLead.dealStatus !== DealStatus.QUOTE_RECEIVED) {
        this.userActionService.setCalendlyUrl(this.calendlyService.buildCalendlyUrl(this.newLead));
        this.block = CalendlyTypes.CALENDLY;
      }
    }

    this.hideBodyScrollbar();
    this.renderer.addClass(this.modalRef, ContactModalComponent.MODAL_ACTIVE_CLASS);
  }

  private hideModal(): void {
    this.showBodyScrollbar();
    this.renderer.removeClass(this.modalRef, ContactModalComponent.MODAL_ACTIVE_CLASS);

    if (this.lead) {
      if (this.leadValidate.isNotOnOffer(this.lead)) {
        this.block = CalendlyTypes.OVERVIEW;
        this.userActionService.clearCalendlyUrl();
      }
    } else if (this.newLead) {
      if (this.newLead.dealStatus === null || this.newLead.dealStatus === DealStatus.QUOTE_RECEIVED) {
        this.block = CalendlyTypes.OVERVIEW;
        this.userActionService.clearCalendlyUrl();
      }
    }
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }
}
