<div class="container">
  <div class="layout-row">
    <h2>{{'appointment.title' | translate}}</h2>
    <div *ngIf="!appointment" class="panel panel--appointment">
      <svg class="spinner--rotate" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" fill="none" r="20" stroke-width="3"></circle>
      </svg>
      <div class="panel__title">
        <div class="txt txt--med">{{'appointment.pending.title' | translate}}</div>
      </div>
    </div>
    <div *ngIf="appointment" class="panel panel--appointment">
      <div class="panel__date">
        <div class="txt txt--big">{{appointment.startTime | date:'dd'}}</div>
        <div>{{appointment.startTime | date:'MMMM': '' : locale}}</div>
      </div>
      <div class="panel__title">
        <div class="txt--med--nomargin">{{appointment.startTime | date:'HH:mm'}}
          - {{appointment.endTime | date:'HH:mm': '' : locale}}
        </div>
        <div class="txt txt--med">{{appointment.title}}</div>
      </div>
      <div class="panel__user">
        <div class="user-pic">
          <ng-template [disabledForClient]="'pv'">
            <img [default]="'/assets/images/teams/team.jpg'"
                 [src]="'/assets/images/teams/' + this.appointment.ownerId + '.jpg'"
                 alt=""
                 appDefaultImage>
          </ng-template>
          <ng-template [disabledForClient]="'pac'">
            <img *ngIf="appointment.ownerName && appointment.ownerName.includes(appointmentOwner.VIRGILIO); else RomainAvatar"
                 [default]="'/assets/images/teams/team.jpg'"
                 src="/assets/images/teams/30000003868.jpg"
                 alt=""
                 appDefaultImage>
            <ng-template #RomainAvatar>
              <img [default]="'/assets/images/teams/team.jpg'"
                   src="/assets/images/teams/30000003867.jpg"
                   alt=""
                   appDefaultImage>
            </ng-template>
          </ng-template>

        </div>
        <div>{{appointment.ownerName}}</div>
      </div>
    </div>
    <div *ngIf="appointment" class="btns">
      <div (click)="openCancelOrRescheduleAppointmentModal('RESCHEDULE')"
           class="btn btn--primary">{{'appointment.button.move' | translate}}</div>
      <div (click)="openCancelOrRescheduleAppointmentModal('CANCEL')"
           class="btn btn--secondary">{{'appointment.button.delete' | translate}}</div>
    </div>
  </div>
</div>
