<!-- Advice Modal -->
<div class="modal__container advice-modal">
  <div class="row">
    <div class="col-12">
      <button (click)="onClickCloseModalButton()" class="button-modal-close"></button>
      <h2 class="modal__container__title">{{'common.contacts.modal.conseil.title' | translate}}</h2>

      <div class="information">
        <img class="alert_icon" src="assets/images/alert_icon.svg">
        <span class="text" [innerHTML]="'common.contacts.modal.conseil.information' | translate"></span>
      </div>

      <div class="advice--modal--content position">
        <div class="advice--modal--button position" (click)="openLink($event, '0800 773 650')">
          <div class="btn btn--secondary advice--modal--call">
            <div class="icon phone" ></div>
            <div style="width: 90%; margin: auto">
              <span class="text--web">{{ 'common.contacts.modal.conseil.call.web' | translate }}<br/><span class="tel">{{'common.contacts.phoneNumber' | translate}}</span></span>
              <span class="text--mobile">{{ 'common.contacts.modal.conseil.call.mobile' | translate }}<span class="tel">{{'common.contacts.phoneNumber' | translate}}</span></span>
              <p class="text--web info">{{ 'common.contacts.time' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
