import {Injectable} from '@angular/core';
import {GlobalConfig, ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Configs} from '../config.constant';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private readonly translateService: TranslateService,
              private readonly toastr: ToastrService) {
    this.configureToastr(toastr.toastrConfig);
  }

  public showSuccessMessage(key: string, scrollToBottom?: boolean): void {
    const message = this.translateService.instant(key);
    this.toastr.success(message).onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(scrollToBottom);
    });
  }

  toasterClickedHandler(scrollToBottom?: boolean) {
    if (scrollToBottom) {
      window.scrollBy(0, 800);
    }
  }

  public showErrorMessage(key: string): void {
    const message = this.translateService.instant(key);
    this.toastr.error(message);
  }

  public showError(message): void {
    this.toastr.error(message);
  }

  private configureToastr(toastrConfig: GlobalConfig): void {
    toastrConfig.preventDuplicates = true;
    toastrConfig.progressBar = false;
    toastrConfig.timeOut = Configs.TIMEOUT.TOASTER;
    toastrConfig.positionClass = 'toast-bottom-right';
  }
}
