import {Injectable} from '@angular/core';
import {BackendService} from '../backend.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Quotation, CustomerDTO} from './customer.dto';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';
import {AppConfig} from '../../../dynamic-config/AppConfig';

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends BackendService<any> {
  public environment: AppConfig;


  private pacQuotation: Quotation[] = [
        {
            quotationText: `Merci beaucoup pour la qualité de la prestation. Service rapide et efficace !
Nous avons particulièrement apprécié l’offre clé en mains. Romande Energie s’est occupée de toutes les démarches administratives, sans aucun souci.
Nous sommes très contents du fonctionnement de notre pompe à chaleur. Nous ne pouvons que recommander Romande Energie.`,
            authorSurname: `Sandra et Jean-Bernard`,
            authorVillage: `Etagnières`
        },
        {
            quotationText: `Très content de l’installation !
Bonne prise en charge.`,
            authorSurname: `François`,
            authorVillage: `Lonay`
        },
        {
            quotationText: `Merveilleuse entreprise, j’ai été surpris en bien.
Je recommande vivement Romande Energie !`,
            authorSurname: `Pierre`,
            authorVillage: `Saint-Prex`
        },
        {
            quotationText: `Je suis très satisfait de l’installation et du service fourni. Notre pompe à chaleur géothermique fonctionne à merveille ! La gestion du projet s’est très bien déroulée. Nous avons choisi Romande Energie pour avoir la confiance d'obtenir un suivi technique sur le long terme.`,
            authorSurname: `Patrice`,
            authorVillage: `Aclens`
        },
        {
            quotationText: `J’ai particulièrement apprécié la prise en charge complète du projet ainsi que la bonne coordination des différents corps de métier. Je suis très satisfait du fonctionnement de ma pompe à chaleur et ne regrette absolument pas mon ancienne chaudière à mazout. En plus d’être écologique, la pompe à chaleur représente une solution très intéressante sur le plan économique. Personnel disponible et sympathique.`,
            authorSurname: `Bertrand`,
            authorVillage: `Grens`
        }
    ];

  private pvQuotation: Quotation[] = [
    {
      quotationText: `J’ai beaucoup apprécié la prise en charge de toutes les démarches administratives.
       L’application pour suivre la production solaire en temps réel est top !
       Les travaux ont été de courte durée et n’ont occasionné aucune gêne.
       Les différents intervenants ont tous été très sympathiques. Entreprise sérieuse et digne de confiance.`,
      authorSurname: `Lorène`,
      authorVillage: `Cugy`
    },
    {
      quotationText: `Installation réalisée dans les délais, personnel compétent,
      sympathique et très disponible. Je suis très satisfait de mes panneaux photovoltaïques.`,
      authorSurname: `Christophe`,
      authorVillage: `Cugy`
    },
    {
      quotationText: `Travail très bien exécuté, équipe polie, respectueuse,
      pro et souriante. Offre rapide, réactivité et bons conseils de la part de Romande Energie.`,
      authorSurname: `Michèle`,
      authorVillage: `Bussigny`
    }
  ];

    constructor(http: HttpClient,
                appConfigService: AppConfigService) {

        super(http, appConfigService);
        this.environment=appConfigService.getConfig();
    }

    public service(): string {
        return 'customer';
    }

    public getListQuotation(): Observable<Quotation[]> {
      if (this.environment.client === 'pac') {
        return of(this.pacQuotation);
      } else {
        return of(this.pvQuotation);
      }
    }

    public getUtmSource(customer: CustomerDTO): string {
      return customer.address ? (customer.address + ' ') : ''
            + customer.streetNumber ? customer.streetNumber : '' + ', '
            + customer.postalCode ? (customer.postalCode + ' ') : ''
            + customer.locality ? customer.locality : '';
    }
}
