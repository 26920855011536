<div *ngIf="spinner" [hidden]="!isLoading" [class.top]="paddingTop" class="bg-white bg-grey">
  <div class="container">
    <div class="layout-row row align-items-center">
      <div class="col-12 col-md-3 col-lg-2">
        <div class="illustration">
          <div class="spinner--home">
            <img alt="Spinner" src="./assets/images/img-spinner-home.svg">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 col-lg-10">
        <h1 class="mob-center color--label">
          {{'common.loader.label' | translate}}
        </h1>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!spinner" [hidden]="!isLoading" class="bg-white top bg-grey">
  <svg class="spinner--rotate" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" fill="none" r="20" stroke-width="3"></circle>
  </svg>
</div>
