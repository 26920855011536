export const Configs = {
  APPLICATION_NAME: 'PACS-FRONTEND',
  CONTACT_EMAIL: 'PAC@romande-energie.ch',
  CONTACT_EMAIL_SOLAR_PANELS: 'romain-isoard@romande-energie.ch',
  CONTACT_TEL_SOLAR_PANELS: '+41 7 43 64 25 66',
  CONSUMMATION: {
    MIN: 24,
    AVERAGE: 45,
    MAX: 50,
    LIMIT: 75
  },
  SYSTEM_TOTAL_SIZE: {
    SUITABLE: 2
  },
  AMORTISATION: {
    LIMIT: 20,
    ESTIMATED_KWH: 0.21
  },
  EMISSIONS: {
    KILOMETERS: 0.2,
    ENVIRONMENT: 94,
    TREES: 12.5
  },
  LOCAL_STORAGE: {
    ACCESS_TOKEN: 'access_token',
    MARKETING_CAMPAIGN: 'marketing_campaign',
    DOWNLOAD_PDF_MODEL: 'download_pdf_model',
    DISCOUNT_CODE: 'discount_code',
    PARTNER_NAME: 'partner_name',
    DATE_POPUP: 'popupDate_display',
    DISPLAY_POPUP: 'display_popup'
  },
  DEFAULT_VAT_VALUE: 7.7,
  CURRENCY_NUMBER_FORMAT: '1.0-0',
  OFFER_CURRENCY_NUMBER_FORMAT: '1.2-2',
  CURRENCY: 'CHF',
  CALENDLY: {
    CANCELLATIONS: 'cancellations',
    RESCHEDULING: 'reschedulings',
    STYLES: 'primary_color=BA0029',
    URL: 'https://calendly.com/cecb/conseil-cecb?'
  },
  TIMEOUT: {
    REDIRECT_MODAL: 5000,
    AUTO_REFRESH: 15000,
    TOASTER: 10000,
    RENDER_GRAPH: 100,
    DOCUMENT_NOTIFI: 30000
  },
  VALIDATION: {
    // tslint:disable-next-line:max-line-length
    EMAIL_PATTERN: /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-zA-ZA-Z0-9]{2,})$/,
    MOBILE_PATTERN: /^[0-9+-]{9,}/,
    POSTAL_CODE_PATTERN: '^[0-9]*$',
    POSTAL_CODE_MIN_LENGHT: 4,
    POSTAL_CODE_MAX_LENGHT: 4
  },
  ENVIRONMENT: {
    PV: 'pv',
    PAC: 'pac'
  },
  DATE_FORMAT: {
    DEFAULT: 'dd.MM.yyyy'
  },
  HEATING_POWER: {
    LIMIT: 20,
    LIMIT_WITH_ABO: 10
  },
  OFFER_LEVEL: {
    LOW: 350,
    HIGHT: 350
  }
};
