import {enableProdMode, NgModuleRef} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {AppConfig} from './app/dynamic-config/AppConfig';
import {APP_CONFIG, ENVIRONMENT_FILE_PATH, TIMEOUT_RETRIEVE_ENVIRONMENT} from './app/dynamic-config/const';
import {NotificationService} from './app/shared/services/notification.service';

var loadAngular = (config: AppConfig) => {
  console.info('Loading Angular');
  return new Promise<NgModuleRef<AppModule>>((resolve, reject) => {
    if (config.production) {
      console.info('Enabling prod mode');
      enableProdMode();
    }

    platformBrowserDynamic([{provide: APP_CONFIG, useValue: config}]).bootstrapModule(AppModule).then(ref => {
      resolve(ref);
    }).catch(err => {
      console.error('Failed to load Angular');
      console.error(err);
      reject();
    });
  });
};

var fetchWithTimeout = (url, options, timeout: number = 10000) => {
  return Promise.race([
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timeout exceeded')), timeout)
    ),
    fetch(url, options)
  ]);
}

// Loading the runtime configuration
console.info('Fetching runtime configuration')
fetchWithTimeout(ENVIRONMENT_FILE_PATH, {}, TIMEOUT_RETRIEVE_ENVIRONMENT)
  .then((res: any) => res.json())
  .then((config: AppConfig) => {
    loadAngular(config);
  })
  .catch(err => {
    console.error('Failed to retrieve runtime configuration: ' + err);

    // Minimal config required to load angular and display an error
    loadAngular(new class implements AppConfig {
      API_ROOT: '';
      CALENDLY_BASE_URL: '';
      CALENDLY_TEAM: '';
      DEFAULT_LANGUAGE: 'fr-FR';
      ENERGISSIMA_ACTIVE: false;
      GTM_ID: '';
      IFRAME_SIMULATOR_URL: '';
      SIMULATOR_URL: '';
      client: '';
      production: true;
      RP_ACTIVE: true;
      BORNE_URL: '';
      PARTNER_TERMS_CONDITIONS: '';
      NEXT_STEP: '';
      PV_SALES_BLOCKED: false;
      DELETE_ABO_REF_PAC: true;
      PAC_POPUP_IS_DELETE: true;
      UNSUITABLE: '';
      DRILLING: '';
      HABITAT_JARDIN: false;
    }).then((ref: NgModuleRef<AppModule>) =>
      ref.injector.get(NotificationService).showError('Impossible d\'initialiser l\'application')
    );
  });

