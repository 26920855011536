import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {BrowserFormat} from '../browser-format.enum';

@Injectable({
  providedIn: 'root'
})
export class UserActionService {
  private readonly calendlyUrl = new Subject<string>();
  private readonly modal = new Subject<boolean>();
  private readonly callMeStatus = new Subject<boolean>();
  private readonly callMeError = new Subject<boolean>();
  private readonly browserFormat = new BehaviorSubject<BrowserFormat>(BrowserFormat.WEB);

  public setCalendlyUrl(url: string): void {
    this.calendlyUrl.next(url);
  }

  public clearCalendlyUrl(): void {
    this.calendlyUrl.next();
  }

  public enableModal(): void {
    this.modal.next(true);
  }

  public disableModal(): void {
    this.modal.next();
  }

  public getModalStatus(): Observable<boolean> {
    return this.modal.asObservable();
  }

  public enableCallMeMessage(): void {
    this.callMeStatus.next(true);
  }

  public disableCallMeMessage(): void {
    this.callMeStatus.next(false);
  }

  public getCallMeMessage(): Observable<boolean> {
    return this.callMeStatus.asObservable();
  }

  public enableCallMeErrorMessage(): void {
    this.callMeError.next(true);
  }

  public disableCallMeErrorMessage(): void {
    this.callMeError.next(false);
  }

  public getCallMeErrorMessage(): Observable<boolean> {
    return this.callMeError.asObservable();
  }

  public getBrowserFormat(): Observable<BrowserFormat> {
    return this.browserFormat.asObservable();
  }

  public changeBrowserFormat(format: BrowserFormat): void {
    this.browserFormat.next(format);
  }

}
