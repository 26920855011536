import { Discount } from './../../services/discount/discount.dto';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {SimulationResultDTO} from '../../services/lead/simulation-result.dto';
import {ActiveSimulationService} from '../../services/active-simulation.service';
import {LeadDTO} from '../../services/lead/lead.dto';
import {ActiveOfferService} from '../../services/active-offer.service';
import {OfferDTO} from '../../services/offer/offer.dto';
import {Configs} from '../../config.constant';
import {RadioButton} from './radio-button.enum';
import {AppConfig} from '../../../dynamic-config/AppConfig';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';

@Component({
  selector: 'app-upper-tabs',
  templateUrl: './upper-tabs.component.html',
  styleUrls: ['./upper-tabs.component.scss']
})
export class UpperTabsComponent implements OnInit {

  // tslint:disable-next-line:no-output-native
  @Output()
  public change = new EventEmitter<any>();
  @Input()
  public lead!: LeadDTO;
  @Input()
  public disabledAirEau = false;
  @Input()
  public disabledGeo = false;
  @Input()
  public isOfferPage = false;
  @Input()
  public isEligibleToAbo!: boolean;
  @Input()
  public aboAmount!: number;
  @Input()
  public discount!: Discount;

  public activeSimulation!: SimulationResultDTO;
  public onHoverAirEau = false;
  public onHoverGeo = false;
  public onHoverAbo = false;
  public isAirEauSelected = true;
  public finalCostAirEau!: number;
  public finalCostGeo!: number;
  public radioButtonType = RadioButton;
  public isSelected = this.radioButtonType.INVESTISSEMENT_AIR;

  public environment: AppConfig;
  public isDeleteRefPACAbo: boolean;

  constructor(
    private readonly activeSimulationService: ActiveSimulationService,
    private readonly activeOfferService: ActiveOfferService,
    private readonly appConfigService: AppConfigService) {
      this.environment = this.appConfigService.getConfig();
      this.isDeleteRefPACAbo = this.environment.DELETE_ABO_REF_PAC;
  }

  public ngOnInit(): void {
    this.activeSimulation = this.activeSimulationService.getActiveSimulationValue();
    this.isAirEauSelected = true;
    this.activeSimulationService.getActiveSimulation().subscribe((simulation) => {
      this.activeSimulation = simulation;
    });
    if (this.lead.airWaterSimulationResult.cost === null || this.disabledAirEau === true && this.disabledGeo === false) {
      this.selectGeoSimulation();
    } else {
      this.selectAirEauSimulation();
    }
    this.setValueToFinalCost();
    if (this.lead.discount != null) {
      this.discount = this.lead.discount;
    }
  }

  public onMouseEnterAirEau(bool: boolean) {
    this.onHoverAirEau = bool;
  }

  public onMouseLeaveAirEau(bool: boolean) {
    this.onHoverAirEau = bool;
  }

  public onMouseEnterGeo(bool: boolean) {
    this.onHoverGeo = bool;
  }

  public onMouseLeaveGeo(bool: boolean) {
    this.onHoverGeo = bool;
  }

  public onMouseEnterAbo(bool: boolean) {
    this.onHoverAbo = bool;
  }

  public onMouseLeaveAbo(bool: boolean) {
    this.onHoverAbo = bool;
  }

  public selectAirEauSimulation(): void {
    this.activeSimulationService.setActiveSimulation(this.lead.airWaterSimulationResult);
    this.activeOfferService.setActiveOffer(this.lead.offerAir);
    this.isSelected = this.radioButtonType.INVESTISSEMENT_AIR;
    this.isAirEauSelected = true;
  }

  public selectGeoSimulation(): void {
    this.activeSimulationService.setActiveSimulation(this.lead.geoSimulationResult);
    this.activeOfferService.setActiveOffer(this.lead.offerGeo);
    this.isSelected = this.radioButtonType.INVESTISSEMENT_GEO;
    this.isAirEauSelected = false;
  }

  public calculateFinalCost(offer: OfferDTO): number {
    return offer.amount + offer.amount * Configs.DEFAULT_VAT_VALUE / 100 - offer.subvention;
  }

  public setValueToFinalCost(): void {
    if (this.isOfferPage) {
      if (this.lead.offerAir) {
        this.finalCostAirEau = this.calculateFinalCost(this.lead.offerAir);
      }

      if (this.lead.offerGeo) {
        this.finalCostGeo = this.calculateFinalCost(this.lead.offerGeo);
      }
    }
  }

  public getTotalSimulationCost(simulation: SimulationResultDTO): number {
    if (this.discount.amount != null && this.discount.valid) {
      return simulation.cost - simulation.subvention - this.discount.amount;
    } else {
      return simulation.cost - simulation.subvention;
    }
  }

  public selectButton(radioButton: RadioButton) {
    this.isSelected = radioButton;
    if (this.isSelected === this.radioButtonType.INVESTISSEMENT_AIR) {
      this.selectAirEauSimulation();
    }
    if (this.isSelected === this.radioButtonType.INVESTISSEMENT_GEO) {
      this.selectGeoSimulation();
    } else {
      this.activeSimulationService.setActiveSimulation(this.lead.airWaterSimulationResult);
      this.isAirEauSelected = true;
    }
    this.change.emit(this.isSelected);
  }
}
