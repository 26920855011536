import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {Configs} from '../../config.constant';
import {InformationModalService} from './information-modal.service';
import {AppConfig} from '../../../dynamic-config/AppConfig';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent implements OnInit {
  public environment: AppConfig;
  private modalRef?: HTMLElement;
  private readonly MODAL_ACTIVE_CLASS = 'active';
  public isSalesBlocked: boolean;
  constructor(private readonly elRef: ElementRef,
              private readonly renderer: Renderer2,
              private readonly informationModalService: InformationModalService,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
  }

  public ngOnInit(): void {
    this.modalRef = this.elRef.nativeElement.querySelector('#modal-information') as HTMLElement;
    this.showBodyScrollbar();
    this.isSalesBlocked = this.environment.PV_SALES_BLOCKED;
    this.informationModalService.showModal.subscribe((newVal) => {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    });

    if (this.checkIsFirstVisit() || this.checkIsMoreThan24Hours()) {
      this.showModal();
      localStorage.setItem(Configs.LOCAL_STORAGE.DISPLAY_POPUP, 'false');
      localStorage.setItem(Configs.LOCAL_STORAGE.DATE_POPUP, new  Date().getTime().toString());
    }
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;
    if (targetElement && this.modalRef?.isEqualNode(targetElement) && targetElement.classList &&
        targetElement.classList.contains(this.MODAL_ACTIVE_CLASS)) {
        this.informationModalService.showModal.next(false);
    }
  }

  private hideModal() {
    this.renderer.removeClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
    this.showBodyScrollbar();
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  public onClickCloseModalButton(): void {
    this.hideModal();
  }

  public showModal(): void {
      this.renderer.addClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
      this.hideBodyScrollbar();
  }

  private checkIsFirstVisit() {
    return !!localStorage.getItem(Configs.LOCAL_STORAGE.DISPLAY_POPUP) &&
      localStorage.getItem(Configs.LOCAL_STORAGE.DISPLAY_POPUP) === 'true';
  }

  private checkIsMoreThan24Hours() {
    if (localStorage.getItem(Configs.LOCAL_STORAGE.DATE_POPUP)) {
      // check if user has not see the popup today
      const date = new Date().getTime();
      const localStorageDate = Number(localStorage.getItem(Configs.LOCAL_STORAGE.DATE_POPUP));
      if (86400000 < (date - localStorageDate)) {
        return true;
      }
    }
    return false;
  }

  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }
}
