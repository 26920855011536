<div class="container">
  <div class="layout-row">
    <h2>{{'stepper.title' | translate}}</h2>
    <div class="stepper stepper--mobile-lines">
      <div class="stepper__item stepper__item--start active">
        <div class="illustration illustration--small">
          <img alt="" src="./assets/images/ico-devis.svg">
        </div>
        <div class="stepper__row">{{'stepper.quote' | translate}}</div>
      </div>
      <div class="stepper__item">
        <div class="illustration illustration--small">
          <img alt="" src="./assets/images/ico-rdv.svg">
        </div>
        <div class="stepper__row">{{'stepper.appointment' | translate}}</div>
      </div>
      <div class="stepper__item">
        <div class="illustration illustration--small">
          <img alt="" src="./assets/images/ico-offre.svg">
        </div>
        <div class="stepper__row">{{'stepper.offer' | translate}}</div>
      </div>
      <div class="stepper__item">
        <div class="illustration illustration--small">
          <img alt="" src="./assets/images/ico-commande.svg">
        </div>
        <div class="stepper__row">{{'stepper.order' | translate}}</div>
      </div>
      <div class="stepper__item">
        <div class="illustration illustration--small">
          <img alt="" src="./assets/images/ico-subvension.svg">
        </div>
        <div class="stepper__row">{{'stepper.grant' | translate}}</div>
      </div>
      <div class="stepper__item">
        <div class="illustration illustration--small">
          <img alt="" src="./assets/images/ico-autorisation.svg">
        </div>
        <div class="stepper__row">{{'stepper.authorization' | translate}}</div>
      </div>
      <div class="stepper__item stepper__item--end">
        <div class="illustration illustration--small">
          <img alt="" src="./assets/images/ico-installation.svg">
        </div>
        <div class="stepper__row">{{'stepper.installation' | translate}}</div>
      </div>
    </div>
    <p>{{'stepper.subtitle' | addClientSuffix | translate}}</p>
  </div>
</div>
