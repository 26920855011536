<div class="top img img--banner"
     [ngClass]="[environment.client == 'pac' ? 'img--background' : '', 'img--bg-' + environment.client]">
  <div class="img--banner--shadow">
    <div class="container container--top">
      <div *disabledForClient="'pv'" class="row panel--home pacBanner">
        <h1 *ngIf="this.isDeleteRefPACAbo" class="txt txt--big txt--white txt--center">
          {{'home.title.label' | addClientSuffix | translate}}
        </h1>

        <h1 *ngIf="this.isDeleteRefPACAbo" class="txt txt--med txt--white txt--center">
          {{'home.title.subtitle' | addClientSuffix | translate}}
        </h1>

        <div *ngIf="this.isDeleteRefPACAbo" class="btns btns--center">
          <a class="btn btn--primary" [routerLink]="['/simulation']"
             (click)="trackClickOnGetSimulation()">{{'home.simulator.button.label' | translate}}</a>
        </div>
      </div>

      <div *disabledForClient="'pv'" class="row panel--home" pacBanner>
        <div *ngIf="!this.isDeleteRefPACAbo" class="panel--big panel--left">
          <h1 class="txt txt--big txt--white">
            {{'home.title.label.pacAbonnement' | translate}}
          </h1>
          <div *ngIf="!this.isDeleteRefPACAbo" class="btns">
            <a class="btn btn--primary"
               [routerLink]="['/simulation']"
               (click)="trackClickOnGetSimulation()">{{'home.simulator.button.label' | translate}}</a>
          </div>
        </div>
        <div *ngIf="!this.isDeleteRefPACAbo" class="panel--intro--small">
          <div class="panel--intro panel--intro--small--content">
            <div class="txt--white txt-intro panel--center">
              <p>{{'home.nouveau.label.subintroduce' | translate}}</p>
              <p class="txt-price">{{'home.nouveau.label.price' | translate}}</p>
              <a class="btn btn--white btn--center"
                 [routerLink]="['/abonnement']">{{'home.nouveau.button' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <div *disabledForClient="'pac'" class="pvBanner">
        <h1 class="txt txt--big txt--white txt--center">
          {{'home.title.label' | addClientSuffix | translate}}
        </h1>

        <h1 class="txt txt--med txt--white txt--center">
          {{'home.title.subtitle' | addClientSuffix | translate}}
        </h1>

        <div class="btns btns--center">
          <a class="btn btn--primary" [routerLink]="['/simulation']"
             (click)="trackClickOnGetSimulation()">{{'home.simulator.button.label' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

