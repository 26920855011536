import { PhoneComponent } from './components/phone/phone.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {TranslateModule} from '@ngx-translate/core';
import {ClickOutSideDirective} from './directives/click-outside.directive';
import {TabsComponent} from './components/tabs/tabs.component';
import {LoaderComponent} from './components/loader/loader.component';
import {CurrencyFormatPipe} from './pipes/currency-format.pipe';
import {NumberFormatPipe} from './pipes/number-format.pipe';
import {ContactModalComponent} from './components/contact-modal/contact-modal.component';
import {AppointmentComponent} from './components/appointment/appointment.component';
import {DefaultImageDirective} from './directives/default-image.directive';
import {OfferCurrencyFormatPipe} from './pipes/offer-currency-format.pipe';
import {FlooringFormatPipe} from './pipes/flooring-format.pipe';
import {UpperTabsComponent} from './components/upper-tabs/upper-tabs.component';
import {QuotationComponent} from './components/quotation/quotation.component';
import {AddClientSuffixPipe} from './pipes/add-client-suffix.pipe';
import {DisabledForClientDirective} from './directives/disabled-for-client.directive';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {MyDocumentsComponent} from './components/my-documents/my-documents.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {DownloadPdfComponent} from './components/download-pdf/download-pdf.component';
import { SafePipe } from './pipes/safe.pipe';
import {AdviceModalComponent} from './components/advice-modal/advice-modal.component';
import {EnergissimaComponent} from './components/energissima/content/energissima.component';
import {EnergissimaModalComponent} from './components/energissima/modal/energissima-modal.component';
import {PopularRetreatsComponent} from './components/popular_retreats/popular_retreats.component';
import {HeaderPartnerComponent} from '../partner/header-partner/header-partner.component';
import {ModalContactComponent} from '../partner/modal-contact/modal-contact.component';
import {InformationModalComponent} from './components/information-modal/information-modal.component';
import {ModalInfoCalendlyComponent} from './components/modalInfoCalendly/modal-info-calendly.component';
import {GardenHabitatComponent} from './components/garden_habitat/garden_habitat.component';
import {HomeSlideComponent} from './components/home_slide/home_slide.component';

const modules = [
  NgxIntlTelInputModule,
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule
];

const components = [
  HeaderComponent,
  FooterComponent,
  TabsComponent,
  LoaderComponent,
  ContactModalComponent,
  AppointmentComponent,
  QuotationComponent,
  StepperComponent,
  MyDocumentsComponent,
  DownloadPdfComponent,
  PhoneComponent,
  AdviceModalComponent,
  InformationModalComponent,
  ModalInfoCalendlyComponent,
  EnergissimaComponent,
  EnergissimaModalComponent,
  PopularRetreatsComponent,
  ModalContactComponent,
  HeaderPartnerComponent,
  GardenHabitatComponent,
  HomeSlideComponent
];

const directives = [
  ClickOutSideDirective,
  DefaultImageDirective,
  DisabledForClientDirective
];

const pipes = [
  CurrencyFormatPipe,
  OfferCurrencyFormatPipe,
  NumberFormatPipe,
  FlooringFormatPipe,
  AddClientSuffixPipe
];

@NgModule({
  imports: [
    ...modules
  ],
    declarations: [
        ...components,
        ...directives,
        ...pipes,
        UpperTabsComponent,
        SafePipe
    ],
    exports: [
        ...components,
        ...directives,
        ...modules,
        ...pipes,
        UpperTabsComponent,
        SafePipe
    ],
  entryComponents: []
})
export class SharedModule {
}
