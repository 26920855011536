import { Discount } from './discount.dto';
import { map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BackendService} from '../backend.service';
import {AppConfigService} from "../../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class DiscountService extends BackendService<Discount> {

  constructor(http: HttpClient,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  service(): string {
    return 'discount';
  }

  public checkCode(uuid: string, code: string): Observable<Discount> {
    return this.get(`/${uuid}?code=${code}`)
    .pipe(map(response => response));
  }

}
