export class ModalContactModel {
  dealUuid!: string;
  contactId!: string;
  civility!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  phone!: string;
  meetingType!: string;
  productType!: string;
}
