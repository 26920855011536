import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EnergissimaModalModel} from './energissima-modal.model';
import {AppConfigService} from "../../../../dynamic-config/AppConfigService";
import {AppConfig} from "../../../../dynamic-config/AppConfig";

@Injectable({
  providedIn: 'root'
})
export class EnergissimaModalService {
  public readonly showModal = new BehaviorSubject<boolean>(false);
  private environment: AppConfig;


  protected constructor(private readonly http: HttpClient,
                        private readonly appConfigService: AppConfigService) {
    this.environment=this.appConfigService.getConfig();
  }

  service(): string {
    return 'energissima';
  }

  public registration(energissimaModalModel: EnergissimaModalModel, marketingCampaign: string): Observable<string> {

    const body: any = {
      civility: energissimaModalModel.civility,
      firstName: energissimaModalModel.firstName,
      lastName: energissimaModalModel.lastName,
      email: energissimaModalModel.email,
      phone: energissimaModalModel.phone,
      address: energissimaModalModel.address,
      locality: energissimaModalModel.city,
      npa: energissimaModalModel.zipCode,
      owner: energissimaModalModel.owner,
      marketingCampaign,
      heatingType: energissimaModalModel.heatingType,
      habitationType: energissimaModalModel.habitationType,
      houseYear: energissimaModalModel.houseYear,
      ticketNumber: energissimaModalModel.ticketNumber
    };

    return this.http.post(this.environment.API_ROOT + '/energissima/checkout', body, { responseType: 'text'});
  }


}
