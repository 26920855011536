import {Inject, Injectable} from '@angular/core';
import {AppConfig} from './AppConfig';
import {APP_CONFIG} from './const';

@Injectable()
export class AppConfigService {
  private config: AppConfig;

  // Config is retrieved before Angular is loaded
  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.config = appConfig;
  }

  getConfig(): AppConfig {
    return this.config;
  }
}
