import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {Configs} from '../config.constant';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe extends CurrencyPipe implements PipeTransform {
  transform(value: number): string {
    const currency = super.transform(value, Configs.CURRENCY, '', Configs.CURRENCY_NUMBER_FORMAT);
    return currency ? currency.replace(',', '\'').concat('.-') : value.toString();
  }
}
