<div appClickOutside class="modal" id="modal_energissima">
  <div class="modal__container modal-energissima" [ngClass]="participationDone ? 'participation' : ''">
    <div>
      <button (click)="onClickCloseModalButton()" class="button-modal-close"></button>
      <div *ngIf="!participationDone">
        <h2 class="modal__container__title" class="modalTitle" >
          {{'energissima.winTickets' | translate}}
        </h2>
        <div class="modal__container__intro" [innerHTML]="'energissima.modalContent' | translate">
        </div>
        <form [formGroup]="energissimaForm" class="form">
          <div class="form__item form__item--bum">
            <label for="ticketNumber">{{'energissima.invitation' | translate}}<span class="required">*</span></label>
            <div>
              <select class="form__item dropDown" formControlName="ticketNumber" id="ticketNumber"
                      name="ticketNumber" class="ticketNumber" required>
                <option [value]="1">1</option>
                <option [value]="2">2</option>
              </select>
            </div>
            <div *ngIf="isTicketNumberValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
            </div>
          </div>
          <div class="form__item form__item--bum">
            <label for="lastName">{{'form.lastName' | translate}}<span class="required">*</span></label>
            <div class="form__item__group-input form__item__group-input--big">
              <select class="form__item__group-input--big--small" formControlName="civility" id="civility"
                      name="civility">
                <option *ngFor="let civilityType of civilityTypes" [value]="civilityType">
                  {{ 'form.civility.' + civilityType | translate }}
                </option>
              </select>
              <div *ngIf="isCivilityValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
              </div>
                <input class="form__item__group-input--big--full-width" formControlName="lastName" id="lastName"
                     name="lastName" type="text">
            </div>
            <div *ngIf="isLastNameValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
            </div>
          </div>
          <div class="form__item">
            <label for="firstName">{{'form.firstName' | translate}}<span class="required">*</span></label>
            <input formControlName="firstName" id="firstName" name="firstName" type="text">
            <div *ngIf="isFirstNameValid()" class="txt txt--error msg msg--no-position">
              {{'form.incomplete' | translate}}</div>
          </div>
          <div class="form__item">
            <label for="address">{{'form.street' | translate}}<span class="required">*</span></label>
            <input formControlName="address" id="address" name="address" type="text">
            <div *ngIf="isAddressValid()" class="txt txt--error msg msg--no-position">
              {{'form.incomplete' | translate}}</div>
          </div>
          <div class="form__item form__item--bum">
            <label for="city">{{'form.locality' | translate}}<span class="required">*</span></label>
            <div class="form__item__group-input form__item__group-input--big">
              <input class="form__item__group-input--big--small" placeholder="NPA" formControlName="npa" id="npa"
                     name="npa" type="number">
              <input class="form__item__group-input--big--full-width" placeholder="{{'form.ville' | translate}}"
                     formControlName="city" id="city" name="city" type="text">
              <div *ngIf="isCityValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
              </div>
            </div>
          </div>
          <div class="form__item">
            <label for="email">{{'form.email' | translate}}<span class="required">*</span></label>
            <input [email]="true" [minLength]="5" formControlName="email" id="email" name="email" type="text">
            <div *ngIf="isEmailValid()" class="txt txt--error msg msg--no-position">
              <ng-template *ngIf="email?.errors?.required">
                {{'form.incomplete' | translate}}
              </ng-template>
              <ng-template *ngIf="email?.errors?.pattern">
                {{'form.wrong-format' | translate}}
              </ng-template>
            </div>
          </div>
          <div class="form__item">
            <label for="phone">{{'form.telephone' | translate}}</label>
            <ngx-intl-tel-input
              [inputId]="'mobile'"
              [cssClass]="'custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="false"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Switzerland"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="false"
              id="phone" name="Mobile" formControlName="phone" type="text">
            </ngx-intl-tel-input>
            <span class="form__item__description">{{'form.optional' | translate}}</span>
          </div>
          <div class="form__item form__item--yesno">
            <label class="label" id="owner">{{'form.ownAccommodation' | translate}}<span class="required">*</span></label>
            <div>
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="Oui" formControlName="owner" id="owner-yes"
                         class="form__item--inline" name="owner">
                  <label for="owner-yes"></label>
                </div>
                <div>{{'form.yes' | translate}}</div>
              </div>
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="Non" formControlName="owner" id="owner-no"
                         class="form__item--inline" name="owner">
                  <label for="owner-no"></label>
                </div>
                <div>{{'form.no' | translate}}</div>
              </div>
            </div>
            <div *ngIf="isOwnerValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
            </div>
          </div>
          <div class="form__item form__item--bum">
            <label for="habitationType">{{'form.habitationType' | translate}}<span class="required">*</span></label>
            <div>
              <select class="form__item dropDown" formControlName="habitationType" id="habitationType"
                      name="habitationType" class="habitationType">
                <option *ngFor="let habitationType of habitationTypes" [value]="habitationType">
                  {{ habitationType | translate }}
                </option>
              </select>
            </div>
            <div *ngIf="isHabitationTypeValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
            </div>
          </div>
          <div class="form__item form__item--bum">
            <label for="heatingType">{{'form.heatingType' | translate}}<span class="required">*</span></label>
            <div>
              <select class="form__item dropDown" formControlName="heatingType" id="heatingType"
                      name="heatingType" class="heatingType" required>
                <option *ngFor="let heatingType of heatingTypes; let i = index" [value]="getKeyNameHeatingTypes(i)">
                  {{ heatingType | translate }}
                </option>
              </select>
            </div>
            <div *ngIf="isHeatingTypeValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
            </div>
          </div>
          <div class="form__item form__item--bum">
            <label for="houseYear">{{'energissima.constructYear' | translate}}<span class="required">*</span></label>
            <input formControlName="houseYear" id="houseYear" name="houseYear" class="houseYear" type="number">
            <div *ngIf="isHouseYearValid()" class="txt txt--error msg msg--no-position">
              {{'form.incomplete' | translate}}</div>
            <div *ngIf="isHouseYearNumberInvalid()" class="txt txt--error msg msg--no-position">
              {{'form.wrongNumber' | translate}}</div>
          </div>
          <div class="btns justify-content-end">
            <button [disabled]="energissimaForm?.invalid || energissimaForm?.pending" class="btn btn--primary"
                    type="submit" (click)="onSubmit()">{{'form.participate' | translate}}</button>
          </div>
        </form>

        <footer>
          *Champs obligatoires
          <br />
        </footer>
      </div>
      <div>
        <div style="padding-top: 20px;">
          <div *ngIf="participationDone">
            <h2 class="modal__container__title formCompleted">
              {{'energissima.participationThank' | translate}}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
