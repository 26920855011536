export enum DocumentType {
  Quote = 'QUOTE',
  Offer = 'OFFER'
}

export enum QuoteType {
  Geothermie = 'geothermie',
  AirWater = 'air-eau',
}

export enum DocumentDisplayName {
  DevisGoothermie = 'Devis géothermie',
  DevisAirEau = 'Devis air-eau',
  OffreGeothermie = 'Offre géothermie',
  OffreAirEau = 'Offre air-eau',
  DevisPv = 'Devis panneaux solaires'
}
