<div appClickOutside class="modal" id="modal_contact">
  <div class="modal__container modal-design" [ngClass]="requestDone ? 'requestDone' : ''">
    <div>
      <header>
        <button (click)="onClickCloseModalButton()" class="button-modal-close"></button>
      </header>
      <div *ngIf="!requestDone">
        <h2 class="modal__container__title title_design"  >
          {{'modal-partner.bcv.title' | translate}}
        </h2>
        <form (ngSubmit)="onSubmit()" [formGroup]="modalContactForm" class="form">
          <div class="form__item form__item--bum">
            <label for="lastName">{{'form.lastName' | translate}}<span class="required">*</span></label>
            <div class="form__item__group-input form__item__group-input--big">
              <select class="form__item__group-input--big--small" formControlName="civility" id="civility"
                      name="civility">
                <option *ngFor="let civilityType of civilityTypes" [value]="civilityType">
                  {{ 'form.civility.' + civilityType | translate }}
                </option>
              </select>
              <input class="form__item__group-input--big--full-width" formControlName="lastName" id="lastName"
                     name="lastName" type="text" >
            </div>
            <div *ngIf="isLastNameValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
            </div>
          </div>
          <div class="form__item">
            <label for="firstName">{{'form.firstName' | translate}}<span class="required">*</span></label>
            <input formControlName="firstName" id="firstName" type="text">
            <div *ngIf="isFirstNameValid()" class="txt txt--error msg msg--no-position">
              {{'form.incomplete' | translate}}</div>
          </div>
          <div class="form__item">
            <label for="email">{{'form.email' | translate}}<span class="required">*</span></label>
            <input [email]="true" [minLength]="5" formControlName="email" id="email" name="email" type="text">
            <div *ngIf="isEmailValid()" class="txt txt--error msg msg--no-position">
              <ng-template *ngIf="email?.errors?.required">
                {{'form.incomplete' | translate}}
              </ng-template>
              <ng-template *ngIf="email?.errors?.pattern">
                {{'form.wrong-format' | translate}}
              </ng-template>
            </div>
          </div>
          <div [class.form__item--invalid]="isPhoneValid()" class="form__item">
            <label for="phone">{{'form.telephone' | translate}}<span class="required">*</span></label>
            <ngx-intl-tel-input
              [inputId]="'phone'"
              [cssClass]="'custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="false"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Switzerland"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              id="phone" name="Mobile" formControlName="phone" type="text">
            </ngx-intl-tel-input>
          </div>
          <div>
            <label class="label info" id="meetingType">{{'modal-partner.meetingType' | translate}}
              <span class="required">*</span>
              <span>
                <img (click)="toogleTooltip()" class="img" [src]="'/assets/images/ico-info-grey.svg'">
              </span>
            </label>
            <div *ngIf="isTooltipVisible" class="tooltipContent">
              {{'modal-partner.tooltip' | translate}}
            </div>
            <div class="meetingType">
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="visio" formControlName="meetingType" id="visio"
                         class="form__item--inline" name="meetingType">
                  <label for="visio"></label>
                </div>
                <div>{{'modal-partner.visio' | translate}}</div>
              </div>
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="agency" formControlName="meetingType" id="agency"
                         class="form__item--inline" name="meetingType">
                  <label for="agency"></label>
                </div>
                <div>{{'modal-partner.agency' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="btns btn_responsive">
            <button [disabled]="modalContactForm?.invalid || modalContactForm?.pending" class="btn btn--primary"
                    type="submit">{{'modal-partner.send' | translate}}</button>
          </div>
        </form>
      </div>
      <div>
        <div>
          <div *ngIf="requestDone">
            <p class="modal__container__title formCompleted" [innerHTML]="'modal-partner.requestDone' | translate">">>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
