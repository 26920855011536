import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ModalContactModel} from './modal-contact.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Configs} from '../../shared/config.constant';
import {CivilityTypes} from '../../checkout/form/civility-types.enum';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {ModalContactService} from './modal-contact.service';
import {LeadDTO} from '../../shared/services/lead/lead.dto';
import {AppConfig} from '../../dynamic-config/AppConfig';
import {AppConfigService} from '../../dynamic-config/AppConfigService';
import {NewLeadEventModel} from '../../shared/services/websocket/new-lead-event.model';

@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.scss']
})
export class ModalContactComponent implements OnInit {
  @Input()
  public lead!: LeadDTO;
  @Input()
  public newLead!: NewLeadEventModel;
  private modalRef?: HTMLElement;
  public beenSubmit = false;
  public beenSubmitValid = false;
  public requestDone = false;
  public modalContactForm!: FormGroup;
  private readonly MODAL_ACTIVE_CLASS = 'active';
  public isTooltipVisible = false;
  @Output()
  public submitForm = new EventEmitter<ModalContactModel>();
  public readonly civilityTypes = [CivilityTypes.MRS, CivilityTypes.MR];
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] =
    [CountryISO.France, CountryISO.Italy, CountryISO.Germany, CountryISO.Belgium, CountryISO.UnitedKingdom, CountryISO.Switzerland];
  public environment: AppConfig;

  constructor(private readonly elRef: ElementRef,
              private readonly modalService: ModalContactService,
              private readonly renderer: Renderer2,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
    this.modalContactForm = new FormGroup({
      civility: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.pattern(Configs.VALIDATION.EMAIL_PATTERN)]),
      meetingType: new FormControl('', [Validators.required])
    });
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;
    if (!targetElement) {
      return;
    }
    const clickedOutside = this.modalRef?.isEqualNode(targetElement);
    if (clickedOutside) {
      this.requestDone = false;
      if (targetElement.classList && targetElement.classList.contains(this.MODAL_ACTIVE_CLASS)) {
        this.modalService.showModal.next(false);
      }
    }
  }

  public ngOnInit(): void {
    this.modalRef = this.elRef.nativeElement.querySelector('#modal_contact') as HTMLElement;
    this.showBodyScrollbar();
    this.populate();
    this.modalService.showModal.subscribe((newVal) => {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    });
  }


  public populate(): void {
    const phoneNumber = this.lead.customer.phone != null ? this.lead.customer.phone.replace('+41', '') : '';

    const getPVCivility = () => this.lead.customer.civility.toLowerCase() === 'male' ? 'mr' : 'mrs';
    const civility = this.environment.client === 'pv' ? getPVCivility() : this.lead.customer.civility.toLowerCase();

    this.modalContactForm.setValue({
      civility,
      firstName: this.lead.customer.firstName,
      lastName: this.lead.customer.lastName,
      phone: phoneNumber,
      email: this.lead.customer.email,
      meetingType: ''
    });
  }

  public get civility() {
    return this.modalContactForm.get('civility');
  }

  public get firstName() {
    return this.modalContactForm.get('firstName');
  }

  public get lastName() {
    return this.modalContactForm.get('lastName');
  }

  public get email() {
    return this.modalContactForm.get('email');
  }

  public get prefix() {
    return this.modalContactForm.get('prefix');
  }

  public get phone() {
    return this.modalContactForm.get('phone');
  }

  public get meetingType() {
    return this.modalContactForm.get('meetingType');
  }

  public onSubmit(): void {
    if (this.email) {
      this.email.setValue(this.email.value.toLowerCase().replace(/ /g, ''));
    }
    if (this.modalContactForm.valid &&
      this.civility && this.firstName && this.lastName && this.email &&
      this.meetingType ) {
      const checkout = new ModalContactModel();
      checkout.dealUuid = this.lead.uuid;
      checkout.contactId = this.lead.freshSalesId;
      checkout.civility = this.civility.value;
      checkout.firstName = this.firstName.value;
      checkout.lastName = this.lastName.value;
      checkout.email = this.email.value;
      if (this.phone?.value) {
        checkout.phone = this.phone.value.e164Number;
      }
      checkout.meetingType = this.meetingType.value;
      checkout.productType = this.environment.client.toUpperCase();
      this.modalService.registration(checkout).subscribe(response => {
        if (response.status === 200) {
          this.requestDone = true;
          this.beenSubmitValid = true;
        }
      });
      this.beenSubmitValid = false;

    } else {
      this.beenSubmit = true;
    }
  }

  public isFirstNameValid(): boolean {
    return this.beenSubmit && !!this.firstName && !this.firstName.valid;
  }

  public isLastNameValid(): boolean {
    return this.beenSubmit && !!this.lastName && !this.lastName.valid;
  }

  public isEmailValid(): boolean {
    return this.beenSubmit && !!this.email && !this.email.valid;
  }

  public isPhoneValid(): boolean {
    return this.beenSubmit && !!this.phone && !this.phone.valid;
  }

  public isMeetingTypeValid(): boolean {
    return this.beenSubmit && !!this.meetingType && !this.meetingType.valid;
  }

  private hideModal() {
    this.resetMeetingType();
    this.renderer.removeClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
    this.showBodyScrollbar();
  }

  private showBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  public onClickCloseModalButton(): void {
    this.resetMeetingType();
    this.requestDone = false;
    this.modalService.showModal.next(false);
  }

  public showModal(): void {
      this.renderer.addClass(this.modalRef, this.MODAL_ACTIVE_CLASS);
      this.hideBodyScrollbar();
  }

  private hideBodyScrollbar(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  public toogleTooltip(): void {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  private resetMeetingType(): void {
    this.modalContactForm.patchValue({
      meetingType: ''
    });
  }

}

