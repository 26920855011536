<!-- Livre blanc modal -->
<div appClickOutside class="modal" id="modal-livre-blanc">
  <div class="modal__container modal-livre-blanc">
    <div class="row">
      <button (click)="onClickCloseModalButton()" class="button-modal-close"></button>
      <div class="col-12 col-lg-6">
        <img class="min-height-auto margin-auto" *disabledForClient="configs.ENVIRONMENT.PV" alt="" src="/assets/images/img-livre-blanc-cover.jpg">
        <img class="min-height-auto margin-auto --box-shadow" *disabledForClient="configs.ENVIRONMENT.PAC" alt="" src="/assets/images/whitepaper_pv_img-1.jpg">
      </div>
      <div class="col-12 col-lg-6">
        <h2 class="modal__container__title">{{'download.livre-blanc.form.title' | addClientSuffix | translate}}</h2>
        <div class="modal__container__intro">{{'download.livre-blanc.form.intro' | addClientSuffix | translate}}</div>
        <form (ngSubmit)="onSubmit()" [formGroup]="livreBlancForm" class="form">
          <div class="form__item form__item--bum">
            <label for="lastName">{{'form.lastName' | translate}}<span class="required">*</span></label>
            <div class="form__item__group-input form__item__group-input--big">
              <select class="form__item__group-input--big--small" formControlName="civility" id="civility"
                      name="civility">
                <option *ngFor="let civilityType of civilityTypes" [value]="civilityType">
                  {{ 'form.civility.' + civilityType | translate }}
                </option>
              </select>
              <input class="form__item__group-input--big--full-width" formControlName="lastName" id="lastName"
                     name="lastName" type="text">
            </div>
            <div *ngIf="isLastNameValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
            </div>
          </div>
          <div class="form__item">
            <label for="firstName">{{'form.firstName' | translate}}<span class="required">*</span></label>
            <input formControlName="firstName" id="firstName" name="firstName" type="text">
            <div *ngIf="isFirstNameValid()" class="txt txt--error msg msg--no-position">
              {{'form.incomplete' | translate}}</div>
          </div>
          <div class="form__item form__item--bum">
            <label for="city">{{'form.locality' | translate}}<span class="required">*</span></label>
            <div class="form__item__group-input form__item__group-input--big">
              <input class="form__item__group-input--big--small" placeholder="NPA" formControlName="zipcode" id="zipcode"
                     name="zipcode" type="text">
              <input class="form__item__group-input--big--full-width" placeholder="{{'form.ville' | translate}}"
                     formControlName="city" id="city" name="city" type="text">
              <div *ngIf="isCityValid()" class="txt txt--error msg msg--no-position">{{'form.incomplete' | translate}}
              </div>
            </div>
          </div>
          <div class="form__item">
            <label for="email">{{'form.email' | translate}}<span class="required">*</span></label>
            <input [email]="true" [minLength]="5" formControlName="email" id="email" name="email" type="text">
            <div *ngIf="isEmailValid()" class="txt txt--error msg msg--no-position">
              <ng-template *ngIf="email?.errors?.required">
                {{'form.incomplete' | translate}}
              </ng-template>
              <ng-template *ngIf="email?.errors?.pattern">
                {{'form.wrong-format' | translate}}
              </ng-template>
            </div>
          </div>
          <div class="form__item">
            <label for="phone">{{'form.telephone' | translate}}</label>
            <ngx-intl-tel-input
              [inputId]="'mobile'"
              [cssClass]="'custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="false"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Switzerland"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="false"
              id="phone" name="Mobile" formControlName="phone" type="text">
            </ngx-intl-tel-input>
            <span class="form__item__description">{{'form.optional' | translate}}</span>
          </div>
          <div class="form__item form__item--yesno">
            <label class="label" id="owner">{{'form.ownAccommodation' | translate}}<span class="required">*</span></label>
            <div>
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="true" formControlName="owner" id="owner-yes"
                         class="form__item--inline" name="owner">
                  <label for="owner-yes"></label>
                </div>
                <div>{{'form.yes' | translate}}</div>
              </div>
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="false" formControlName="owner" id="owner-no"
                         class="form__item--inline" name="owner">
                  <label for="owner-no"></label>
                </div>
                <div>{{'form.no' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="form__item form__item--yesno" *disabledForClient="configs.ENVIRONMENT.PAC">
            <label class="label" id="installedSolar">{{'form.installedSolar' | translate}}<span class="required">*</span></label>
            <div>
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="Oui" formControlName="installedSolar" id="installedSolar-yes"
                         class="form__item--inline" name="installedSolar">
                  <label for="installedSolar-yes"></label>
                </div>
                <div>{{'form.yes' | translate}}</div>
              </div>
              <div class="d-flex">
                <div class="checkbox">
                  <input type="radio" value="Non" formControlName="installedSolar" id="installedSolar-no"
                         class="form__item--inline" name="installedSolar">
                  <label for="installedSolar-no"></label>
                </div>
                <div>{{'form.no' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="form__provisions form__item">
            {{'form.provisions' | translate}}
            <a class="txt txt--error" href="https://www.romande-energie.ch/conditions-internet">{{'form.provisionsImportant' | translate}}</a>
          </div>
          <div class="form__item">
            <div class="checkbox checkbox-confirmation">
              <input *disabledForClient="configs.ENVIRONMENT.PV" formControlName="agree" id="agree" name="Consentement PAC" required
                     type="checkbox" value="true">
              <input *disabledForClient="configs.ENVIRONMENT.PAC" formControlName="agreePv" id="agreePv" name="Consentement PV" required
                     type="checkbox" value="true">
              <label class="checkbox-with-text" for="{{this.environment.client === configs.ENVIRONMENT.PV ? 'agreePv' : 'agree'}}"></label>
            </div>
            <label for="{{this.environment.client === configs.ENVIRONMENT.PV ? 'agreePv' : 'agree'}}">
              <div class="small" class="justify text-with-checkbox">{{'form.agree' | translate}}</div>
            </label>
          </div>
          <div class="btns justify-content-end">
            <button [disabled]="livreBlancForm?.invalid || livreBlancForm?.pending" class="btn btn--primary"
                    type="submit">{{'download.livre-blanc.form.button' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
