export class OfferDTO {
  amount!: number;
  // TODO: ask for vat calculation ?
  // vat: number;
  subvention!: number;
  // TODO: ask for deductions ?
  // deductions: number;
  productName!: string;
  productDescription!: string;

  createdAt!: Date;
  updatedAt!: Date;
}
