export class DownloadPdfModel {
    firstName!: string;
    lastName!: string;
    email!: string;
    phone!: string;
    city!: string;
    zipCode!: string;
    consentPac!: boolean;
    consentPv!: boolean;
    owner!: boolean;
    referer!: string;
    civility!: string;
    installedSolar!: boolean;
}
