import {Injectable} from '@angular/core';
import {Configs} from '../config.constant';
import {BackendService} from './backend.service';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AppConfigService} from "../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends BackendService<any> {

  constructor(http: HttpClient,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  service(): string {
    return 'auth';
  }

  public login(uuid: string, postalCode: string): Observable<string> {
    const body = {
      appName: Configs.APPLICATION_NAME,
      username: uuid,
      password: postalCode
    };
    return this.post('/login', body)
      .pipe(map(response => response.token))
      .pipe(tap(token => this.saveToken(token)));
  }

  public getToken(): string | null {
    return localStorage.getItem(Configs.LOCAL_STORAGE.ACCESS_TOKEN);
  }

  public saveToken(token: string): void {
    localStorage.setItem(Configs.LOCAL_STORAGE.ACCESS_TOKEN, token);
  }

  public logout(): void {
    localStorage.removeItem(Configs.LOCAL_STORAGE.ACCESS_TOKEN);
  }
}
