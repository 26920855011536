import {DealStatus} from '../lead/deal-status.enum';
import {CustomerDTO} from '../customer/customer.dto';

export class NewLeadEventModel {
  constructor(
    public uuid: string,
    public customer: CustomerDTO,
    public appointmentEventType: string,
    public callEventType: string,
    public address: string,
    public consummation: number,
    public estimatedProduction: number,
    public investment: number,
    public systemTotalSize: number,
    public dealStatus: DealStatus,
    public numberOfPanels: number,
    public buildingUuid: string,
    public shortCallEventType: string,
    public callLink: string
  ) {}

  static getInstance(): NewLeadEventModel {
    return new NewLeadEventModel('', new CustomerDTO(), '', '', '',
      0, 0, 0, 0, DealStatus.QUOTE_RECEIVED, 0,
      '', '', '');
  }
}
