<!-- Information Modal -->
<div appClickOutside class="modal" id="modal-information">
  <div class="modal__container">
    <button (click)="onClickCloseModalButton()" class="button-modal-close"></button>
    <h2 class="modal__container__title">
      {{'modal-information.title' | translate}}
    </h2>
    <div class="modal__container__intro" [innerHTML]="(this.isSalesBlocked ? 'modal-information.content_is_sales_blocked' : 'modal-information.content') | translate">
    </div>
  </div>
</div>
