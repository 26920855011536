<header [class.open]="open" class="header">
  <div class="header__logo">
    <img class="header__image logo" alt="Romande energie" routerLink="/" src="./assets/images/logo.png">
  </div>

  <div class="header__info">

    <ng-template [ngIf]="(!lead || !lead.uuid ) && (!newLead || !newLead.uuid)  && information">
      <div class="no-border"></div>
      <div class="header__info__mobile--no-border" (click)="toggleHeader()">
        <div class="header__info__open-menu">
          {{'header.open-menu.label' | translate}}
        </div>
        <div class="header__info__mobile
                    header__info__mobile--no-border">
          <div>
            <a class="--text-color" [routerLink]="['/']">
              {{'header.navigation.accueil.text' | translate}}
            </a>
          </div>
          <div>
            <a class="--text-color" [routerLink]="['/avantages']">
              {{'header.navigation.avantages.text' | translate}}
            </a>
          </div>
          <div *disabledForClient="'pv'">
            <a *ngIf="!this.isDeleteRefPACAbo" class="--text-color" [routerLink]="['/abonnement']">
              {{'header.navigation.abonnement.text' | translate}}
            </a>
          </div>
          <div *disabledForClient="'pv'">
            {{'header.already.offered.label' | translate}}
            <a (click)="showModal($event)" class="txt"
              href="#">&nbsp;{{'header.already.offered.button' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="header__info__navigation" [class.header__info__navigation--active]="titlePage === titleEnum.Home">
        <a class="--text-color" [routerLink]="['/']">
          {{'header.navigation.accueil.text' | translate}}
        </a>
      </div>
      <span class="header__info__bulkhead">
        |
      </span>
      <div class="header__info__navigation"
        [class.header__info__navigation--active]="titlePage === titleEnum.Advantages">
        <a class="--text-color" [routerLink]="['/avantages']">
          {{'header.navigation.avantages.text' | translate}}
        </a>

      </div>
      <span *disabledForClient="'pv'" class="header__info__bulkhead">
        |
      </span>
      <div *disabledForClient="'pv'" [ngClass]="{'header__info__navigation': !this.isDeleteRefPACAbo}"
        [class.header__info__navigation--active]="titlePage === titleEnum.Abonnement">
        <a *ngIf="!this.isDeleteRefPACAbo" class="--text-color" [routerLink]="['/abonnement']">
          {{'header.navigation.abonnement.text' | translate}}
        </a>
      </div>
      <span *ngIf="isNotOnSimulationPage() && !this.isDeleteRefPACAbo" class="header__info__bulkhead">
        |
      </span>
      <div *disabledForClient="'pv'" class="header__info__navigation">
        {{'header.already.offered.label' | translate}}
        <br class="mobile-break">
        <a (click)="showModal($event)" class="txt txt--caps"
          href="#">&nbsp;{{'header.already.offered.button' | translate}}</a>
      </div>
      <div *ngIf="isNotOnSimulationPage()" class="btns header__info__section">
        <div class="btn-panel">
          <a *disabledForClient="'pv'" class="btn btn--primary btn-left" (click)="trackClickOnGetSimulation()"
             [routerLink]="['/simulation']">{{'common.calculate-my-suggestion' | translate}}</a>
          <a *disabledForClient="'pac'" class="btn btn--primary btn-left" (click)="trackClickOnGetSimulation()"
            [routerLink]="['/simulation']">{{'common.calculate-my-suggestion' | translate}}</a>
          <div class="btn--primary btn-right" (click)="showAdviceModal()" >
            <img class="phone--icon" alt="" src="./assets/images/ico-phone.svg">
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template *disabledForClient="'pv'" [ngIf]="lead && lead.uuid && information">
      <div class="txt txt--label">{{'header.customer.title' | translate}}</div>
      <div (click)="toggleHeader()" class="js-header-btn">
        <div *ngIf="isValidAddress(lead)">
          {{lead.customer.address}}{{lead.customer.streetNumber ? ', ' + lead.customer.streetNumber : ''}}
        </div>
        <div *ngIf="isValidAddress(lead)">{{lead.customer.postalCode}} {{lead.customer.locality}}</div>
        <div class="header__info__mobile">
          <div>
            <div class="txt txt--label">{{'header.customer.consumption' | translate}}</div>
            <div class="txt txt--primary">{{lead.currentHeating.consumptionKwh | numberFormat}}
              {{'common.kwh' | translate}}/{{'common.year' | translate}}</div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.cost' | translate}}</div>
            <div class="txt txt--primary">CHF {{getActualCost() | currencyFormat}}/{{'common.year' | translate}}</div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.emissions' | translate}}</div>
            <div class="txt txt--primary">{{lead.currentHeating.co2Output | numberFormat}}
              {{'common.kg' | translate}}/{{'common.year' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.consumption' | translate}}</div>
        <div class="txt txt--primary">{{lead.currentHeating.consumptionKwh | numberFormat}}
          {{'common.kwh' | translate}}/{{'common.year' | translate}}</div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.cost' | translate}}</div>
        <div class="txt txt--primary">CHF {{getActualCost() | currencyFormat}}/{{'common.year' | translate}}</div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.emissions' | translate}}</div>
        <div class="txt txt--primary">{{lead.currentHeating.co2Output | numberFormat}}
          {{'common.kg' | translate}}/{{'common.year' | translate}}</div>
      </div>
      <div *ngIf="isAppointmentButtonVisible()" class="btns header__info__section btn-panel">
        <div (click)="openCalendlyModal()" class="btn btn--primary btn-left">{{'common.schedule' | translate}}</div> <!-- TODO: Change to common.meet.me when visit are back -->
        <div class="btn--primary btn-right">
          <a href="tel:{{'common.contacts.phoneNumber' | translate}}">
            <img class="phone--icon" alt="" src="./assets/images/ico-phone.svg">
          </a>
        </div>
      </div>

      <div *ngIf="isCallMeButtonVisible() && checkCalculatedHeatingPower()"
        class="btns header__info__section btn-panel">
        <div (click)="openCecbCalendlyModal()" class="btn btn--primary btn-right">{{'common.schedule' | translate}}</div> <!-- TODO: Change to meet.me when visit are back -->
        <div class="btn--primary btn-right">
          <a href="tel:{{'common.contacts.phoneNumber' | translate}}">
            <img class="phone--icon" alt="" src="./assets/images/ico-phone.svg">
          </a>
        </div>
      </div>
      <div *ngIf="!checkCalculatedHeatingPower() && lead.customer.owner && isFromRegion()" class="btns header__info__section">
        <div (click)="openCalendlyModal()" class="btn btn--primary">{{'common.schedule' | translate}}
        </div>
      </div>
    </ng-template>

    <ng-template *disabledForClient="'pac'" [ngIf]="(newLead && newLead.uuid) && information">
      <div class="txt txt--label">
        <br>
      </div>
      <div (click)="toggleHeader()" class="js-header-btn">
        <div *ngIf="isValidAddress(newLead)">
          {{newLead.customer.address}}{{newLead.customer.streetNumber ? ', ' + newLead.customer.streetNumber : ''}}
        </div>
        <div *ngIf="isValidAddress(newLead)">{{newLead.customer.postalCode}} {{newLead.customer.locality}}</div>
        <div class="header__info__mobile">
          <div>
            <div class="txt txt--label">{{'header.customer.proposed-power-pv' | translate}}</div>
            <div class="txt txt--primary">{{(newLead.systemTotalSize || 0)}}
              {{'common.kwc' | translate}}</div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.estimated-production' | translate}}</div>
            <div class="txt txt--primary">{{(newLead.estimatedProduction || 0) | numberFormat}}
              {{'common.kwh' | translate}}/{{'common.year' | translate}}
            </div>
          </div>
          <div>
            <div class="txt txt--label">{{'header.customer.investment' | translate}}</div>
            <div class="txt txt--primary">CHF {{(newLead.investment || 0) | currencyFormat}}</div>
          </div>
        </div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.proposed-power-pv' | translate}}</div>
        <div class="txt txt--primary">{{(newLead.systemTotalSize || 0)}}
          {{'common.kwc' | translate}}</div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.estimated-production' | translate}}</div>
        <div class="txt txt--primary">{{(newLead.estimatedProduction || 0) | numberFormat}}
          {{'common.kwh' | translate}}/{{'common.year' | translate}}
        </div>
      </div>
      <div class="header__info__section">
        <div class="txt txt--label">{{'header.customer.investment' | translate}}</div>
        <div class="txt txt--primary">CHF {{(newLead.investment || 0) | currencyFormat}}</div>
      </div>
      <div *ngIf="isCallMeButtonVisibleForPV()" class="btns header__info__section btn-panel">
        <div *ngIf="!isFromFribourgArea()" (click)="openCalendlyModal()" class="btn btn--primary btn-left">
          {{(this.salesBlocked ? 'common.information' : 'common.schedule') | translate}}
        </div>
        <div *ngIf="isFromFribourgArea()" (click)="openCalendlyModal()"
          class="btn btn--primary btn-left">
          {{(this.salesBlocked ? 'common.information' : 'common.schedule') | translate}}
        </div>
        <div class="btn--primary btn-right">
          <a href="tel:{{'common.contacts.phoneNumber' | translate}}">
            <img class="phone--icon" alt="" src="./assets/images/ico-phone.svg">
          </a>
        </div>
      </div>
    </ng-template>
  </div>
</header>

<!-- Calendly modal -->
<app-contact-modal *ngIf="lead && lead?.customer?.owner" [lead]="lead">
</app-contact-modal>

<!-- Calendly modal -->
<app-contact-modal *ngIf="newLead && newLead?.customer?.owner" [newLead]="newLead">
</app-contact-modal>

<!-- Resend Email modal -->
<div class="modal" id="modal-email">
  <div (clickOutside)="handleClickOutside($event)" appClickOutside class="modal__container modal-email">
    <div class="row">
      <div class="col-12">
        <button (click)="onClickCloseModalButton()" class="button-modal-close"></button>
        <h2 class="modal__container__title">{{'resend.email.form.title' | translate}}</h2>
        <form (ngSubmit)="onSubmit()" [formGroup]="emailForm" action="" class="checkoutForm">
          <div class="form__item">
            <label for="email">{{'form.email' | translate}}<span class="required">*</span></label>
            <input [email]="true" [minLength]="5" formControlName="email" id="email" name="email" required type="text">
          </div>
          <div class="btns">
            <button [disabled]="!emailForm.valid" class="btn btn--primary max-width-button-mobile" type="submit">
              {{'resend.email.form.send' | translate}}
            </button>
            <a class="btn btn--secondary align-right-btn" [routerLink]="['/simulation']">
              {{'home.simulator.button.label' | translate}}
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
