import {Component} from '@angular/core';
import {UserTrackingService} from '../../services/user/user-tracking.service';
import {AppConfigService} from '../../../dynamic-config/AppConfigService';
import {AppConfig} from '../../../dynamic-config/AppConfig';
@Component({
  selector: 'app-home-slide',
  templateUrl: './home_slide.component.html',
  styleUrls: ['./home_slide.component.scss']
})
export class HomeSlideComponent {
  public environment: AppConfig;
  public isDeleteRefPACAbo: boolean;

  constructor(private readonly appConfigService: AppConfigService,
              private readonly userTrackingService: UserTrackingService) {
    this.environment = this.appConfigService.getConfig();
    this.isDeleteRefPACAbo = this.environment.DELETE_ABO_REF_PAC;
  }

  public trackClickOnGetSimulation(): void {
    this.userTrackingService.trackEvent('CTA', 'simulationButtonOnHomeClicked');
  }

}

