import {Directive, HostBinding, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDefaultImage]'
})
export class DefaultImageDirective {

  @HostBinding('attr.src')
  @Input() src!: string;
  @Input() default!: string;

  @HostListener('error')
  public getDefaultImage() {
    this.src = this.default;
  }
}
