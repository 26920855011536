import {Injectable} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {CanActivate, Router} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private readonly router: Router,
              private readonly authenticationService: AuthenticationService) {
  }

  public canActivate(): boolean {

    if (this.authenticationService.getToken()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to home page
    this.router.navigate(['/']);
    return false;
  }
}

