import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BackendService} from '../backend.service';
import {AppointmentDTO} from './appointment.dto';
import {AppConfigService} from "../../../dynamic-config/AppConfigService";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends BackendService<AppointmentDTO> {

  constructor(http: HttpClient,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  service(): string {
    return 'appointment';
  }

  public findByUuid(uuid: string): Observable<AppointmentDTO> {
    return this.get(`/${uuid}`);
  }

}
