export class HeatingDTO {
  name!: string;
  description!: string;
  consumptionKwh!: number;
  consumptionWm2!: number;
  cost!: number;
  co2Output!: number;
  maintenanceCost!: number;
  calculatedHeatingPowerKw!: number;
}
