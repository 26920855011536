import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Configs} from '../config.constant';


@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe extends DecimalPipe implements PipeTransform {
  transform(value: number): string {
    const numberString = super.transform(value, Configs.CURRENCY_NUMBER_FORMAT);
    return numberString ? numberString.replace(',', '\'') : value.toString();
  }
}
