import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SimulationResultDTO} from './lead/simulation-result.dto';

@Injectable({
  providedIn: 'root'
})
export class ActiveSimulationService {

  private readonly activeSimulation = new BehaviorSubject<SimulationResultDTO>(new SimulationResultDTO());

  public setActiveSimulation(simulation: SimulationResultDTO): void {
    this.activeSimulation.next(simulation);
  }

  public getActiveSimulation(): Observable<SimulationResultDTO> {
    return this.activeSimulation.asObservable();
  }

  public getActiveSimulationValue(): SimulationResultDTO {
    return this.activeSimulation.value;
  }
}
