import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {OfferDTO} from './offer/offer.dto';

@Injectable({
    providedIn: 'root'
  })
  export class ActiveOfferService {
    private readonly activeOffer = new BehaviorSubject<OfferDTO>(new OfferDTO());

    public setActiveOffer(Offer: OfferDTO): void {
      this.activeOffer.next(Offer);
    }

    public getActiveOffer(): Observable<OfferDTO> {
      return this.activeOffer.asObservable();
    }

    public getActiveOfferValue(): OfferDTO {
      return this.activeOffer.value;
    }
}
