import { Configs } from './../config.constant';
import { CustomerService } from './customer/customer.service';
import { Injectable } from '@angular/core';
import {AppConfig} from '../../dynamic-config/AppConfig';
import {AppConfigService} from '../../dynamic-config/AppConfigService';
import {LeadDTO} from './lead/lead.dto';
import {NewLeadEventModel} from './websocket/new-lead-event.model';
@Injectable({
  providedIn: 'root'
})
export class CalendlyService {
  private environment: AppConfig;

  constructor(private readonly customerService: CustomerService,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
  }
  public callCalendly(urlCalendly: string, lead: any) {
    if (!!lead) {
      // @ts-ignore
      Calendly.initPopupWidget({
        url: urlCalendly,
        prefill: {
          name: `${lead.customer.firstName ? lead.customer.firstName : ''} ${lead.customer.lastName ? lead.customer.lastName : ''}`,
          email: lead.customer.email ? lead.customer.email : '',
          customAnswers: {
            a1: lead.customer.postalCode,
            a2: lead.customer.state
          }
        },
        utm: {
          utmContent: lead.buildingUuid ? lead.buildingUuid : lead.uuid,
          utmSource: this.customerService.getUtmSource(lead.customer)
        }
      });
    } else {
      // @ts-ignore
      Calendly.initPopupWidget({
        url: urlCalendly
      });
    }
  }

  public buildCalendlyUrl(lead: NewLeadEventModel | LeadDTO): string {
    // tslint:disable-next-line:max-line-length
    return `${lead.callLink}`;
  }

  public buildCalendlyUrlUnsuitable(): string {
    // tslint:disable-next-line:max-line-length
    return `${this.environment.CALENDLY_BASE_URL}/${this.environment.CALENDLY_TEAM}/${this.environment.UNSUITABLE}?${Configs.CALENDLY.STYLES}`;
  }

  public buildCalendlyUrlPartnerAnswers(lead: LeadDTO): string {
    // tslint:disable-next-line:max-line-length
    return `${lead.callLink}`;
  }

  public buildCalendlyUrlPartnerFinance(lead: LeadDTO): string {
    // tslint:disable-next-line:max-line-length
    return `${this.environment.CALENDLY_BASE_URL}/${this.environment.CALENDLY_TEAM}/${this.environment.client}${lead.callEventType}?${Configs.CALENDLY.STYLES}`;
  }
}
