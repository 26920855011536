import { LeadValidate } from './../../services/lead/lead.validate';
import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {MyDocument} from './my-document.dto';
import {DocumentType} from './my-document.enum';
import {MyDocumentService} from '../../services/my-document.service';
import {LeadDTO} from '../../services/lead/lead.dto';
import {Configs} from '../../config.constant';
import {ActivatedRoute} from '@angular/router';
import {MyDocumenvPVService} from '../../services/my-document-pv.service';
import {HeatingName} from '../../services/lead/heating-name.enum';
import {HeatDistributionSystem} from '../../services/lead/heat-distribution-system.enum';
import {AppConfig} from "../../../dynamic-config/AppConfig";
import {AppConfigService} from "../../../dynamic-config/AppConfigService";
import {Location} from '@angular/common';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html'
})
export class MyDocumentsComponent implements OnInit, AfterViewInit {
  @Input()
  public classDocuments = 'table--bg-white';
  @Input()
  public lead!: LeadDTO;
  @Input()
  public uuid!: string;
  @Input()
  public isDisplayMyDocuments!: boolean;
  public environment: AppConfig;

  public myDocuments$!: Observable<MyDocument[]>;
  public documentType = DocumentType;
  public configs = Configs;
  public fragment = '';
  public isPartner = false;
  public isDeleteRefPACAbo: boolean;

  constructor(private readonly myDocumentService: MyDocumentService,
              private readonly myDocumentPvService: MyDocumenvPVService,
              private route: ActivatedRoute,
              private location: Location,
              private leadValidate: LeadValidate,
              private readonly appConfigService: AppConfigService) {
    this.environment = this.appConfigService.getConfig();
    const matches =  this.location.path().match(/[^\/]+/g);
    this.isPartner = (!!matches && !!matches[0] && matches[0] !== 'checkout' && matches[0] !== 'offer');
    this.isDeleteRefPACAbo = this.environment.DELETE_ABO_REF_PAC;
  }

  public ngOnInit(): void {
    this.getListMyDocument();
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  public ngAfterViewInit(): void {
    const anchor = document.querySelector('#' + this.fragment);
    if (anchor != null) {
      anchor.scrollIntoView();
    }
  }

  public getListMyDocument(): void {
    if (this.isDisplayMyDocuments) {
      if (this.environment.client === 'pv') {
        this.myDocuments$ = this.myDocumentPvService.getListMyDocument(this.uuid);
      } else {
        this.myDocuments$ = this.myDocumentService.getListMyDocument(this.uuid);
      }
    } else {
      this.myDocuments$ = new Observable<MyDocument[]>();
    }
  }

  public isEligibleToAbo(): boolean {
    return this.environment.client === 'pac' && this.isEligible()
      && this.lead.currentHeating.calculatedHeatingPowerKw <= Configs.HEATING_POWER.LIMIT_WITH_ABO
      && (this.lead.currentHeating.name === HeatingName.GAS || this.lead.currentHeating.name === HeatingName.OIL)
      && this.lead.heatDistributionSystem !== 'combined';
  }

  public isFloorAbo(): boolean {
    return this.lead.heatDistributionSystem === HeatDistributionSystem.FLOOR_HEATING;
  }

  public isEligible(): boolean {
    return this.leadValidate.isEligible(this.lead);
  }

  public async downloadFileFromURI(uri: string, name: string): Promise<void> {
    if (!uri || !name) {
      console.error('downloadFileFromURI: uri or name is undefined');
      return;
    }
    const blob = await fetch(uri).then(async r => await r.blob());
    const link = document.createElement('a');
    document.body.appendChild(link);
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = name;
    link.click();
    document.body.removeChild(link);
  }
}
