import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BackendService} from './backend.service';
import {map} from 'rxjs/operators';
import {DocumentDisplayName} from '../components/my-documents/my-document.enum';
import {MyDocument} from '../components/my-documents/my-document.dto';
import {AppConfigService} from '../../dynamic-config/AppConfigService';

@Injectable({
  providedIn: 'root'
})
export class MyDocumenvPVService extends BackendService<MyDocument[]> {

  constructor(http: HttpClient,
              appConfigService: AppConfigService) {
    super(http, appConfigService);
  }

  public service(): string {
    return 'pv/lead';
  }

  public getListMyDocument(uuid: string): Observable<MyDocument[]> {
    return this.get(`/${uuid}/documents`).pipe(
      map((listMyDocument: MyDocument[]) =>
        listMyDocument.map((document: MyDocument) => {
          const splitNameValue = document.name.split('.');
          document.documentFormat = splitNameValue[splitNameValue.length - 1].toLocaleUpperCase();
          document.displayName = DocumentDisplayName.DevisPv;
          return document;
        })
      )
    );
  }
}
