import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {Configs} from '../config.constant';

@Pipe({
  name: 'offerCurrencyFormat'
})
export class OfferCurrencyFormatPipe extends CurrencyPipe implements PipeTransform {
  transform(value: number): string {
    value = Math.ceil(value * 20) / 20; // 20 = 100 / 5
    const currency = super.transform(value, Configs.CURRENCY, '', Configs.OFFER_CURRENCY_NUMBER_FORMAT);

    return currency ? currency.replace(',', '\'') : value.toString();
  }
}
