import {Component, OnInit, Input} from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {AdviceModalComponent} from '../advice-modal/advice-modal.component';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

  @Input()
  public isQuickCall!: boolean;

  bsModalRef?: BsModalRef;
  constructor(private readonly modalService: BsModalService) {
  }
  public isSelected = false;
  public onHoverPhone = false;

  public ngOnInit(): void {
  }

  public onMouseEnterPhone(bool: boolean) {
    this.onHoverPhone = bool;
  }

  public onMouseLeavePhone(bool: boolean) {
    this.onHoverPhone = bool;
  }

  public showAdviceModal(): void {
    const initialState: ModalOptions = {
      animated: true
    };
    this.bsModalRef = this.modalService.show(AdviceModalComponent, initialState);
  }

}
