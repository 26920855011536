export class EnergissimaModalModel {
  firstName!: string;
  lastName!: string;
  email!: string;
  phone!: string;
  address!: string;
  city!: string;
  zipCode!: string;
  owner!: string;
  civility!: string;
  heatingType!: string;
  habitationType!: string;
  houseYear!: number;
  ticketNumber!: number;
}
