import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {remove} from 'lodash';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private readonly requests: HttpRequest<any>[] = [];

  constructor(private readonly loaderService: LoaderService) {
  }

  private static isNotAssetsRequest(req: HttpRequest<any>): boolean {
    return req.url.indexOf('assets') === -1;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (LoaderInterceptor.isNotAssetsRequest(req)) {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
    }

    return next
      .handle(req)
      .pipe(finalize(() => {
        remove(this.requests, req);
        this.loaderService.isLoading.next(this.requests.length > 0);
      }));
  }
}
