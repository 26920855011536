
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthGuard} from './shared/guards/auth.guard';
import {PartnerConditionsGuard} from './shared/guards/partnerConditions.guard';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {LoaderInterceptor} from './shared/interceptors/loader.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Title} from '@angular/platform-browser';
import {ToastrModule} from 'ngx-toastr';
import {Angulartics2Module} from 'angulartics2';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {CarouselModule } from 'ngx-bootstrap/carousel';
import {AppConfigService} from './dynamic-config/AppConfigService';
import {PartnerRouteGuard} from './shared/guards/partnerRoute.guard';
import {NextStepGuard} from './shared/guards/nextStep.guard';
import {PartnerDrillingConditionsGuard} from './shared/guards/partnerDrillingConditions.guard';
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const initGoogleTagManagerId = (appConfig: AppConfigService) => appConfig.getConfig().GTM_ID;

const HTTP_INTERCEPTORS_PROVIDERS = [
  {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ModalModule.forRoot(),
    CarouselModule.forRoot()
  ],
  providers: [
    HTTP_INTERCEPTORS_PROVIDERS,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      // Waiting on the AppConfigService's constructor to be called
      useFactory: () => () => {
        return new Promise(resolve => {
          resolve();
        });
      },
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: 'googleTagManagerId',
      deps: [AppConfigService],
      useFactory: initGoogleTagManagerId
    },
    AuthGuard,
    PartnerConditionsGuard,
    PartnerDrillingConditionsGuard,
    PartnerRouteGuard,
    NextStepGuard,
    Title,
    BsModalRef,
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
