import { CalendlyService } from './../../services/calendly.service';
import {Component, Input, OnInit} from '@angular/core';
import {AppointmentService} from '../../services/appoitment/appointment.service';
import {AppointmentDTO} from '../../services/appoitment/appointment.dto';
import {Configs} from '../../config.constant';
import {LeadDTO} from '../../services/lead/lead.dto';
import {TranslateService} from '@ngx-translate/core';
import {delay, retryWhen} from 'rxjs/operators';
import {EventAlteration, AppointmentOwner} from './event-alteration.enum';
import {AppConfigService} from "../../../dynamic-config/AppConfigService";
import {AppConfig} from "../../../dynamic-config/AppConfig";

@Component({
  selector: 'app-offer-appointment',
  templateUrl: './appointment.component.html'
})
export class AppointmentComponent implements OnInit {

  @Input()
  public lead!: LeadDTO;
  @Input()
  public uuid!: string;
  public appointment!: AppointmentDTO;
  public locale!: string;
  public appointmentOwner = AppointmentOwner;
  private environment: AppConfig;

  constructor(private readonly appointmentService: AppointmentService,
              private readonly translate: TranslateService,
              private readonly calendlyService: CalendlyService,
              private readonly appConfigService: AppConfigService) {
    this.environment=this.appConfigService.getConfig();
  }

  public ngOnInit(): void {
    this.locale = this.translate.currentLang;
    let appointmentUuid;
    if (this.environment.client === 'pac') {
      appointmentUuid = this.lead.buildingUuid ? this.lead.buildingUuid : this.lead.uuid;
    } else {
      appointmentUuid = this.uuid;
    }
    this.appointmentService.findByUuid(appointmentUuid)
      .pipe(retryWhen(obs => obs.pipe(delay(Configs.TIMEOUT.AUTO_REFRESH))))
      .subscribe((dto: AppointmentDTO) => {
        this.appointment = dto;
      });
  }

  public openCancelOrRescheduleAppointmentModal(eventAlteration: string): boolean {
    let calendlyUrl: string;
    if (eventAlteration === EventAlteration.CANCEL) {
      calendlyUrl = this.buildCalendlyUrl(Configs.CALENDLY.CANCELLATIONS);
    } else {
      calendlyUrl = this.buildCalendlyUrl(Configs.CALENDLY.RESCHEDULING);
    }
    this.calendlyService.callCalendly(calendlyUrl, this.lead);
    return false;
  }

  private buildCalendlyUrl(eventType: string) {
    return `${this.environment.CALENDLY_BASE_URL}/${eventType}/${this.appointment.calendlyId}?${Configs.CALENDLY.STYLES}`;
  }
}
