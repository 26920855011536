import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AppConfig} from "../../dynamic-config/AppConfig";
import {AppConfigService} from "../../dynamic-config/AppConfigService";

@Directive({
  selector: '[disabledForClient]'
})
export class DisabledForClientDirective {

  private environment: AppConfig;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private readonly appConfigService: AppConfigService
  ) {
    this.environment=this.appConfigService.getConfig();
  }

  @Input()
  set disabledForClient(value: string) {
    this.viewContainerRef.clear();
    if (value !== this.environment.client) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
