/**
 * See _variables.scss
 */
const WIDTH_MAX_MOBILE = 768;

/*
 * The browser format
 */
export enum BrowserFormat {
  MOBILE = 'MOBILE',
  WEB = 'WEB'
}

/**
 * Get the browser format which depends on the browser width
 * @param width the browser width
 */
export function getBrowserFormat(width: number): BrowserFormat {
  return width > WIDTH_MAX_MOBILE ? BrowserFormat.WEB : BrowserFormat.MOBILE;
}
