<div class="row panel--home">
  <div class="panel--big panel--left">
    <h1 class="verticalTitle">
      {{'energissima.vertical.label' | translate }} {{'energissima.vertical.year' | translate }}
    </h1>
    <div>
      <img src="../../../../../assets/images/energissima/img.png" class="home"/>
      <h1 class="txt txt--big txt--white content" [innerHTML]="'energissima.welcomeText' | translate">
      </h1>
    </div>
  </div>
  <div class="panel--intro--small">
    <div class="panel--intro panel--intro--small--content">
      <div class="txt--white txt-intro panel--center">
        <p class="txt txt--white txt--med">{{'energissima.form' | translate}}</p>
        <a class="btn btn--white btn--center" (click)="activateModal(true)">{{'energissima.button' | translate}}</a>
      </div>
    </div>
  </div>
</div>
